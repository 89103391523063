import { defineStore } from 'pinia'
import type { OrderHistoryState as State, PastOrder } from '~/types/orderHistory'
import { STORE_ID } from '~/constants/store'
import { useAuthStore } from '~/store'
import { getOrderHistory } from '~/composables/api'

const initialState = (): State => ({
  pastOrders: null
})

/**
 * @name useOrderHistoryStore
 * @description Store for order history state across the app
 *
 */
const useOrderHistoryStore = defineStore(STORE_ID.ORDER_HISTORY, {
  state: initialState,

  getters: {
    getPastOrderByRedcatId: (state) => {
      return (redcatOrderId: number): PastOrder | null => {
        return state.pastOrders?.find((pastOrder) => pastOrder.redcatOrderId === redcatOrderId) || null
      }
    },
    pastOrdersAreLoaded(): boolean {
      return this.pastOrders !== null
    }
  },

  actions: {
    async fetchOrderHistory() {
      const authStore = useAuthStore()
      if (!authStore.isAuthenticated) {
        throw new Error('User is not authenticated. Could not fetch order history.')
      }

      try {
        this.pastOrders = await getOrderHistory(authStore.authUser!.id!)
      } catch (error: any) {
        throw new Error(`${error.name}: ${error.message}`)
      }
    },

    setOrderHistoryWithOrders(orders: PastOrder[]) {
      this.pastOrders = orders
    }
  }
})

export default useOrderHistoryStore
export { initialState }
