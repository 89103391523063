/**
 * @description Runtime config composable.
 * This is legacy code from Nuxt. It is used to expose runtime config to the client.
 */
export function useRuntimeConfig() {
  return {
    public: {
      appMode: import.meta.env.VITE_APP_MODE,
      env: import.meta.env.NODE_ENV,
      deployEnv: import.meta.env.VITE_DEPLOY_ENV,
      baseApiUrl: import.meta.env.VITE_BASE_API_URL,
      apiVersion: import.meta.env.VITE_API_VERSION,
      googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      adyenClientKey: import.meta.env.VITE_ADYEN_CLIENT_KEY,
      sentryDsn: import.meta.env.VITE_SENTRY_DSN,
      metaPixelId: import.meta.env.VITE_META_PIXEL_ID
    }
  }
}
