import { SplashScreen } from '@capacitor/splash-screen'
import type { Plugin } from 'vue'

export const splashScreenPlugin: Plugin = {
  async install(_app, _options) {
    if (isPlatform('capacitor')) {
      await SplashScreen.show({
        showDuration: 1500,
        autoHide: true
      })
    }
  }
}
