import type { Plugin } from 'vue'
import loadCustomIcons from '~/theme/icons'

/**
 * Initial app setup
 */
export const setupAppPlugin: Plugin = {
  install(_app, _options) {
    loadCustomIcons()
  }
}
