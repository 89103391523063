import type { CartItem, CartItemIngredient, MenuItem } from '~/types/api/data-contracts'
import type { MenuMultiChoiceItem } from '~/types/menu'

export function transformMenuItemToCartItemIngredient(menuItem: MenuItem): CartItemIngredient {
  return {
    id: menuItem.id,
    title: menuItem.title,
    priceInCents: menuItem.priceInCents,
    quantity: 1
  }
}

export function transformMenuMultiChoiceItemToCartItemIngredient(multiChoice: MenuMultiChoiceItem): CartItemIngredient {
  return {
    id: multiChoice.id,
    title: multiChoice.title,
    priceInCents: multiChoice.priceInCents,
    quantity: multiChoice.quantity ?? 0
  }
}

export function getCustomisationsPriceInCents(
  subtractions: CartItemIngredient[] | null | undefined,
  additions: CartItemIngredient[] | null | undefined,
  multiChoices: Array<CartItem | undefined>[] | null | undefined
): number {
  let totalPrice = 0

  if (multiChoices) {
    for (const multiChoice of multiChoices) {
      for (const choice of multiChoice) {
        if (!choice) {
          continue
        }

        totalPrice += (choice.unitPriceInCents || choice.priceInCents || 0) * (choice.quantity || 1)
      }
    }
  }

  // Remove subtractions from final price
  if (subtractions) {
    for (const subtraction of subtractions) {
      totalPrice += subtraction.unitPriceInCents || subtraction.priceInCents || 0
    }
  }

  // Add additions to final price
  if (additions) {
    for (const addition of additions) {
      const priceInCents = addition.unitPriceInCents || addition.priceInCents || 0
      totalPrice += priceInCents * addition.quantity
    }
  }
  return totalPrice
}

export function reduceChoices(choices: CartItem[][] | null | undefined): CartItem[][] {
  if (!choices) {
    return []
  }

  return choices.map((choice) => {
    return choice.reduce((acc: CartItem[], choice) => {
      const existingChoice = acc.find((c) => c.id === choice.id && !hasCustomisations(c))
      if (hasCustomisations(choice)) {
        const customChoices = reduceChoices(choice.multiChoices)
        acc.push({
          title: choice.title,
          quantity: choice.quantity,
          id: choice.id,
          multiChoices: customChoices,
          additions: reduceIngredients(choice.additions),
          subtractions: reduceIngredients(choice.subtractions)
        })
      } else {
        if (existingChoice) {
          existingChoice.quantity += choice.quantity
        } else {
          acc.push({ title: choice.title, quantity: choice.quantity, id: choice.id })
        }
      }

      return acc
    }, [])
  })
}

export function reduceIngredients(ingredients: CartItemIngredient[] | null | undefined): CartItemIngredient[] {
  if (!ingredients) {
    return []
  }
  return ingredients.reduce((acc: CartItemIngredient[], ingredient) => {
    const existing = acc.find((a) => a.id === ingredient.id)
    if (existing) {
      existing.quantity += ingredient.quantity
    } else {
      acc.push({ ...ingredient })
    }
    return acc
  }, [])
}

export function hasCustomisations(choice: CartItem): boolean {
  return (
    (!!choice.multiChoices && choice.multiChoices.length > 0) ||
    (!!choice.additions && choice.additions.length > 0) ||
    (!!choice.subtractions && choice.subtractions.length > 0)
  )
}
