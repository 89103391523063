import { registerPlugin } from '@capacitor/core'

import type { PushNotificationsPermissionsPlugin } from './definitions'

const PushNotificationsPermissions = registerPlugin<PushNotificationsPermissionsPlugin>(
  'PushNotificationsPermissions',
  {}
)

export * from './definitions'
export { PushNotificationsPermissions }
