import type { Restaurant } from '~/types/api/data-contracts'
import { isAfterDate, parseDate } from '~/utils/dateTime'

/**
 * @description Check if the store is after closing time
 * @param closingTime {string} The closing time today
 * @param dateTime {string} The date to check the store status. Generated as current time if not provided
 * @returns {boolean} True if the store is after closing time
 */
export function checkStoreIsAfterClosingTime(closingTime: string, dateTime?: string): boolean {
  const currentDate = parseDate(dateTime ?? new Date())
  return isAfterDate(currentDate, new Date(closingTime))
}

/**
 * @description Check if the store is open. The store is open if the current time is before the closing time today and the store is online
 * @param closingTime {string} The closing time today
 * @param isOnline {boolean} The store online status
 * @param dateTime {string} The date to check the store status. Generated as current time if not provided
 * @returns {boolean} True if the store is open
 */
export function checkStoreIsOpen(closingTime: string, isOnline: boolean, dateTime?: string): boolean {
  return !checkStoreIsAfterClosingTime(closingTime, dateTime) && isOnline
}

/**
 * @description Check if the store will open today
 * @param openingTime {string} The opening time today
 * @param dateTime {string} The date to check the store status. Generated as current time if not provided
 * @returns {boolean} True if the store will open today
 */
export function checkStoreWillOpenToday(openingTime: string, dateTime?: string): boolean {
  const currentDate = parseDate(dateTime ?? new Date())
  return currentDate < new Date(openingTime)
}

/**
 * @description Get the store online status message.
 * NOTE: The string returns '–' instead of '-'.
 * @param restaurant {Restaurant}
 * @param dateTime {string} The date to check the store status. Generated as current time if not provided
 * @returns {string} The store status in readable string
 */
export function getStoreOnlineStatusMessage(restaurant: Restaurant, dateTime?: string): string {
  const currentDayHours = restaurant.hours[0]

  if (checkStoreWillOpenToday(restaurant.openingTimeToday, dateTime)) {
    const openingTime = currentDayHours?.description.split(' - ')[0]
    return `Closed – Opens ${openingTime}`
  }

  const isOnline = checkStoreIsOpen(restaurant.closingTimeToday, restaurant.isOnline!, dateTime)
  // If the store is online, return the closing time
  if (isOnline) {
    const closingTime = currentDayHours?.description.split(' - ')[1]
    return `Open – Until ${closingTime}`
  }

  // If store is offline, find the next open day
  for (let i = 1; i < restaurant.hours!.length; i++) {
    const candidateDay = restaurant.hours![i]
    if (!candidateDay.isClosed) {
      return `Closed – Open ${restaurant.hours![i].name}`
    }
  }

  // Return closed if no conditions are met
  return 'Closed'
}
