import { defineStore } from 'pinia'
import useRestaurantStore from '~/store/restaurant'
import { STORE_ID } from '~/constants/store'
import type { UiState as State } from '~/types/ui'
import { OnlineOrderType } from '~/types/restaurant'

const initialState = (): State => ({
  orderSuccessModal: undefined,
  hasRestaurantClosedBannerDismissed: false,
  hasViewedCheckout: false,
  hasRelishPromptFired: false
})

/**
 * @name useUiStore
 * @description Store for user interface state across the app
 *
 */
const useUiStore = defineStore(STORE_ID.UI, {
  state: initialState,

  getters: {
    isOrderSuccessModalOpen(): boolean {
      return !!this.orderSuccessModal
    },
    /**
     * @description Shows closed banner if restaurant is closed.
     * Presented on GPageFooterOverlay and ViewProductItem
     * - Non-demo restaurant selected
     * - Restaurant is open
     * - Order type is not catering
     * - Banner has not been dismissed
     */
    shouldShowRestaurantClosedBanner(): boolean {
      const restaurantStore = useRestaurantStore()
      return (
        restaurantStore.isNonDemoRestaurantSelected &&
        !restaurantStore.isOpen &&
        restaurantStore.orderConfig?.orderType !== OnlineOrderType.Catering &&
        !this.hasRestaurantClosedBannerDismissed
      )
    }
  },
  persist: {
    paths: ['hasRelishPromptFired']
  }
})

export default useUiStore
export { initialState }
