import { defineStore } from 'pinia'
import useAuthStore from './auth'
import useRestaurantStore from './restaurant'
import { fetchUserLoyaltyTags } from '~/composables/api'
import { STORE_ID } from '~/constants/store'
import type { Coupon } from '~/types/api/data-contracts'
import type { RelishState as State } from '~/types/relish'

const initialState = (): State => ({
  coupons: [],
  eightAndDonate: {},
  productRecommendations: [],
  tags: undefined,
  lastUpdated: {
    coupons: undefined,
    eightAndDonate: undefined,
    productRecommendations: undefined,
    tags: undefined
  }
})

/**
 * @name useRelishStore
 * @description Store for Relish membership data
 *
 */
const useRelishStore = defineStore(STORE_ID.RELISH, {
  state: initialState,

  getters: {
    getCouponById: (state) => {
      return (hash: string): Coupon | null => {
        return state.coupons.find((coupon) => coupon.hash === hash) || null
      }
    },
    getCouponByProgramId: (state) => {
      return (programId: number): Coupon | null => {
        return state.coupons.find((coupon) => coupon.programId === programId) || null
      }
    }
  },

  actions: {
    /**
     * @description Fetches the user's coupons
     */
    async fetchCoupons(): Promise<void> {
      const authStore = useAuthStore()

      if (!authStore.isAuthenticated) {
        throw new Error('User must be authenticated to get coupons')
      }

      try {
        this.coupons = await fetchCoupons()
        this.lastUpdated.coupons = Date.now()
      } catch (error: any) {
        throw new Error(`${error.name}: ${error.message}`)
      }
    },
    /**
     * @description Fetches the user's Eight and Donate data
     */
    async fetchEightAndDonate(): Promise<void> {
      const authStore = useAuthStore()

      if (!authStore.authUser?.id) {
        throw new Error('User must be authenticated to get loyalty card')
      }

      try {
        this.eightAndDonate = await getEightAndDonate(authStore.authUser.id)
        this.lastUpdated.eightAndDonate = Date.now()
      } catch (error) {
        console.error(error)
        throw Error('Could not fetch loyalty data')
      }
    },
    /**
     * @description Fetches the user's loyalty tags
     * @param emit {boolean} - Whether to emit the user tags to the marketing app
     * @returns {Promise<void>}
     */
    async fetchLoyaltyTags(emit = true): Promise<void> {
      const authStore = useAuthStore()

      if (!authStore.authUser?.id) {
        throw new Error('User must be authenticated to get tags')
      }

      try {
        this.tags = await fetchUserLoyaltyTags(authStore.authUser.id)
        this.lastUpdated.tags = Date.now()
      } catch (error) {
        console.warn(error)
      }

      if (emit) {
        emitUserTags()
      }
    },
    async fetchProductRecommendations(): Promise<void> {
      const authStore = useAuthStore()
      const restaurantStore = useRestaurantStore()

      if (!authStore.authUser?.id) {
        throw new Error('User must be authenticated to get recommendations')
      }

      if (!restaurantStore.restaurant?.id) {
        throw new Error('Cannot fetch product recommendations without a restaurant')
      }

      if (!restaurantStore.orderConfig.orderType) {
        throw new Error('Cannot fetch product recommendations without an order type')
      }

      try {
        // If in catering mode or if the restaurant is a demo restaurant, set product recommendations to an empty array
        if (restaurantStore.isDemoRestaurant || restaurantStore.isCateringMode) {
          this.productRecommendations = []
        } else {
          this.productRecommendations = await getProductRecommendations({
            userId: authStore.authUser.id,
            restaurantId: restaurantStore.restaurant.id,
            orderType: restaurantStore.orderConfig.orderType
          })
        }
        this.lastUpdated.productRecommendations = Date.now()
      } catch (error) {
        console.error(error)
        throw Error('Could not fetch product recommendations')
      }
    }
  }
})

export default useRelishStore
export { initialState }
