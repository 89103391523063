import { addMinutes, format, formatISO, isAfter, parseISO, subMinutes } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'
import { roundUpToX } from '~/utils/number'

/**
 * @description Returns a date object from a datetime value
 * @param datetime {Date | string} The datetime value to handle
 * @returns {Date} The datetime value as a Date object
 */
export function parseDate(datetime: Date | string): Date {
  if (datetime instanceof Date) {
    return datetime
  }

  return new Date(datetime)
}

/**
 * @description Returns the long day name of the date
 * @param dateTime {string} The date to get the long day name. Generated as current time if not provided
 * @returns {string} The long day name of the date
 */
export function getLongDayName(dateTime?: string): string {
  const date = dateTime ? new Date(dateTime) : new Date()

  return date.toLocaleString('en-us', { weekday: 'long' })
}

/**
 * @description Formats the date to the format: do MMMM
 * e.g. 9th June
 * @param dateTime {string} The date to format as ISO string
 * @returns {string} The date as a string
 * @summary Note that if the dateTime string includes a timezone offset, the date
 *  will be converted to the local device timezone due to use of parseISO().
 */
export function formatReadableDay(dateTime: string): string {
  return format(parseISO(dateTime), 'do MMMM')
}

/**
 * @description Formats the date to the format: h:mm aa
 * e.g. 12:00 AM
 * @param dateTime {string} The date to format as ISO string
 * @returns {string} The time as a string
 * @summary Note that if the dateTime string includes a timezone offset, the date
 *  will be converted to the local device timezone due to use of parseISO().
 */
export function formatTime(dateTime: string): string {
  return format(parseISO(dateTime), 'h:mm aa')
}

/**
 * @description Creates a new ISO date string
 * @param date {Date} The date to format as ISO string (default: current date)
 * @returns {string} The date as ISO string (e.g. 2021-05-16T12:34:56+10:00)
 */
export function newISODate(date = new Date()): string {
  return formatISO(date)
}

/**
 * @description Rounds the time up to the nearest X minutes
 * @param dateTime {Date | string} The date as ISO string
 * @param roundingTarget {number} The rounding target in minutes (default: 10)
 * @returns {string} The date as ISO string
 */
export function roundTimeUpToNearestXMinutes(dateTime: Date | string, roundingTarget = 10): string {
  const date = parseDate(dateTime)

  const minutes = date.getMinutes()
  const remainder = minutes % roundingTarget
  const roundedMinutes = remainder === 0 ? minutes : minutes + (roundingTarget - remainder)
  const roundedDate = new Date(date)
  roundedDate.setMinutes(roundedMinutes, 0, 0) // Set rounded minutes, reset seconds and milliseconds
  return formatISO(roundedDate)
}

/**
 * @description Rounds the time down to the nearest X minutes
 * @param dateTime {Date | string} The date as ISO string
 * @param roundingTarget {number} The rounding target in minutes (default: 10)
 * @returns {string} The date as ISO string
 */
export function roundTimeDownToNearestXMinutes(dateTime: Date | string, roundingTarget = 10): string {
  const date = parseDate(dateTime)

  const minutes = date.getMinutes()
  const remainder = minutes % roundingTarget
  const roundedMinutes = remainder === 0 ? minutes : minutes - remainder
  const roundedDate = new Date(date)
  roundedDate.setMinutes(roundedMinutes, 0, 0) // Set rounded minutes, reset seconds and milliseconds
  return formatISO(roundedDate)
}

/**
 * @description Gets the next time for scheduling an order (can be outside of ordering hours)
 * @param dateTime {string} The date as ISO string
 * @param offset {number} The offset in minutes (default: 12)
 * @param interval {number} The interval in minutes (default: 10)
 * @returns {string} The date as ISO string
 */
export function getNextScheduleTime(dateTime?: Date | string, offset = 12, interval = 10): string {
  const currentDate = parseDate(dateTime ?? new Date())
  currentDate.setMinutes(currentDate.getMinutes() + offset)

  const minutes = currentDate.getMinutes()

  // if dateTime current minutes is divisible by interval, add offset
  // else add offset and round up to nearest interval minute
  if (minutes % interval !== 0) {
    currentDate.setMinutes(roundUpToX(minutes, interval))
  }

  return roundTimeUpToNearestXMinutes(formatISO(currentDate))
}

/**
 * @description Gets the next available time for scheduling an order
 * @param closingTime {string} The closing time for today as ISO string
 * @param offset {number} The offset in minutes (default: 12)
 * @param dateTime {Date | string} Optional date to check against (default: current date)
 * @returns {string | undefined} The date as ISO string or null if not available
 */
export function getNextAvailableScheduleTime(
  closingTime: string,
  offset?: number,
  dateTime?: Date | string
): string | undefined {
  const nextScheduleTime = getNextScheduleTime(dateTime ?? new Date(), offset)
  const lastScheduleTime = getLastScheduleTime(closingTime, offset)

  if (new Date(nextScheduleTime) > new Date(lastScheduleTime)) {
    return
  }

  return nextScheduleTime
}

/**
 * @description Gets the earliest available time for scheduling an order.
 * @param openingTime {string} The opening time for today as ISO string
 * @param offset {number} The offset in minutes (default: 12)
 * @param dateTime {Date | string} Optional date to check against (default: current date)
 * @returns {string} The date as ISO string
 */
export function getEarliestScheduleTime(openingTime: string, offset?: number, dateTime?: Date | string): string {
  const currentDateTime = parseDate(dateTime ?? new Date())
  const openingDateTime = new Date(openingTime)

  // if current time is before openingTimeToday, return next available from openingTimeToday
  if (currentDateTime < openingDateTime) {
    return getNextScheduleTime(openingTime, offset)
  }

  return getNextScheduleTime(newISODate(currentDateTime), offset)
}

/**
 * @description Gets the latest available time for scheduling an order.
 * @param closingTime {string} The closing time for today as ISO string
 * @param offset {number} The negative offset in minutes (default: 20)
 * @param interval {number} The interval in minutes (default: 10)
 * @returns {string} The date as ISO string
 */
export function getLastScheduleTime(closingTime: string, offset = 20, interval = 10): string {
  const lastScheduleTime = new Date(closingTime)
  lastScheduleTime.setMinutes(lastScheduleTime.getMinutes() - offset)

  return roundTimeDownToNearestXMinutes(lastScheduleTime, interval)
}

/**
 * @description Checks if the time is after the last available order schedule time
 * @param closingTime {string} The closing time for today as ISO string
 * @param offset {number} The offset in minutes (default: 12)
 * @param dateTime {Date | string}  The date as ISO string
 * @returns {boolean} True if the time is after the last available order schedule time
 */
export function checkScheduleOrderIsAvailable(closingTime: string, offset?: number, dateTime?: Date | string): boolean {
  return !!getNextAvailableScheduleTime(closingTime, offset, dateTime)
}

export function subtractMinutesFromDateString(dateTimeStringUtc: string, minutesToSubtract: number) {
  const utcToLocalTimezone = new Date(dateTimeStringUtc).toString()
  return subMinutes(new Date(utcToLocalTimezone), minutesToSubtract)
}

export function addMinutesFromDateString(dateTimeString: string, minutesToAdd: number) {
  return addMinutes(new Date(dateTimeString), minutesToAdd)
}

/**
 * @description Checks if the date is after the date to compare
 * @param date {Date} The date to check
 * @param dateToCompare {Date} The date to compare
 * @returns {boolean} True if the date is after the date to compare
 */
export function isAfterDate(date: Date, dateToCompare: Date): boolean {
  return isAfter(date, dateToCompare)
}

/**
 * @description Delays the execution of the function. Useful for animations
 * @param ms {number} The delay in milliseconds
 * @returns {Promise<void>} A promise that resolves after the delay
 */
export function delay(ms: number): Promise<void> {
  return new Promise((res: any) => setTimeout(res, ms))
}

export function isSameOffsetAsLocalTimezone(timeZone: string, localDateTime?: Date): boolean {
  if (!localDateTime) {
    localDateTime = new Date()
  }
  const localTimezoneOffset = -localDateTime.getTimezoneOffset() * 60 * 1000
  const remoteTimezoneOffset = getTimezoneOffset(timeZone, localDateTime)

  return localTimezoneOffset === remoteTimezoneOffset
}
/**
 * @description Strips the timezone offset from the ISO date time string
 * @param isoDateTime Date time string in ISO format
 * @returns Date time string without timezone offset
 * @summary This function is used to strip the timezone offset from the ISO date
 * time string so that the date may be formatted without converting to the
 * device's timezone.
 */
export function stripTimezoneOffset(isoDateTime: string): string {
  // split on + or Z
  const parts = isoDateTime.split(/[+Z]/)

  return parts[0]
}

/**
 * @description Formats the catering date and time to a readable format, without converting to device time
 * @param dateTime {string} The date and time as string in ISO format
 */
export function formatCateringDateAndTime(dateTime: string, startsWithTime = false): string {
  // Strip off the timezone offset to prevent conversion to device timezone
  const unspecifiedTime = stripTimezoneOffset(dateTime)

  return startsWithTime
    ? format(parseISO(unspecifiedTime), 'h:mmaa, do MMMM')
    : format(parseISO(unspecifiedTime), 'do MMMM, h:mm aa')
}
