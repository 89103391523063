import type { Restaurant } from '~/types/api/data-contracts'

/**
 * @description Enum for the type of order types
 * @todo Refactor to use the `OnlineOrderType` enum from `api/enums.ts`
 */
export enum OnlineOrderType {
  'Order at Table' = 100,
  'Park & Collect' = 102,
  'Delivery' = 103,
  'Pickup' = 106,
  'Catering' = 107,
  'Drive Thru' = 109
}

export interface RestaurantHour {
  name: string
  description: string
  isClosed?: boolean
}

export enum OrderTimeOption {
  NOW = 1,
  SCHEDULED = 2
}

export interface OrderTime {
  option: OrderTimeOption
  dateTime: string | undefined
}

export interface DeliveryDetails {
  fullAddress?: string
  addressLine1?: string
  suburb?: string
  state?: string
  postcode?: string
  deliveryNotes?: string
}

export interface OrderAtTableDetails {
  tableNumber?: string
}

export interface ParkAndCollectDetails {
  registration?: string
  colour?: string
  make?: string
  model?: string
}

export interface DriveThruDetails {
  registration?: string
}

export interface CateringDetails {
  cateringDate?: string
  cateringTime?: string
}

export interface OrderTypeDetails
  extends DeliveryDetails,
    OrderAtTableDetails,
    ParkAndCollectDetails,
    CateringDetails {}

export interface OrderConfig extends OrderTypeDetails {
  orderTime: OrderTime
  orderType: OnlineOrderType
}

export interface TemporaryOrderConfig extends Required<OrderConfig> {
  restaurant: Restaurant
}

export type OrderConfigValidatedInputFields = keyof Pick<
  OrderConfig,
  'tableNumber' | 'registration' | 'colour' | 'make' | 'model' | 'cateringDate' | 'cateringTime'
>

export type ParkAndCollectInputFields = keyof ParkAndCollectDetails

export interface RestaurantState {
  /**
   * @description The restaurant that the user is currently viewing
   */
  restaurant?: Restaurant
  /**
   * @description The user's current order configuration
   */
  orderConfig: OrderConfig
  /**
   * @description The user's temporary order configuration.
   * This is used to track the user's order config changes on `/order-config` before saving it
   */
  tempOrderConfig: TemporaryOrderConfig
  /**
   * @description Tracks the restaurant's open/closed state.
   * This is updated by the polling interval
   */
  isOpen?: boolean
  /**
   * @description Track the restaurant being viewed by the user on the restaurants list page
   */
  restaurantViewing?: Restaurant
  hasVisitedCheckoutPageWithActiveOrder: boolean
  isDemoRestaurant: boolean
  isCateringMode: boolean
  shouldShowProminentPromotion: boolean
  shouldShowOrderConfigConfirmationModal: boolean
  lastPresentedOrderConfigConfirmationModalDateStamp?: string
}
