import { useUserStore } from '~/store'

/**
 * @description Complete onboarding process.
 */
export function completeOnboarding(): void {
  const userStore = useUserStore()

  if (userStore.hasOnboarded) {
    console.log('User has already completed onboarding')
    return
  }

  userStore.hasOnboarded = true
}
