export enum STORE_ID {
  AUTH = 'auth',
  BRIGHTNESS = 'brightness',
  CUSTOM_CHOICE = 'customChoice',
  MENU = 'menu',
  ORDER = 'order',
  ORDER_HISTORY = 'orderHistory',
  PAYMENT = 'payment',
  PERMANENT = 'permanent',
  POLLING = 'polling',
  PRODUCT = 'product',
  PROMOTIONS = 'promotions',
  RELISH = 'relish',
  RESTAURANT = 'restaurant',
  TRACK = 'track',
  USER = 'user',
  UI = 'ui'
}

export type StoreId =
  | 'auth'
  | 'brightness'
  | 'customChoice'
  | 'menu'
  | 'order'
  | 'orderHistory'
  | 'payment'
  | 'permanent'
  | 'polling'
  | 'product'
  | 'promotions'
  | 'relish'
  | 'restaurant'
  | 'track'
  | 'user'
  | 'ui'
