import type { GChipSelectorItem } from '~/components/common/GChipSelector/types'
import type { MenuCategory, MenuResponse } from '~/types/api/data-contracts'

/**
 * @description Format the attributes into a string
 * @param attributes {string[]} - The attributes to format
 * @returns {string} - The formatted attributes
 */
export function formatAttributes(attributes: string[]): string {
  return attributes.map((value) => value).join(' ')
}

/**
 * @description Generates the category filters from the menu
 * @param menu {MenuResponse} - The menu to generate the filters from
 * @returns {GChipSelectorItem[]}
 */
export function generateCategoryFilters(menu: MenuResponse): GChipSelectorItem[] {
  if (!menu?.filters || !menu?.categories) {
    return []
  }
  return menu.filters
    .filter(({ categoryIds }) => categoryIds.length > 0)
    .map(({ categoryIds, title }) => {
      if (categoryIds.length > 1) {
        const subCategories = categoryIds
          .map((id) => getCategoryById(menu.categories, id))
          .filter((subCategory) => subCategory !== undefined)
          .map((subCategory) => ({ id: subCategory!.id, title: subCategory!.title }))
        return { id: categoryIds[0], title, items: subCategories }
      } else {
        return { id: categoryIds[0], title }
      }
    })
}

/**
 * @description Finds a category by id
 * @param categories {MenuCategory[]} - The categories to search
 * @param id {number} - The id of the category to find
 * @returns {MenuCategory | undefined}
 */
export function getCategoryById(categories: MenuCategory[] | null | undefined, id: number): MenuCategory | undefined {
  if (!categories) {
    return undefined
  }
  return categories.find((category) => category.id === id)
}
