import { useOrderHistoryStore, usePromotionsStore, useRelishStore } from '~/store'

export type RelishRequest =
  | 'coupons'
  | 'eightAndDonate'
  | 'loyaltyTags'
  | 'orderHistory'
  | 'productRecommendations'
  | 'promotions'

/**
 * @description Fetches user's relish data
 */
export function useFetchRelish() {
  const orderHistoryStore = useOrderHistoryStore()
  const promotionsStore = usePromotionsStore()
  const relishStore = useRelishStore()

  const relishRequestMap: Record<RelishRequest, () => Promise<void>> = {
    coupons: relishStore.fetchCoupons,
    eightAndDonate: relishStore.fetchEightAndDonate,
    loyaltyTags: relishStore.fetchLoyaltyTags,
    orderHistory: orderHistoryStore.fetchOrderHistory,
    productRecommendations: relishStore.fetchProductRecommendations,
    promotions: promotionsStore.fetchPromotions
  }

  async function fetchRelishData(
    requestedEndpoints: RelishRequest[] = [
      'coupons',
      'eightAndDonate',
      'orderHistory',
      'productRecommendations',
      'promotions'
    ]
  ) {
    // Refresh token if necessary before fetching data
    try {
      await shouldRefreshToken()
    } catch (error) {
      console.error('Error refreshing token', error)
    }

    const requests = requestedEndpoints.map((endpoint) => {
      if (Object.hasOwn(relishRequestMap, endpoint)) {
        return relishRequestMap[endpoint]
      }
    })

    if (!requests.length) {
      console.log('No requests to fetch')
      return
    }

    try {
      await Promise.allSettled(requests.map((request) => (request ? request() : () => {})))
    } catch (error) {
      console.error('Error fetching relish data', error)
    }
  }

  return fetchRelishData
}
