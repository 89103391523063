/**
 * @description Rounds a number up to the nearest X.
 * @param value {number} The value to round
 * @param target {number} The rounding target
 * @returns {number} The rounded value
 */
export function roundUpToX(value: number, target: number): number {
  return Math.ceil(value / target) * target
}

/**
 * @description Formats a whole number to two decimal places
 * @param amountInCents {number} The amount in cents
 * @returns {string} The formatted amount
 */
export function formatTwoDecimalPlaces(amountInCents: number): string {
  if (!Number.isInteger(amountInCents)) {
    throw new TypeError('Amount must be a whole number in cents.')
  }

  return (amountInCents / 100).toFixed(2)
}
