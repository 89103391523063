import type { HomeScreenCheckoutBanner, OrderState as State } from '~/types/order'
import { fetchAvailableCoupons, updateCartItems } from '~/composables/api'
import type {
  Cart,
  CartCateringDetails,
  CartDeliveryDetails,
  CartDiscount,
  CartError,
  CartItem,
  CartResponse,
  CartSurcharge,
  CartTableDetails,
  CartVehicleDetails,
  Coupon
} from '~/types/api/data-contracts'
import { STORE_ID } from '~/constants/store'
import { useAuthStore, useRestaurantStore, useUiStore } from '~/store'
import type { CartItemSource } from '~/types/product'
import { OnlineOrderType } from '~/types/restaurant'

const initialState = (): State => ({
  // `cart` is provided by the api response for the current order type
  cart: undefined,
  // `order` is the local mutable state of `cart`. it's persistent when order types change and is sent to the cart api
  // to update the cart product items
  order: undefined,
  currentCartId: '',
  availableCoupons: [],
  intervalId: undefined,
  reorderCartItems: undefined,
  homeScreenCheckoutBanners: [],
  isCartUpdating: false,
  currentOrderStartedFromReorder: false,
  currentOrderHasHadARecommendationAdded: false,
  currentOrderHasHadAnUpsellAdded: false,
  isProcessingPayment: false
})

/**
 * @name useOrderStore
 * @description Store for order and cart related data.
 * @returns {OrderState}
 */
const useOrderStore = defineStore(STORE_ID.ORDER, {
  state: initialState,

  getters: {
    getTotalItems(): number {
      if (!this.order?.items) return 0

      return this.order.items?.reduce((total: number, cartItem: CartItem) => total + (cartItem.quantity || 0), 0)
    },
    getCartItems(): CartItem[] {
      return this.cart?.items || []
    },
    hasCartItems(): boolean {
      return !!this.getCartItems?.length
    },
    getOrderItems(): CartItem[] {
      return this.order?.items || []
    },
    hasOrderItems(): boolean {
      return !!this.getOrderItems?.length
    },
    getOrderNote(): string {
      return this.order?.orderNote || ''
    },
    getSubtotal(): number {
      if (!this.cart?.items) return 0

      return this.cart.items?.reduce((item: number, cartItem: CartItem) => item + (cartItem?.priceInCents || 0), 0)
    },
    getSurcharges(): CartSurcharge[] {
      return this.cart?.surcharges || []
    },
    getSurchargesTotal(): number {
      if (!this.cart?.surcharges) return 0

      return this.cart.surcharges?.reduce((total: number, surcharge: CartSurcharge) => {
        return total + surcharge.priceInCents
      }, 0)
    },
    getSavingsTotal(): number {
      const discountsTotal =
        this.cart?.discounts?.reduce((item: number, discount: CartDiscount) => item + discount.priceInCents, 0) || 0
      return Math.abs(discountsTotal)
    },
    getCheckoutTotal(): number {
      return this.cart?.priceInCents || 0
    },
    getHomeScreenCheckoutBanners(): HomeScreenCheckoutBanner[] {
      return this.homeScreenCheckoutBanners
    },
    isRecentOrder: (state) => {
      return (redcatOrderId: number): boolean => {
        return state.homeScreenCheckoutBanners.map((banner) => banner.redcatOrderId).includes(redcatOrderId)
      }
    },
    getRecentOrderHomescreenBanner: (state) => {
      return (redcatOrderId: number): HomeScreenCheckoutBanner | undefined => {
        return state.homeScreenCheckoutBanners.find((banner) => banner.redcatOrderId === redcatOrderId)
      }
    },
    getAvailableCoupons(): Coupon[] {
      return this.availableCoupons
    },
    getSelectedCoupon(): Coupon | undefined {
      if (!this.cart?.coupons || this.cart.coupons?.length === 0) return undefined

      return this.availableCoupons.filter((coupon: Coupon) => coupon.hash === this.cart?.coupons?.[0].hash)[0]
    },
    getReorderCartItems(): CartItem[] | undefined {
      return this.reorderCartItems
    }
  },
  actions: {
    setCurrentCartId(id: string): void {
      this.currentCartId = id
    },
    clearCurrentCartId(): void {
      this.currentCartId = ''
    },
    setCart(cart: Cart): void {
      this.cart = cart
      this.setCurrentCartId(cart.id)

      emitCartItemCount()
    },

    setOrderFromCart(cart: Cart): void {
      // Get note from current order if it exists
      const orderNote = this.order?.orderNote
      this.order = cart
      // reset the note
      this.order.orderNote = orderNote
    },
    /**
     * @description Adds any product to a cart and updates order; if no cart exists, first create one
     * @param cartItem {CartItem}
     * @param source {CartItemSource}
     */
    async addProductToOrder(cartItem: CartItem, source: CartItemSource): Promise<CartError[] | undefined | null> {
      if (!this.currentCartId || !this.order) {
        await this.createCartAndSetOrder()
      } else {
        try {
          await this.fetchCart()
        } catch (error) {
          // if cart has expired or is invalid, create a new one
          await this.createCartAndSetOrder()
        }
      }

      try {
        if (!this.order?.items) {
          throw new Error('No order. Could not add product to order.')
        }

        this.order.items.push(cartItem)

        const errors = await this.updateItemsInCart(this.order.items)

        if (errors && errors.length > 0) {
          return errors
        }

        const restaurantStore = useRestaurantStore()

        gaEventAddToCart({
          currency: 'AUD',
          value: cartItem?.priceInCents ? cartItem.priceInCents / 100 : 0,
          orderType: this.cart?.orderType ? OnlineOrderType[this.cart.orderType] : '',
          context: source,
          restaurantName: restaurantStore.getSelectedRestaurant ? restaurantStore.getSelectedRestaurant.name : '',
          items: [
            {
              item_id: cartItem ? cartItem.id.toString() : '',
              item_name: cartItem?.title ? cartItem.title : '',
              price: cartItem?.priceInCents ? cartItem.priceInCents / 100 : 0,
              quantity: cartItem.quantity
            }
          ]
        })

        trackingEventAddToCart({
          content_ids: [cartItem ? cartItem.id.toString() : ''],
          contents: [{ id: cartItem ? cartItem.id.toString() : '', quantity: cartItem.quantity }],
          content_name: cartItem?.title ? cartItem.title : '',
          content_type: 'product',
          currency: 'AUD',
          value: cartItem?.priceInCents ? cartItem.priceInCents / 100 : 0
        })

        emitCartItemCount()
      } catch (error: any) {
        console.error(error)
        throw Error(error)
      }
    },
    async createCartAndSetOrder(): Promise<void> {
      const res = await this.createNewCart()
      if (!this.order?.items && res?.cart) {
        this.setOrderFromCart(res.cart)
      }

      useUiStore().hasRelishPromptFired = false
    },

    async updateOrder(): Promise<void> {
      if (!this.order) {
        throw new Error('No order. Could not update order.')
      }

      const res = await updateCart(this.currentCartId, this.order)

      if (!res.cart) {
        throw new Error('Cart could not be updated.')
      }

      this.setCart(res.cart)
      this.setOrderFromCart(res.cart)
    },
    /**
     * @description Updates items in cart and re-fetches cart contents from the server
     * @returns {Promise<void>}
     */
    async updateItemsInCart(newItems: CartItem[]): Promise<CartError[] | undefined | null> {
      try {
        if (!this.order) {
          throw new Error('No order. Could not update order items.')
        }

        if (!this.order?.items) {
          throw new Error('No items in order. Could not update order items.')
        }

        const res = await updateCartItems(this.currentCartId, newItems)

        if (!res.cart) {
          throw Error('Cart could not be updated.')
        }

        this.setCart(res.cart)
        this.setOrderFromCart(res.cart)

        return res.errors
      } catch (error: any) {
        console.error(error)
        throw error
      }
    },
    updateOrderInstructions(instructions: string): void {
      if (!this.order) {
        throw new Error('No order. Could not update order instructions.')
      }

      this.order.orderNote = instructions
    },
    /**
     * @description Fetches the cart from the server and sets it as the store reference
     * @returns {Promise<void>}
     */
    async fetchCart(): Promise<void> {
      try {
        const res = await getCart(this.currentCartId)

        if (!res.cart) {
          throw new Error('Cart could not be fetched.')
        }

        this.setCart(res.cart)
        this.setOrderFromCart(res.cart)
      } catch (error) {
        console.warn(error)
        // This function should throw if unable to fetch cart. Callers should handle the error and create
        // a new cart if necessary.
        throw error
      }
    },
    /**
     * @description Creates a new cart and saves as store reference
     * @returns {Promise<CartResponse | undefined>}
     */
    async createNewCart(): Promise<CartResponse | undefined> {
      const restaurantStore = useRestaurantStore()

      try {
        const tableDetails: CartTableDetails = {
          tableNumber: restaurantStore.orderConfig.tableNumber!
        }
        const deliveryDetails: CartDeliveryDetails = {
          addressLine1: restaurantStore.orderConfig.addressLine1!,
          suburb: restaurantStore.orderConfig.suburb!,
          state: restaurantStore.orderConfig.state!,
          postcode: restaurantStore.orderConfig.postcode!,
          note: restaurantStore.orderConfig.deliveryNotes!
        }
        const vehicleDetails: CartVehicleDetails = {
          registrationNumber: restaurantStore.orderConfig.registration!,
          make: restaurantStore.orderConfig.make!,
          model: restaurantStore.orderConfig.model!,
          colour: restaurantStore.orderConfig.colour!
        }
        const cateringDetails: CartCateringDetails = {
          date: restaurantStore.orderConfig.cateringDate!,
          time: restaurantStore.orderConfig.cateringTime!
        }
        const res = await createCart({
          restaurantId: restaurantStore.getSelectedRestaurant!.id,
          orderType: restaurantStore.getSelectedOrderType,
          orderTime: restaurantStore.getOrderTime.dateTime,
          deliveryDetails,
          vehicleDetails,
          tableDetails,
          cateringDetails
        })

        if (res && res.errors && res.errors.length > 0) {
          throw new Error("Your order didn't make it through. Please check your internet connection and try again.")
        }

        if (!res.cart) {
          throw new Error('Cart could not be created.')
        }

        // Reset the flag when new cart created
        useUiStore().hasViewedCheckout = false

        this.setCart(res.cart)
        return res
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    /**
     * @description Updates item quantity in the order/cart
     * @returns {void}
     */
    async changeItemQuantity(quantity: number, index: number): Promise<void> {
      try {
        this.isCartUpdating = true

        if (!this.order?.items) {
          throw new Error('No items in order. Could not change item quantity.')
        }

        this.order.items[index].quantity = quantity

        const errors = await this.updateItemsInCart(this.order.items)

        if (errors && errors.length > 0) {
          console.warn('Errors updating item quantity:', errors)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isCartUpdating = false
      }
    },
    /**
     * @description Removes an item from the order; deletes cart and returns to menu if cart is empty
     * @param index {number}
     * @param successNavigation
     */
    async removeItem(index: number, onCartEmpty: () => Promise<void>): Promise<void> {
      try {
        this.isCartUpdating = true

        if (!this.order?.items) {
          throw new Error('No items in order. Could not remove item.')
        }

        this.order.items.splice(index, 1)

        const errors = await this.updateItemsInCart(this.order.items)

        if (errors && errors.length > 0) {
          console.warn('Errors removing item:', errors)
        }

        if (!this.hasCartItems) {
          // the cart is now empty, so we'll consider it a new cart and reset the analytics variables
          this.resetCurrentOrderAnalyticsAttributes()

          await deleteCart(this.currentCartId)
          // call the callback when the cart has been successfully deleted
          await onCartEmpty()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isCartUpdating = false
      }
    },
    addHomeScreenCheckoutBanner(homeScreenCheckoutBanner: HomeScreenCheckoutBanner): void {
      this.homeScreenCheckoutBanners.unshift(homeScreenCheckoutBanner)
    },
    setHomeScreenCheckoutBannerParkAndCollectArrivedBanner(id: string, shouldShow: boolean) {
      this.homeScreenCheckoutBanners.find((x) => x.id === id)!.shouldShowParkAndCollectIveArrivedBanner = shouldShow
    },
    setHomeScreenCheckoutBannerCreationDate(id: string, dateString: string) {
      this.homeScreenCheckoutBanners.find((x) => x.id === id)!.dateTimeStringOfCreation = dateString
    },
    removeHomeScreenCheckoutBanner(id: string) {
      this.homeScreenCheckoutBanners = this.homeScreenCheckoutBanners.filter((x) => x.id !== id)
    },
    /**
     * @description Fetches coupons available for the current cart
     * @returns {Promise<void>}
     */
    async fetchAvailableCoupons(): Promise<void> {
      const authStore = useAuthStore()

      if (!authStore.isAuthenticated) {
        throw new Error('User is not authenticated. Could not fetch available coupons for cart.')
      }

      if (!this.cart) {
        throw new Error('No cart. Could not fetch available coupons for cart.')
      }

      this.availableCoupons = await fetchAvailableCoupons(this.cart.id)
    },
    /**
     * @description Sets the coupon to be applied to the cart
     * @param couponHash {string} - Coupon hash or id
     * @returns {Promise<Error | undefined>} - Error if coupon could not be set
     */
    async setSelectedCoupon(couponHash: Coupon['hash']): Promise<void> {
      if (!this.order) {
        throw new Error('No cart. Could not set coupon.')
      }

      if (this.order.coupons && this.order.coupons?.length > 1) {
        throw new Error('A coupon is already applied to the cart. Could not set another coupon.')
      }

      const res = await updateCartCoupons(this.order.id, { hash: couponHash })

      if (!res.cart || res.errors?.length) {
        throw new Error('Could not set coupon on cart.')
      } else {
        this.setCart(res.cart)
        this.setOrderFromCart(res.cart)
      }
    },
    /**
     * @description Removes a coupon applied to the cart
     * @returns {void}
     */
    async removeSelectedCoupon(): Promise<void> {
      if (!this.order) {
        throw new Error('No order. Could not remove coupon.')
      }

      if (!this.order?.coupons) {
        throw new Error('No coupon is applied to the cart. Could not remove coupon.')
      }

      const res = await removeCartCoupons(this.order.id)

      if (!res.cart || res.errors?.length) {
        throw new Error('Could not remove coupon from cart.')
      }

      this.setCart(res.cart)
      this.setOrderFromCart(res.cart)
    },
    clearCartAndOrder(): void {
      this.order = undefined
      this.cart = undefined
      this.currentCartId = ''

      emitCartItemCount()
    },
    /**
     * @description Clears cart/order with conditional redirect back to home screen
     */
    handleExpiredCartIfRestaurantClosed(): void {
      if (this.hasCartItems) {
        this.clearCartAndOrder()

        if (window.location.pathname === '/checkout' && this.$router) {
          this.$router.go(-1)
        }
      }
    },
    setReorderCartItems(items: CartItem[]): void {
      this.reorderCartItems = items
    },
    clearReorderCartItems(): void {
      this.reorderCartItems = undefined
    },
    resetCurrentOrderAnalyticsAttributes(): void {
      this.currentOrderStartedFromReorder = false
      this.currentOrderHasHadARecommendationAdded = false
      this.currentOrderHasHadAnUpsellAdded = false
    }
  },
  persist: true
})

export default useOrderStore
export { initialState }
