import { defineStore } from 'pinia'
import { STORE_ID } from '~/constants/store'
import type { PaymentMethodListItem, PaymentState as State, SupportedPaymentMethod } from '~/types/payment'
import type { PaymentMethod } from '~/types/api/data-contracts'
import useOrderStore from '~/store/order'
import useRestaurantStore from './restaurant'
import { OnlineOrderType } from '~/types/restaurant'

const initialState = (): State => ({
  checkoutRequestPaymentString: undefined,
  paymentMethod: undefined,
  rawPaymentMethodsResponse: undefined,
  paymentMethodsList: [],
  paymentMethodTitle: 'Add Payment',
  paymentMethodType: undefined,
  savePaymentMethod: false,
  cardConfirmed: false
})

/**
 * @description Store for payment related state used in the checkout
 */
const usePaymentStore = defineStore(STORE_ID.PAYMENT, {
  state: initialState,

  getters: {
    hasPaymentMethod(): boolean {
      return !!(this.paymentMethod && Object.keys(this.paymentMethod).length !== 0)
    },
    isPaymentGooglePay(): boolean {
      return this.paymentMethodType === 'paywithgoogle'
    },
    isPaymentPayPal(): boolean {
      return this.paymentMethodType === 'paypal'
    },
    isPaymentApplePay(): boolean {
      return this.paymentMethodType === 'applepay'
    },
    isPaymentCard(): boolean {
      if (!this.paymentMethodType) return false

      return ['scheme', 'card', 'mc', 'visa', 'amex'].includes(this.paymentMethodType)
    },
    getPaymentMethods(): PaymentMethodListItem[] {
      return this.paymentMethodsList
    }
  },

  actions: {
    setPaymentMethod({
      paymentMethod,
      title,
      type
    }: {
      paymentMethod: PaymentMethod
      title: string
      type: SupportedPaymentMethod
    }): void {
      const orderStore = useOrderStore()
      const restaurantStore = useRestaurantStore()

      this.paymentMethod = paymentMethod
      this.paymentMethodTitle = title
      this.paymentMethodType = type

      gaEventAddShippingInfo({
        currency: 'AUD',
        value: orderStore.cart ? orderStore.cart.priceInCents! / 100 : 0,
        shipping_tier: 'Ground',
        orderType: OnlineOrderType[restaurantStore.orderConfig.orderType],
        restaurantName: restaurantStore.getSelectedRestaurant ? restaurantStore.getSelectedRestaurant.name : '',
        items: restaurantStore.getSelectedRestaurant
          ? getECommerceEventItems(orderStore.cart, restaurantStore.getSelectedRestaurant.id.toString())
          : []
      })
      gaEventAddPaymentInfo({
        currency: 'AUD',
        value: orderStore.cart ? orderStore.cart.priceInCents! / 100 : 0,
        payment_type: 'applepay',
        orderType: OnlineOrderType[restaurantStore.orderConfig.orderType],
        restaurantName: restaurantStore.getSelectedRestaurant ? restaurantStore.getSelectedRestaurant.name : '',
        items: restaurantStore.getSelectedRestaurant
          ? getECommerceEventItems(orderStore.cart, restaurantStore.getSelectedRestaurant.id.toString())
          : []
      })
    },
    /**
     * @description Format payment request body for checkout API request
     * @param paymentData {PaymentMethod} - Payment method data
     */
    setCheckoutPaymentString(
      paymentData:
        | PaymentMethod
        | {
            type: string
            applePayToken: string
          }
    ): void {
      this.checkoutRequestPaymentString = JSON.stringify(paymentData)
    },
    setSavePaymentMethod(savePaymentMethod: boolean): void {
      this.savePaymentMethod = savePaymentMethod
    },
    setCardConfirmed(cardConfirmed: boolean): void {
      this.cardConfirmed = cardConfirmed
    },
    setPaymentMethods(paymentMethods: PaymentMethodListItem[]): void {
      this.paymentMethodsList = paymentMethods
    },
    async fetchPaymentMethods(): Promise<void> {
      const orderStore = useOrderStore()

      const res = await preparePaymentMethods(orderStore.currentCartId)
      this.rawPaymentMethodsResponse = res

      const paymentMethods = res.paymentMethods ?? []
      const storedPaymentMethods = res.storedPaymentMethods ?? []

      const list = paymentMethods
        .filter((item) => item.type !== 'scheme')
        .concat(storedPaymentMethods) as PaymentMethodListItem[]

      const finalList = isPlatform('ios') ? list.filter((item) => item.type !== 'paywithgoogle') : list
      this.setPaymentMethods(finalList)
    },
    resetSelectedPayment() {
      this.paymentMethodTitle = 'Add Payment'
      this.paymentMethodType = undefined
      this.paymentMethod = undefined
      this.checkoutRequestPaymentString = undefined
      this.cardConfirmed = false
    }
  },
  persist: false
})

export default usePaymentStore
export { initialState }
