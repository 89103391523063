import { App } from '@capacitor/app'
import { usePollingStore, useRestaurantStore } from '~/store'

export type AppMode = 'web' | 'mobile'

/**
 * @description Composable function to get the app mode.
 * E.g. `web` or `mobile`. Default is `mobile`.
 */
export function useAppMode() {
  const appMode = import.meta.env.VITE_APP_MODE

  const isWebMode: boolean = appMode === 'web'
  const isMobileMode: boolean = appMode !== 'web'

  /**
   * @description Get the app mode. Default is `mobile`.
   * @returns {AppMode} The app mode. E.g. `web` or `mobile`.
   */
  function getMode(): AppMode {
    return appMode === 'web' ? 'web' : 'mobile'
  }

  return {
    isWebMode,
    isMobileMode,
    getMode
  }
}

/**
 * @description Initialize app listeners for Mobile and Web.
 * Called after the app is mounted.
 */
export function useAppListeners() {
  /**
   * @description Initialise app listeners for Mobile
   */
  function initWebListeners() {
    const pollingStore = usePollingStore()

    App.addListener('resume', async (): Promise<void> => {
      await pollingStore.initPolling()
    })

    App.addListener('pause', (): void => {
      pollingStore.clearPolling()
    })
  }

  /**
   * @description Initialise app listeners for Web
   */
  function initMobileListeners() {
    if (isPlatform('capacitor')) {
      const pollingStore = usePollingStore()

      App.addListener('resume', async (): Promise<void> => {
        const restaurantStore = useRestaurantStore()

        if (restaurantStore.restaurant) {
          await pollingStore.initPolling()
        }
      })

      App.addListener('pause', (): void => {
        pollingStore.clearPolling()
      })
    }
  }

  /**
   * @description Initialize app listeners based on the app mode.
   */
  async function initAppListeners() {
    const { isMobileMode } = useAppMode()
    const pollingStore = usePollingStore()

    if (isMobileMode) {
      initMobileListeners()
    } else {
      initWebListeners()
    }

    // Start polling check on App mount
    await pollingStore.initPolling()
  }

  return initAppListeners
}
