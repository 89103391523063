import type { Router } from 'vue-router'
import mobileRouter from './mobile'
import webRouter from './web'

declare global {
  interface Window {
    fbq: any
    ttq: any
    gtag: any
  }
}

function defineRouter(): Router {
  const appMode = import.meta.env.VITE_APP_MODE

  if (appMode !== 'web') {
    console.log('Router config loaded: Mobile')
    return mobileRouter
  } else {
    console.log('Router config loaded: Web')
    return webRouter
  }
}

const router = defineRouter()

export default router
