import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from './router'
import { IonicVue } from '@ionic/vue'
import VueApp from './App.vue'
import { adyenPlugin } from './plugins/adyen'
import { sentryPlugin } from './plugins/sentry'
import { setupAppPlugin } from './plugins/setupApp'
import { swiperPlugin } from './plugins/swiper'
import { lottiePlugin } from './plugins/Vue3Lottie.client'
import { webAnalyticsPlugin } from './plugins/webAnalytics'
import { splashScreenPlugin } from './plugins/splashScreen'
import { googleMapsLoader } from './plugins/googlemaps'

// Styling
import 'virtual:uno.css'

/* Theme variables */
import '~/theme/main.scss'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'
import customTransitionAnimation from './ionic-transitions/customTransitionAnimation'

// State Management
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

const getIonicConfig = () => {
  let config: any = {
    mode: 'ios',
    // Enable html in text display of ionic components such as ion-alert, ion-infinite-scroll-content,
    // ion-loading, ion-refresher-content, and ion-toast
    // These strings are added to the DOM using innerHTML and must be properly sanitized by the developer
    innerHTMLTemplatesEnabled: true
  }

  if (isPlatform('ios') && isPlatform('mobileweb')) {
    const animation = customTransitionAnimation

    config = {
      ...config,
      navAnimation: animation,
      swipeBackEnabled: false
    }
  } else if (isPlatform('android') && isPlatform('mobileweb')) {
    config = {
      ...config,
      swipeBackEnabled: false
    }
  }

  return config
}

const app = createApp(VueApp)
  .use(IonicVue, getIonicConfig())
  .use(pinia)
  .use(router)
  .use(adyenPlugin)
  .use(sentryPlugin)
  .use(setupAppPlugin)
  .use(swiperPlugin)
  .use(lottiePlugin)
  .use(webAnalyticsPlugin)
  .use(splashScreenPlugin)
  .use(googleMapsLoader)

router.isReady().then(async () => {
  app.mount('#app')

  const initAppListeners = useAppListeners()
  await initAppListeners()
})
