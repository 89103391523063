import type { RouteLocationRaw } from 'vue-router'
import type { OnboardingQueries } from '~/types/onboard'

/**
 * @description Gets the route to navigate to after a user signs in or user continues as guest
 * @param source {ViewSource | undefined} The source of the current route
 * @param restaurantId {string | undefined} The restaurant id used in the order-at-table flow
 * @returns {Promise<RouteLocationRaw>} The route to redirect to. This is passed to the `router.replace()` method
 */
export async function getPostSignInRoute({ source, restaurantId }: OnboardingQueries): Promise<RouteLocationRaw> {
  if (await shouldPromptNotificationsPermission()) {
    // Check to prompt for notification permission
    return {
      name: 'permissions-notifications',
      query: { source, restaurantId }
    }
  } else if (await shouldPromptLocationPermission()) {
    // Check to prompt for location permission
    return {
      name: 'permissions-location',
      query: { source, restaurantId }
    }
  } else if (source === 'order-at-table') {
    // If the user was redirected to sign in from the order-at-table page, redirect back to the order-at-table page
    return {
      name: 'order-at-table',
      params: { restaurantId },
      query: { source }
    }
  }

  // Enforce onboarding completion if user has completed permissions
  completeOnboarding()

  if (source === 'onboarding') {
    // If the user was redirected to sign in from the onboarding page, redirect to the order config page
    return {
      name: 'config',
      query: { source }
    }
  } else {
    // If the user was redirected to sign in from any other page, redirect to the home page
    return {
      name: 'home'
    }
  }
}
