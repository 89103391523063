import { createRouter, createWebHistory } from '@ionic/vue-router'
import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteLocationPathRaw,
  RouteRecordRaw,
  RouteRecordSingleView
} from 'vue-router'
import middlewareAuth from '~/router/middleware/auth'
import {
  useCustomChoiceStore,
  useMenuStore,
  useOrderStore,
  useProductStore,
  useRestaurantStore,
  useTrackStore
} from '~/store'
import { OnlineOrderType } from '~/types/restaurant'
import { parseEmbeddedRoute, parseStringToQueryParams } from '~/utils/string'

/**
 * @description Web app routes.
 */
const routes: Readonly<RouteRecordRaw[]> = [
  {
    name: 'embed',
    path: '/embed',
    beforeEnter: async (
      to: RouteLocationNormalized,
      _from: RouteLocationNormalizedLoaded,
      next: NavigationGuardNext
    ) => {
      const orderStore = useOrderStore()
      const restaurantStore = useRestaurantStore()
      const menuStore = useMenuStore()
      const productStore = useProductStore()
      const customChoiceStore = useCustomChoiceStore()
      const trackStore = useTrackStore()
      // Send messages to marketing site
      emitSignedInState()
      emitUserTags()
      emitCartItemCount()

      const parsedPath = parseEmbeddedRoute(to.query.goto as string)

      const response: RouteLocationPathRaw = {
        path: parsedPath,
        query: {
          ...parseStringToQueryParams(parsedPath),
          from: 'embed',
          /**
           * @description The sign-up source attribute to send when user signs up.
           * This is used to track where the user signed up from.
           */
          r: to.query.r,
          source: to.query.source
        }
      }

      // Try to initialise the menu if not initialised yet
      async function tryInitialiseMenu(): Promise<boolean> {
        // Fetch initialise the restaurant if no restaurant is set
        if (!restaurantStore.restaurant) {
          // If no restaurant is set, initialise the default restaurant
          try {
            const { getApproximateStoreLocation } = useGoogleMapSearch()
            const storeLocation = await getApproximateStoreLocation()
            await restaurantStore.initDefaultRestaurant(undefined, storeLocation)
          } catch (e) {
            console.warn(`Could not initialise default restaurant.`)
            return false
          }
        }

        // Fetch menu if not fetched yet - note this requires the restaurant to be set
        if (!menuStore.menu) {
          try {
            await menuStore.fetchMenu()
          } catch (e) {
            console.warn(`Could not fetch menu. `)
            return false
          }
        }
        return true
      }

      // Handle setting the restaurant by id from query param in the URL
      async function handleRestaurantIdQuery(): Promise<void> {
        if (to.query.restaurant) {
          try {
            const restaurantId = Number(to.query.restaurant)
            if (!restaurantStore.restaurant?.id || restaurantStore.restaurant?.id !== restaurantId) {
              // TODO GRIL-412 improvement: rather than clearing cart here, perform full validation to validate the cart
              // and remove any items that are not valid for the selected restaurant
              orderStore.clearCartAndOrder()
              // also reset the menu so it will be re-fetched
              menuStore.resetMenu()
              productStore.clearStore()
              customChoiceStore.clearStore()
              const restaurant = await fetchRestaurantById(restaurantId)
              restaurantStore.setSelectedRestaurant(restaurant)
            }
          } catch (e) {
            console.error(`Could not find restaurant with ID from URL query param.`)
          }
        }
      }

      function handleCateringRoute(): void {
        // Handle routing to Catering
        if (parsedPath === '/catering' || parsedPath === 'catering') {
          restaurantStore.isCateringMode = true
          if (restaurantStore.orderConfig.orderType !== OnlineOrderType.Catering) {
            restaurantStore.resetOrderTypeAndTime()
            // clear the cart as it is not valid after switching to catering mode
            orderStore.clearCartAndOrder()
            // clear the existing restaurant if not specified in the query parameters
            if (!to.query.restaurant) {
              restaurantStore.clearSelectedRestaurant()
            }
          }
        } else {
          restaurantStore.isCateringMode = false
          if (restaurantStore.orderConfig.orderType === OnlineOrderType.Catering) {
            restaurantStore.resetOrderTypeAndTime()
            // clear the cart as it is not valid after switching from catering mode
            orderStore.clearCartAndOrder()
          }
        }
      }

      /**
       * @description Handle routing to menu category
       * @example /embed?goto=/menu/category/1
       * @example /embed?goto=/menu/category/1&restaurant=1
       */
      async function handleMenuCategoryRoute(): Promise<void> {
        if (parsedPath.includes('menu') && parsedPath.includes('category')) {
          /**
           * Gets the category ID from url path
           * @example /embed?goto=/menu/category/1
           */
          const categoryId = to.query.goto?.includes('category')
            ? Number(to.query.goto?.slice(to.query.goto?.lastIndexOf('/') + 1))
            : null

          if (categoryId) {
            if (await tryInitialiseMenu()) {
              try {
                menuStore.setActiveCategory(categoryId)
                response.query!.categoryId = categoryId
                response.path = '/home'
                return
              } catch (error) {
                console.warn(`Could not set active category with ID from URL query param.`)
              }
            }
          }

          console.warn('Unable to set category. Fall back to /home')
          response.path = '/home'
        }
      }
      async function handleMenuProductRoute(): Promise<void> {
        if (parsedPath.includes('menu') && parsedPath.includes('product')) {
          /**
           * Gets the product ID from url path
           * @example /embed?goto=/menu/product/1
           */
          const productId = to.query.goto?.includes('product')
            ? Number(to.query.goto?.slice(to.query.goto?.lastIndexOf('/') + 1))
            : null

          if (productId) {
            // Fetch menu if not fetched yet
            if (await tryInitialiseMenu()) {
              try {
                response.query!.productId = productId
                response.path = '/home'
                return
              } catch (error) {
                console.warn(`Could not set product ID from URL query param.`)
              }
            }
          }

          console.warn('Unable to set product. Fall back to /home')
          response.path = '/home'
        }
      }

      function handleUtmCodes(): void {
        // Handle UTM codes
        if (to.query.utm_source) {
          const utmCodes = {
            source: to.query.utm_source as string,
            medium: to.query.utm_medium as string,
            campaign: to.query.utm_campaign as string,
            content: to.query.utm_content as string,
            term: to.query.utm_term as string
          }
          trackStore.setUtmCodes(utmCodes)
        }
      }

      function handleAccountJourneyType(): void {
        if (!to.query.journeyType) {
          to.query.journeyType = 'marketing_site'
        }

        if (to.query.goto === '/sign-in') {
          gaEventSignInStart(to.query.journeyType as string)
        }
        if (to.query.goto === '/sign-up') {
          gaEventSignUpStart(to.query.journeyType as string)
        }
      }

      handleUtmCodes()
      await handleRestaurantIdQuery()
      await handleMenuCategoryRoute()
      await handleMenuProductRoute()
      handleCateringRoute()
      handleAccountJourneyType()

      next(response)
    }
  } as RouteRecordSingleView,
  {
    path: '/',
    redirect: '/home'
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('~/views/home/ViewHome.vue'),
    props: (route) => ({ productId: route.query?.productId, categoryId: route.query?.categoryId })
  },
  {
    name: 'config',
    path: '/config',
    component: () => import('~/views/order/ViewOrderConfig.vue')
  },
  {
    name: 'order-at-table',
    path: '/order-at-table/:restaurantId',
    component: () => import('~/views/order/ViewOrderAtTable.vue')
  },
  {
    name: 'catering',
    path: '/catering',
    component: () => import('~/views/order/ViewCateringOrderConfig.vue')
  },
  {
    name: 'restaurants',
    path: '/restaurants',
    component: () => import('~/views/restaurants/ViewRestaurants.vue')
  },
  {
    name: 'restaurant-detail',
    path: '/restaurants/:id',
    component: () => import('~/views/restaurants/ViewRestaurantItem.vue')
  },
  {
    name: 'menu',
    path: '/menu',
    component: () => import('~/views/menu/ViewMenu.vue')
  },
  {
    name: 'menu-product',
    path: '/menu/product/:id',
    component: () => import('~/views/menu/product/ViewProductItem.vue')
  },
  {
    name: 'menu-product-customisation',
    path: '/menu/product/:id/customise',
    component: () => import('~/views/menu/product/ViewProductItemCustomise.vue')
  },
  {
    name: 'menu-product-nutrition',
    path: '/menu/product/:id/nutritionalinfo',
    component: () => import('~/views/menu/product/ViewProductItemNutrition.vue')
  },
  {
    name: 'menu-product-list',
    path: '/menu/product/:id/productchoices',
    component: () => import('~/views/menu/product/ViewProductList.vue')
  },
  {
    name: 'menu-product-custom',
    path: '/menu/customproduct/:id',
    component: () => import('~/views/menu/product/ViewCustomProductItem.vue')
  },
  {
    name: 'menu-product-custom-customisation',
    path: '/menu/customproduct/:id/customise',
    component: () => import('~/views/menu/product/ViewCustomProductItemCustomise.vue')
  },
  {
    name: 'menu-product-custom-nutrition',
    path: '/menu/customproduct/:id/nutritionalinfo',
    component: () => import('~/views/menu/product/ViewCustomProductItemNutrition.vue')
  },
  {
    name: 'checkout',
    path: '/checkout',
    component: () => import('~/views/checkout/ViewCheckout.vue'),
    props: (route) => ({ replaceBackPath: route.query.replaceBackPath })
  },
  {
    name: 'history',
    path: '/history',
    component: () => import('~/views/order/ViewOrderHistory.vue')
  },
  {
    name: 'history-item',
    path: '/history/:id',
    component: () => import('~/views/order/ViewOrderHistoryItem.vue')
  },
  {
    name: 'profile',
    path: '/profile',
    component: () => import('~/views/profile/ViewSettings.vue')
  },
  {
    name: 'mad-bunday',
    path: '/profile/mad-bunday',
    component: () => import('~/views/profile/mad-bunday/ViewMadBunday.vue'),
    props: (route) => ({ source: route.query?.source, promo: route.query?.promo, referrer: route.query?.r }),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'edit-profile',
    path: '/profile/edit',
    component: () => import('~/views/profile/edit/ViewEditProfile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'edit-password',
    path: '/profile/edit/password',
    component: () => import('~/views/profile/edit/ViewEditPassword.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: () => import('~/views/login/ViewResetPassword.vue')
  },
  {
    name: 'edit-payment-methods',
    path: '/profile/edit/payment-methods',
    component: () => import('~/views/profile/edit/ViewEditPaymentMethods.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'relish',
    path: '/relish',
    component: () => import('~/views/relish/ViewRelish.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'relish-coupon-item',
    path: '/relish/coupons/:id',
    component: () => import('~/views/relish/coupons/ViewCouponItem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'sign-in',
    path: '/sign-in',
    component: () => import('~/views/login/ViewSignIn.vue')
  },
  {
    name: 'sign-up',
    path: '/sign-up',
    component: () => import('~/views/login/ViewSignUp.vue')
  },
  {
    name: 'sign-up-details',
    path: '/sign-up/details',
    beforeEnter: (to, from, next) => {
      if (!from.name) {
        next({ name: 'sign-up' })
      } else {
        next()
      }
    },
    component: () => import('~/views/login/ViewSignUpDetails.vue')
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    redirect: '/home'
  }
]

const webRouter = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes
})

webRouter.beforeEach((to) => {
  // Route Guard: if not authenticated, redirect to /home.
  if (to.meta.requiresAuth) {
    const redirect = middlewareAuth()

    if (redirect) {
      return redirect
    }
  }
})

webRouter.afterEach((to) => {
  window.fbq('track', 'ViewContent', { content_name: to.path })
  window.ttq.page()
})

export default webRouter
