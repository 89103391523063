import { defineStore } from 'pinia'
import { STORE_ID } from '~/constants/store'
import type { PermanentState as State } from '~/types/permanent'

const initialState = (): State => ({
  dontRequestLocationPermissionAgain: false
})

/**
 * @description Store for permanent state values - saved in local storage and NOT cleared by globalStore.resetStores
 */
const usePermanentStore = defineStore(STORE_ID.PERMANENT, {
  state: initialState,
  persist: true
})

export default usePermanentStore
