import { orderTypeOptions, PICKUP_MULTI_ITEM_KEY } from '~/constants/orderType'
import { OnlineOrderType } from '~/types/restaurant'
import type { OrderType } from '~/types/api/data-contracts'
import type { OrderTypeButtonGroupOption, SingleOrderTypeItem } from '~/types/orderType'

/**
 * @description Sanitize order types for online ordering
 * @param orderTypes {OrderType[]} - All Order types
 * @param excludedOrderTypes {OrderType[] | undefined} - Order types to exclude
 * @returns {OnlineOrderType[]} - Order types for online ordering
 */
export function getOnlineOrderTypes(
  orderTypes: OrderType[] | OnlineOrderType[],
  excludedOrderTypes?: OnlineOrderType[]
): OnlineOrderType[] {
  const availableOrderTypes = orderTypes.filter(
    (type) => OnlineOrderType[type as OrderType]
  ) as unknown as OnlineOrderType[]

  if (excludedOrderTypes) {
    return availableOrderTypes.filter((type) => !excludedOrderTypes.includes(type))
  }
  return availableOrderTypes
}

/**
 * @description Get icon for order type of online ordering
 * @param orderType {OnlineOrderType} - Order type for online ordering
 * @returns {string} - Ionic icon name/image url or empty string
 */
export function getOnlineOrderTypeIcon(orderType: OnlineOrderType | OrderType): string {
  switch (orderType) {
    case OnlineOrderType.Delivery:
      return 'grilld-delivery'
    case OnlineOrderType['Order at Table']:
      return 'grilld-order-at-table'
    case OnlineOrderType['Park & Collect']:
      return 'grilld-park-and-collect'
    case OnlineOrderType.Pickup:
      return 'grilld-pickup'
    case OnlineOrderType.Catering:
      return 'grilld-catering'
    case OnlineOrderType['Drive Thru']:
      return 'grilld-drive-thru'
    default:
      return ''
  }
}

/**
 * @description Get order type for online ordering
 * @param availableOrderTypes {OnlineOrderType[]} - List of available order types
 * @param orderType {OnlineOrderType || undefined} - Order type
 * @returns {OnlineOrderType} - Always returns a valid order type
 */
export function getOrderType(availableOrderTypes: OnlineOrderType[], orderType?: OnlineOrderType): OnlineOrderType {
  if (!orderType || !availableOrderTypes.includes(orderType)) {
    // If the restaurant supports "Pickup" (106)
    if (availableOrderTypes.includes(OnlineOrderType.Pickup)) {
      return OnlineOrderType.Pickup
      // Else set the first order type as active
    } else {
      return availableOrderTypes[0]
    }
  }

  return orderType
}

/**
 * @description Sort order types
 * @param rawItems - Unsorted order types
 * @param order - Order of order types
 * @returns {OrderTypeButtonGroupOption[]} - Sorted order types for online ordering
 */
export function sortOrderTypesItems<T extends OrderTypeButtonGroupOption>(rawItems: T[], order: number[]): T[] {
  return [...rawItems].sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
}

/**
 * @description Get order type option items and sorts them
 * @param orderTypes {OnlineOrderType[]} - Order types for online ordering
 * @param isCateringMode {boolean} - Catering mode
 * @returns {OrderTypeButtonGroupOption[]} - Order type option items
 */
export function getOrderTypeOptionItems(
  orderTypes: OnlineOrderType[] | OrderType[],
  isCateringMode?: boolean
): OrderTypeButtonGroupOption[] {
  // Iterate through the orderTypes, created two arrays
  // one array for pickup options and another for the rest of the order types
  const pickupOptions: SingleOrderTypeItem[] = []
  const options: OrderTypeButtonGroupOption[] = []

  for (const type of orderTypes) {
    switch (type) {
      case OnlineOrderType.Pickup:
        pickupOptions.push(orderTypeOptions[OnlineOrderType.Pickup])
        break
      case OnlineOrderType.Delivery:
        options.push(orderTypeOptions[OnlineOrderType.Delivery])
        break
      case OnlineOrderType['Order at Table']:
        options.push(orderTypeOptions[OnlineOrderType['Order at Table']])
        break
      case OnlineOrderType['Drive Thru']:
        pickupOptions.push(orderTypeOptions[OnlineOrderType['Drive Thru']])
        break
      case OnlineOrderType['Park & Collect']:
        pickupOptions.push(orderTypeOptions[OnlineOrderType['Park & Collect']])
        break
      case OnlineOrderType.Catering:
        if (isCateringMode) {
          options.push(orderTypeOptions[OnlineOrderType.Catering])
        }
        break
    }
  }

  if (!isCateringMode) {
    options.push(getPickupOptionItem(pickupOptions))
  }

  // Sort the display order of the options
  return sortOrderTypesItems(options, [
    PICKUP_MULTI_ITEM_KEY,
    OnlineOrderType.Pickup,
    OnlineOrderType.Delivery,
    OnlineOrderType['Order at Table'],
    OnlineOrderType.Catering
  ])
}

/**
 * @description Get pickup option items
 * @param pickupOptions
 * @returns {OrderTypeButtonGroupOption} - MultiItem if more than one pickup option or SingleItem
 */
function getPickupOptionItem(pickupOptions: SingleOrderTypeItem[]): OrderTypeButtonGroupOption {
  // If there is only one pickup option, return it as a SingleItem (as it is 106)
  if (pickupOptions.length === 1) {
    return pickupOptions[0]
  }

  const multiPickupOption: OrderTypeButtonGroupOption = {
    type: 'multi',
    id: PICKUP_MULTI_ITEM_KEY,
    label: orderTypeOptions[OnlineOrderType.Pickup].label,
    icon: 'grilld-pickup',
    items: []
  }

  // Add the pickup options to the multiPickupOption
  multiPickupOption.items = sortOrderTypesItems(pickupOptions, [
    OnlineOrderType['Drive Thru'],
    OnlineOrderType['Park & Collect'],
    OnlineOrderType.Pickup
  ]).map((item) => {
    const label = item.value === OnlineOrderType.Pickup ? `Pickup ⋅ at Counter` : `Pickup ⋅ ${item.label}`

    return {
      ...item,
      label
    }
  }, [])

  return multiPickupOption
}
