/**
 * This is a custom transition based upon the `ios-transition` in Ionic, modified to disable the back transition.
 * Based on https://github.com/ionic-team/ionic-framework/blob/v7.8.1/core/src/utils/transition/ios.transition.ts
 * This is a workaround for the issue with the back gesture transition conflicting with the ionic transition on iOS.
 */

import type { TransitionOptions } from '@ionic/vue'
import { iosTransitionAnimation } from '@ionic/vue'
import noneTransitionAnimation from './noneTransitionAnimation'

/**
 * Custom transition animation to prevent conflict with the ios swipe back gesture
 * animation
 * @param navEl
 * @param opts
 * @returns
 */
export default function customTransitionAnimation(navEl: HTMLElement, opts: TransitionOptions) {
  const backDirection = opts.direction === 'back'

  // If going back, no animation
  if (backDirection) {
    return noneTransitionAnimation()
  }

  return iosTransitionAnimation(navEl, opts)
}
