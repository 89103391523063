import { register } from 'swiper/element/bundle'
import type { Plugin } from 'vue'

import 'swiper/css'
import 'swiper/element/css/effect-coverflow'
import 'swiper/element/css/navigation'
import 'swiper/element/css/pagination'
import 'swiper/element/css/parallax'

/**
 * Initialise Swiper/Element
 */
export const swiperPlugin: Plugin = {
  install(_app, _options) {
    register()
  }
}
