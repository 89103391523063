import type { GChipSelectorItem } from '~/components/common/GChipSelector/types'
import type {
  MenuCategory,
  MenuItem,
  MenuItemChoice as MenuItemChoiceAPI,
  MenuItemExpanded as MenuItemExpandedAPI,
  MenuResponse
} from '~/types/api/data-contracts'

export type ProductAttributes = 'GFR' | 'DF' | 'V' | 'VE' | 'LC'

export enum ProductAttributeEnum {
  GlutenFree = 'GFR',
  DairyFree = 'DF',
  Vegan = 'V',
  Vegetarian = 'VE',
  LowCarb = 'LC'
}

export interface MenuState {
  activeCategory?: MenuCategory
  categoryFilters: GChipSelectorItem[]
  filterAttribute?: ProductAttributes
  menu?: MenuResponse
  lastUpdated: {
    menu?: number
  }
}

export interface MenuMultiChoiceItem extends MenuItem {
  quantity?: number
}

export interface MenuItemChoice extends MenuItemChoiceAPI {
  items: MenuMultiChoiceItem[]
}

export interface MenuItemExpanded extends MenuItemExpandedAPI {
  choices: MenuItemChoice[]
  multiChoices: MenuItemChoice[]
}
