import { defineStore } from 'pinia'
import { STORE_ID } from '~/constants/store'
import { useProductStore, useRestaurantStore } from '~/store'
import { generateCategoryFilters } from '~/utils/menu'
import type { GChipSelectorItem } from '~/components/common/GChipSelector/types'
import type { ECommerceItem } from '~/composables/analytics'
import type { MenuCategory, MenuResponse } from '~/types/api/data-contracts'
import type { MenuState as State, ProductAttributes } from '~/types/menu'
import { OnlineOrderType } from '~/types/restaurant'

const initialState = (): State => ({
  activeCategory: undefined,
  categoryFilters: [],
  filterAttribute: undefined,
  menu: undefined,
  lastUpdated: {
    menu: undefined
  }
})

/**
 * @name useMenuStore
 * @description Store for menu related data (active category, menu).
 * @returns {MenuState}
 */
const useMenuStore = defineStore(STORE_ID.MENU, {
  state: initialState,

  getters: {
    /**
     * @name getFilteredMenu
     * @description Returns the menu if it exists
     * @returns {MenuCategory[] | null}
     */
    getFilteredMenu(): MenuResponse | undefined {
      const attribute = this.getFilterAttribute
      if (attribute === undefined) {
        return this.menu
      }

      const categories =
        this.menu?.categories
          ?.map((category) => {
            const items = category.items.filter((item) => {
              return item.attributes.length === 0 || item.attributes.includes(attribute)
            })

            return {
              ...category,
              items
            }
          })
          .filter((category) => {
            return category.items.length > 0
          }) ?? []
      const filters =
        this.menu?.filters?.map((filter) => {
          const categoryIds = filter.categoryIds.filter((categoryId) => {
            return categories.find((value) => value.id === categoryId)
          })
          return {
            ...filter,
            categoryIds
          }
        }) ?? []

      return {
        categories,
        filters
      }
    },
    /**
     * @name getActiveCategory
     * @description Returns the active category if it exists
     * @returns {MenuCategory}
     */
    getActiveCategory(): MenuCategory | undefined {
      return this.getFilteredMenu?.categories?.find((category) => category.id === this.activeCategory?.id)
    },
    hasActiveCategory(): boolean {
      return this.getActiveCategory !== undefined
    },
    /**
     * @name getFilterAttribute
     * @description Returns the filter attribute if it is set
     * @returns {ProductAttributes | undefined}
     */
    getFilterAttribute(): ProductAttributes | undefined {
      return this.filterAttribute
    },
    getCategoryFilters(): GChipSelectorItem[] {
      return this.categoryFilters
    }
  },

  actions: {
    /**
     * @name setActiveCategory
     * @description Sets the active category
     * @param categoryId {number} - The id of the category to set as active
     * @returns {void}
     */
    setActiveCategory(categoryId: number): void {
      const newCategory = this.menu?.categories?.find((c) => c.id === categoryId)
      if (!newCategory) {
        throw new Error('Category not found')
      }
      const restaurantStore = useRestaurantStore()

      this.activeCategory = newCategory
      gaEventViewItemList({
        item_list_id: this.activeCategory.id.toString(),
        item_list_name: this.activeCategory.title,
        orderType: OnlineOrderType[restaurantStore.orderConfig.orderType],
        restaurantName: restaurantStore.getSelectedRestaurant ? restaurantStore.getSelectedRestaurant.name : '',
        items: this.activeCategory.items.map<ECommerceItem>((item) => ({
          item_id: item.id.toString(),
          item_name: item.title,
          price: item.priceInCents / 100,
          quantity: 1
        }))
      })
    },
    /**
     * @name setCategoryFilters
     * @description Sets the category filters
     * @returns {void}
     */
    setCategoryFilters(): void {
      if (
        !this.menu?.categories ||
        !this.menu?.filters ||
        this.menu.categories.length === 0 ||
        this.menu.filters.length === 0
      ) {
        this.categoryFilters = []
      }

      this.categoryFilters = generateCategoryFilters(this.getFilteredMenu as MenuResponse)
    },
    /**
     * @name setActiveCategory
     * @description Sets the active category
     * @returns {void}
     * @param attribute
     */
    setFilterAttribute(attribute: ProductAttributes | undefined): void {
      if (this.filterAttribute !== attribute) {
        const productStore = useProductStore()
        productStore.$reset()
      }
      this.filterAttribute = attribute
      if (attribute !== undefined) {
        gaEventMenuFilterApplied(attribute)
      }
      this.setCategoryFilters()
    },
    /**
     * @name fetchMenu
     * @description Fetches the menu from the API for the active restaurant and order type
     * @returns {Promise<void>}
     */
    fetchMenu: async function (): Promise<void> {
      const restaurantStore = useRestaurantStore()

      if (!restaurantStore.getSelectedRestaurant) {
        throw new Error('No selected restaurant provided')
      }

      try {
        this.menu = await getMenuForRestaurant(
          restaurantStore.getSelectedRestaurant.id,
          restaurantStore.getSelectedOrderType
        )

        this.lastUpdated.menu = Date.now()
      } catch (error: any) {
        throw new Error(error)
      } finally {
        this.setCategoryFilters()
      }
    },
    resetCategory(): void {
      this.activeCategory = undefined
    },
    resetMenu(): void {
      this.menu = undefined
    },
    resetState(): void {
      this.$reset()
    }
  },
  persist: true
})

export default useMenuStore
export { initialState }
