<template>
  <ion-app>
    <Suspense>
      <ion-router-outlet />

      <template #fallback>
        <GPageSpinner />
      </template>
    </Suspense>
  </ion-app>
</template>

<script lang="ts" setup>
  import { useBackButton, useIonRouter } from '@ionic/vue'
  const ionRouter = useIonRouter()
  const route = useRoute()

  useBackButton(10, (processNextHandler) => {
    if (route.path.startsWith('/home')) {
      // Do not navigate back from home, exit app or ignore TBC
      // The following is required to exit app
      // import { App } from '@capacitor/app'
      // App.exitApp()
    } else if (ionRouter.canGoBack()) {
      ionRouter.back()
    } else {
      processNextHandler()
    }
  })
</script>
