import { addIcons } from 'ionicons'

import IconHeartGrilld from '~/assets/icons/grilld-heart-filled-red.svg'
import IconHeartGrilldWhite from '~/assets/icons/grilld-heart-filled-white.svg'
import IconHeartOutlineGrilld from '~/assets/icons/grilld-heart-outline.svg'
import IconGrilldG from '~/assets/icons/grilld-g.svg'
import IconGrilldSquiggle from '~/assets/icons/grilld-squiggle.svg'
import IconGrilldSquiggleOutline from '~/assets/icons/grilld-squiggle-outline.svg'
import IconGrilldPinDefaultDisabled from '~/assets/icons/pin/grilld-default-disabled.svg'
import IconGrilldPinDefaultDisabledSelected from '~/assets/icons/pin/grilld-default-disabled-selected.svg'
import IconGrilldDefaultSelect from '~/assets/icons/pin/grilld-default-selected.svg'
import IconGrilldDefaultUnselected from '~/assets/icons/pin/grilld-default-unselected.svg'
import IconGrilldGamechangerSelected from '~/assets/icons/pin/grilld-gamechanger-selected.svg'
import IconGrilldGamechangerUnselected from '~/assets/icons/pin/grilld-gamechanger-unselected.svg'
import IconGrilldTick from '~/assets/icons/grilld-tick.svg'

// Payment method icons
import IconAmex from '~/assets/icons/amex.svg'
import IconVisa from '~/assets/icons/visa.svg'
import IconMasterCard from '~/assets/icons/mastercard.svg'
import IconPayPal from '~/assets/icons/paypal.svg'
import IconApplePay from '~/assets/icons/apple-pay.svg'
import IconGooglePay from '~/assets/icons/google-pay.svg'

// Order type icons
import IconCatering from '~/assets/icons/order-type/catering.svg'
import IconDelivery from '~/assets/icons/order-type/delivery.svg'
import IconDriveThru from '~/assets/icons/order-type/drive-thru.svg'
import IconOrderAtTable from '~/assets/icons/order-type/order-at-table.svg'
import IconParkAndCollect from '~/assets/icons/order-type/park-and-collect.svg'
import IconPickup from '~/assets/icons/order-type/pickup.svg'

/**
 * @description Add custom icons to the ionicons library
 */
const loadCustomIcons = () =>
  addIcons({
    'grilld-heart': IconHeartGrilld,
    'grilld-heart-white': IconHeartGrilldWhite,
    'grilld-heart-outline': IconHeartOutlineGrilld,
    'grilld-g': IconGrilldG,
    'grilld-squiggle': IconGrilldSquiggle,
    'grilld-squiggle-outline': IconGrilldSquiggleOutline,
    'grilld-pin-default-disabled': IconGrilldPinDefaultDisabled,
    'grilld-default-disabled-selected': IconGrilldPinDefaultDisabledSelected,
    'grilld-default-selected': IconGrilldDefaultSelect,
    'grilld-default-unselected': IconGrilldDefaultUnselected,
    'grilld-gamechanger-selected': IconGrilldGamechangerSelected,
    'grilld-gamechanger-unselected': IconGrilldGamechangerUnselected,
    'grilld-tick': IconGrilldTick,
    'grilld-catering': IconCatering,
    'grilld-delivery': IconDelivery,
    'grilld-drive-thru': IconDriveThru,
    'grilld-order-at-table': IconOrderAtTable,
    'grilld-park-and-collect': IconParkAndCollect,
    'grilld-pickup': IconPickup,
    'amex': IconAmex,
    'visa': IconVisa,
    'mastercard': IconMasterCard,
    'paypal': IconPayPal,
    'apple-pay': IconApplePay,
    'google-pay': IconGooglePay
  })

export default loadCustomIcons
