import { useMenuStore, useRestaurantStore } from '~/store'
import type { CartItem, CartItemIngredient, MenuItem } from '~/types/api/data-contracts'
import { MenuItemChoiceEnum } from '~/types/api/enums'
import { ProductAttributeEnum, type MenuItemChoice, type MenuItemExpanded } from '~/types/menu'
import type {
  MultiChoiceErrors,
  ProductState,
  SelectedChoiceItems,
  SelectedCustomChoiceItems,
  SelectedMultiChoiceItems,
  SelectedSingleChoiceItems
} from '~/types/product'
import {
  getCustomisationsPriceInCents,
  transformMenuItemToCartItemIngredient,
  transformMenuMultiChoiceItemToCartItemIngredient
} from '~/utils/product'

export function useProductCommon() {
  function getProductId(state: ProductState): number {
    return state.id || 0
  }

  function getAllSingleChoices(state: ProductState): MenuItemChoice[] {
    if (!state.product?.multiChoices) {
      return []
    }
    return state.product.multiChoices.filter(
      (choice) =>
        choice.minItemCount === 1 &&
        choice.maxItemCount === 1 &&
        (choice.type === MenuItemChoiceEnum.Single || !choice.type)
    )
  }

  function hasChoices(state: ProductState): boolean {
    return !!state.selectedSingleChoiceItems?.size
  }

  function getAllMultiChoices(state: ProductState): MenuItemChoice[] {
    if (!state.product?.multiChoices) {
      return []
    }
    return state.product.multiChoices.filter(
      (choice) =>
        (!choice.type && !(choice.minItemCount === 1 && choice.maxItemCount === 1)) ||
        choice.type !== MenuItemChoiceEnum.Single
    )
  }

  function getSelectedChoiceItem(state: ProductState, choiceTitle: MenuItemChoice['title']): MenuItem | undefined {
    if (!state.selectedSingleChoiceItems) {
      return undefined
    }

    return state.selectedSingleChoiceItems.get(choiceTitle)
  }

  function getSelectedChoiceItemId(
    state: ProductState,
    choiceTitle: MenuItemChoice['title']
  ): MenuItem['id'] | undefined {
    if (!state.selectedSingleChoiceItems) {
      throw new Error('Selected single choice items are not set')
    }

    return state.selectedSingleChoiceItems.get(choiceTitle)?.id
  }

  function getSelectedCustomChoices(
    state: ProductState,
    choiceTitle: MenuItemChoice['title']
  ): Array<CartItem | undefined> | undefined {
    if (!state.selectedCustomChoiceItems) {
      return undefined
    }

    const selectedChoice = state.selectedCustomChoiceItems.get(choiceTitle)

    if (!selectedChoice) {
      return undefined
    }

    if (Array.isArray(selectedChoice)) {
      return state.selectedCustomChoiceItems.get(choiceTitle) as Array<CartItem | undefined>
    }
  }

  function getSelectedMultiChoiceItem(
    state: ProductState,
    choiceTitle: MenuItemChoice['title']
  ): MenuItemChoice | undefined {
    if (!state.selectedMultiChoiceItems) {
      return undefined
    }
    const selectedChoice = state.selectedMultiChoiceItems.get(choiceTitle)

    if (!selectedChoice) {
      return undefined
    }
    if (!Array.isArray(selectedChoice)) {
      return state.selectedMultiChoiceItems.get(choiceTitle) as MenuItemChoice
    }
  }

  function hasMultiChoices(state: ProductState): boolean {
    return !!state.selectedMultiChoiceItems?.size
  }

  function isMultiChoiceError(state: ProductState, choiceTitle: MenuItemChoice['title']): boolean | undefined {
    if (!state.multiChoiceErrors) {
      return undefined
    }

    return state.multiChoiceErrors.get(choiceTitle)
  }

  function getCustomisationsAmount(state: ProductState): number {
    if (!state.product || !state.id) {
      return 0
    }

    return (
      state.customisations.additions.map((addition) => addition.quantity).reduce((curr, next) => curr + next, 0) +
      state.customisations.subtractions
        .map((subtraction) => subtraction.quantity)
        .reduce((curr, next) => curr + next, 0)
    )
  }

  function getPriceOfUpsellsInCents(state: ProductState): number {
    if (!state.customisations.upsells || state.customisations.upsells.length === 0) {
      return 0
    }

    return state.customisations.upsells.reduce((total, upsell) => total + upsell.priceInCents, 0)
  }

  function getPriceInCents(state: ProductState): number {
    if (!state.product || !state.id) {
      return 0
    }

    let totalPrice = state.product.priceInCents

    // Add price for selected choice items
    if (state.selectedSingleChoiceItems && state.selectedSingleChoiceItems.size > 0) {
      for (const choice of state.selectedSingleChoiceItems) {
        totalPrice += choice[1].priceInCents
      }
    }

    // Add price for selected multi-choice items
    if (state.selectedMultiChoiceItems && state.selectedMultiChoiceItems.size > 0) {
      for (const multiChoice of state.selectedMultiChoiceItems) {
        if (multiChoice[1].freeItemCount > 0) {
          let quantityCount = 0
          // Filter for items with quantity and sort by increasing price
          for (const item of multiChoice[1].items
            .filter((item) => item.quantity && item.quantity > 0)
            .sort((a, b) => a.priceInCents - b.priceInCents)) {
            // Only start incrementing the price when we reach the free cap
            if (item.quantity) {
              for (let i = 0; i < item.quantity; i++) {
                if (quantityCount >= multiChoice[1].freeItemCount) {
                  totalPrice += item.priceInCents
                }
                quantityCount++
              }
            }
          }
        } else {
          for (const item of multiChoice[1].items) {
            if (item.quantity) {
              totalPrice += item.quantity * item.priceInCents
            }
          }
        }
      }
    }

    // Add price for selected multi-choice items
    if (state.selectedCustomChoiceItems && state.selectedCustomChoiceItems.size > 0) {
      for (const customChoice of state.selectedCustomChoiceItems) {
        const choices = customChoice[1]

        for (const cartItem of choices) {
          if (!cartItem) {
            continue
          }
          totalPrice += getCustomisationsPriceInCents(cartItem.subtractions, cartItem.additions, cartItem.multiChoices)
        }
      }
    }

    // Remove subtractions from final price
    const subtractions = state.customisations.subtractions

    if (subtractions) {
      for (const subtraction of subtractions) {
        totalPrice += subtraction.priceInCents || 0
      }
    }

    // Add additions to final price
    const additions = state.customisations.additions
    if (additions) {
      for (const addition of additions) {
        const priceInCents = addition.priceInCents || 0
        totalPrice += priceInCents * addition.quantity
      }
    }

    return totalPrice * state.quantity
  }

  /**
   * @description Fetches the product from the API and sets it in the store
   * @param id {number} The product ID
   * @returns {Promise<MenuItemExpanded>} The product
   */
  async function fetchProduct(state: ProductState, id: number): Promise<MenuItemExpanded> {
    const restaurantStore = useRestaurantStore()

    if (!restaurantStore.restaurant?.id || !restaurantStore.orderConfig.orderType) {
      throw new Error('Restaurant or order type not set')
    }

    if (!id) {
      throw new Error('Product ID not set')
    }

    const res = await fetchMenuItemById({
      productId: id,
      restaurantId: restaurantStore.restaurant.id,
      orderType: restaurantStore.orderConfig.orderType
    })

    if (!res) {
      throw new Error('Product not found')
    }

    state.id = id
    state.product = res

    return res
  }

  /**
   * @description Updates the selected choice item for a given choice title
   * @param choiceTitle The title of the choice
   * @param item The selected choice item
   */
  function updateSelectedChoiceItem(state: ProductState, choiceTitle: MenuItemChoice['title'], item: MenuItem): void {
    if (!state.selectedSingleChoiceItems) {
      throw new Error('Selected choice items not set')
    }

    state.selectedSingleChoiceItems.set(choiceTitle, item)
  }

  /**
   * @description Updates the selected multi choice item for a given choice title
   * @param choiceTitle The title of the choice
   * @param item The selected choice item
   */
  function updateSelectedMultiChoice(
    state: ProductState,
    choiceTitle: MenuItemChoice['title'],
    choices: MenuItemChoice
  ): void {
    if (!state.selectedMultiChoiceItems) {
      throw new Error('Selected multi choice items not set')
    }

    // Add or update item in the map
    state.selectedMultiChoiceItems.set(choiceTitle, choices)
  }
  /**
   * @description Updates the selected error state given multi choice title
   * @param choiceTitle The title of the multi choice
   * @param isError The error state
   */
  function updateSelectedChoiceError(
    state: ProductState,
    choiceTitle: MenuItemChoice['title'],
    isError: boolean
  ): void {
    if (!state.multiChoiceErrors) {
      throw new Error('Selected multi choice errors not set')
    }

    state.multiChoiceErrors.set(choiceTitle, isError)
  }

  function updateSelectedCustomChoice(
    state: ProductState,
    choiceTitle: MenuItemChoice['title'],
    index: number,
    choice: CartItem | undefined
  ): void {
    if (!state.selectedCustomChoiceItems) {
      throw new Error('Selected custom choice items not set')
    }

    const choices = state.selectedCustomChoiceItems.get(choiceTitle)
    if (!choices) {
      throw new Error('Selected custom choice items not set')
    }
    choices[index] = choice
    // Add or update item in the map
    state.selectedCustomChoiceItems.set(choiceTitle, choices)
  }

  /**
   * @description Updates the additions array in the temp config state
   * If the ingredient is already in the additions array, update the quantity,
   * else if the new quantity is 0, remove the ingredient from the additions array,
   * otherwise, add the ingredient to the additions array
   */
  function updateAddition(state: ProductState, newAddition: CartItemIngredient): void {
    const existingAddition = state.customisations.additions.find((addition) => addition.id === newAddition.id)

    if (existingAddition) {
      if (newAddition.quantity === 0) {
        state.customisations.additions = state.customisations.additions.filter(
          (addition) => addition.id !== newAddition.id
        )
      } else {
        existingAddition.quantity = newAddition.quantity
      }
    } else {
      state.customisations.additions.push(newAddition)
    }
  }

  /**
   * @description Updates the subtractions array in the temp config state
   * If the ingredient is already in the subtractions array, remove it,
   * else add the ingredient to the subtractions array
   */
  function updateSubtraction(state: ProductState, subtraction: CartItemIngredient): void {
    const existingSubtraction = state.customisations.subtractions.find((item) => item.id === subtraction.id)

    if (existingSubtraction) {
      state.customisations.subtractions = state.customisations.subtractions.filter((item) => item.id !== subtraction.id)
    } else {
      state.customisations.subtractions.push(subtraction)
    }
  }

  /**
   * @description Initialise the selected choice items to the first item in each choice
   * e.g. { 'Choice 1': {...menuItem}, 'Choice 2': {...menuItem} }
   * @param choices The choices to reset
   * @param multiChoices - Already customised selected choice items
   */
  function initSelectedChoiceItems(
    state: ProductState,
    choices: MenuItemChoice[],
    multiChoices?: CartItemIngredient[][]
  ): void {
    const selectedSingleChoiceItems: SelectedSingleChoiceItems = new Map()
    const selectedChoiceItems: SelectedChoiceItems = new Map()
    const selectedMultiChoiceItems: SelectedMultiChoiceItems = new Map()
    const selectedCustomChoiceItems: SelectedCustomChoiceItems = new Map()

    /**
     * @description Gets the initial menu item for a choice
     * @param choice {MenuItemChoice} The choice to get the initial menu item for
     * @returns {MenuItem} The menu item
     */
    const getInitialMenuItem = (choice: MenuItemChoice): MenuItem => {
      const menuStore = useMenuStore()
      let initialItem: MenuItem | undefined

      switch (menuStore.filterAttribute) {
        case ProductAttributeEnum.GlutenFree:
          initialItem = choice.items.find((item) => item.attributes.includes('GFR'))
          break
        case ProductAttributeEnum.LowCarb:
          initialItem = choice.items.find((item) => item.attributes.includes('LC'))
          break
        case ProductAttributeEnum.DairyFree:
          initialItem = choice.items.find((item) => item.attributes.includes('DF'))
          break
        default:
          initialItem = choice.items[0]
          break
      }
      return initialItem ?? choice.items[0]
    }

    if (multiChoices?.length) {
      // Handle customised selected choice items
      // (i.e. data from a passed in prop)
      choices.forEach((choice, index) => {
        const isMultiChoice =
          choice.type === MenuItemChoiceEnum.Multiple ||
          (!choice.type && !(choice.minItemCount === 1 && choice.maxItemCount === 1))
        const isCustomChoice =
          choice.type === MenuItemChoiceEnum.Customise ||
          (!choice.type && !(choice.minItemCount === 1 && choice.maxItemCount === 1))

        if (!multiChoices[index]) {
          // If there are no customised selected choice items (e.g. data misaligned with menu item):
          // - return the first item in each choice, or set the multi choice to zero quantity
          if (isMultiChoice) {
            selectedMultiChoiceItems.set(choice.title, choice)
            selectedChoiceItems.set(choice.title, choice)
          } else if (isCustomChoice) {
            selectedCustomChoiceItems.set(choice.title, new Array(choice.maxItemCount).fill(undefined))
            selectedChoiceItems.set(choice.title, new Array(choice.maxItemCount).fill(undefined))
          } else {
            selectedSingleChoiceItems.set(choice.title, getInitialMenuItem(choice))
          }
        } else {
          if (isMultiChoice) {
            choice.items.forEach((item) => {
              // Aggregate the quantity of the selected choice items
              if (multiChoices[index].some((selectedChoice) => selectedChoice.id === item.id)) {
                item.quantity = multiChoices[index]
                  .filter((selectedChoice) => selectedChoice.id === item.id)
                  .reduce((n, item) => n + (item.quantity ?? 0), 0)
              } else {
                item.quantity = 0
              }
            })

            selectedMultiChoiceItems.set(choice.title, choice)
            selectedChoiceItems.set(choice.title, choice)
          } else if (isCustomChoice) {
            selectedCustomChoiceItems.set(choice.title, multiChoices[index])
            selectedChoiceItems.set(choice.title, multiChoices[index])
          } else {
            const selectedItem = choice.items.find((item) =>
              multiChoices[index][0] ? item.id === multiChoices[index][0].id : false
            )
            if (selectedItem) {
              selectedSingleChoiceItems.set(choice.title, selectedItem)
            } else {
              selectedSingleChoiceItems.set(choice.title, choice.items[0])
            }
          }
        }
      })
    } else {
      for (const choice of choices) {
        const isMultiChoice =
          choice.type === MenuItemChoiceEnum.Multiple ||
          (!choice.type && !(choice.minItemCount === 1 && choice.maxItemCount === 1))
        const isCustomChoice =
          choice.type === MenuItemChoiceEnum.Customise ||
          (!choice.type && !(choice.minItemCount === 1 && choice.maxItemCount === 1))
        if (isMultiChoice) {
          // If there are no customised selected configuration:
          // - set the multi choice to zero quantity, or select the maxItemCount
          if (choice.items.length === 1 && choice.maxItemCount === choice.minItemCount) {
            choice.items[0].quantity = choice.maxItemCount
          }
          selectedMultiChoiceItems.set(choice.title, choice)
          selectedChoiceItems.set(choice.title, choice)
        } else if (isCustomChoice) {
          // If there are no customised selected configuration:
          // - set the custom choice to undefined
          selectedCustomChoiceItems.set(choice.title, new Array(choice.maxItemCount).fill(undefined))
          selectedChoiceItems.set(choice.title, new Array(choice.maxItemCount).fill(undefined))
        } else {
          // If there are no customised selected configuration:
          // - handle dietary filters or,
          // - return the first item in each choice
          selectedSingleChoiceItems.set(choice.title, getInitialMenuItem(choice))
        }
      }
    }

    state.selectedSingleChoiceItems = selectedSingleChoiceItems
    state.selectedMultiChoiceItems = selectedMultiChoiceItems
    state.selectedCustomChoiceItems = selectedCustomChoiceItems
    state.selectedChoiceItems = selectedChoiceItems
  }
  function initMultiChoiceErrors(state: ProductState): void {
    const multiChoiceErrors: MultiChoiceErrors = new Map()
    state.multiChoiceErrors = multiChoiceErrors
  }
  /**
   * @description Initialises the product and upsell items as default
   * @param id {number} The product ID
   */
  async function initProduct(state: ProductState, id: number): Promise<void> {
    const { multiChoices } = await fetchProduct(state, id)

    state.customisations = {
      additions: [],
      subtractions: [],
      upsells: []
    }

    initSelectedChoiceItems(state, multiChoices)
    initMultiChoiceErrors(state)
  }
  /**
   * @description Initialises the product and upsell items with customised data
   * @param cartItem {CartItem} The cart item to initialise the product with
   */
  async function initCustomisedProduct(state: ProductState, cartItem: CartItem): Promise<void> {
    const { multiChoices, additions, subtractions } = await fetchProduct(state, cartItem.id)

    // Deep copy the cart item to avoid reactivity issues
    cartItem = JSON.parse(JSON.stringify(toRaw(cartItem)))

    // Set the quantity of the product
    state.quantity = cartItem.quantity || 1

    // Read customised additions from the cart and set them in the product store
    if (cartItem.additions) {
      // Find the additions from the cart item in the product.additions, set the quantity
      // transform into CartItemIngredient and set in the customisations.additions

      state.customisations.additions = cartItem.additions.reduce((acc, cartAddition) => {
        const addition = additions.find((addition) => addition.id === cartAddition.id)

        if (!addition) {
          return acc
        }

        acc.push({ ...transformMenuItemToCartItemIngredient(addition), quantity: cartAddition.quantity })

        return acc
      }, [] as CartItemIngredient[])
    }

    // Read customised subtractions from the cart and set them in the product store
    if (cartItem.subtractions) {
      // Find the subtractions from the cart item in the product.subtractions,
      // transform into CartItemIngredient and set in the customisations.subtractions
      state.customisations.subtractions = cartItem.subtractions.reduce((acc, cartSubtraction) => {
        const subtraction = subtractions.find((subtraction) => subtraction.id === cartSubtraction.id)

        if (!subtraction) {
          return acc
        }

        acc.push(transformMenuItemToCartItemIngredient(subtraction))

        return acc
      }, [] as CartItemIngredient[])
    }

    if (cartItem.multiChoices) {
      initSelectedChoiceItems(state, multiChoices, cartItem.multiChoices)
      initMultiChoiceErrors(state)
    }
  }

  /**
   * @description Prepares the product for adding to the cart
   * @returns {CartItem} The product in the format required for the cart
   */
  function prepareProductForCart(state: ProductState): CartItem {
    if (!state.product || !state.id) {
      return {} as CartItem
    }

    const payload: CartItem = {
      id: state.id,
      title: state.product.title,
      quantity: state.quantity,
      priceInCents: getPriceInCents(state)
    }

    if (hasChoices(state) || hasMultiChoices(state)) {
      payload.multiChoices = []

      state.product.multiChoices.forEach((choice) => {
        const isMultiChoice = choice.type === MenuItemChoiceEnum.Multiple
        const isCustomChoice = choice.type === MenuItemChoiceEnum.Customise

        if (isMultiChoice) {
          const selectedChoiceItem = state.selectedMultiChoiceItems?.get(choice.title)
          if (selectedChoiceItem) {
            const choices: CartItemIngredient[] = []
            selectedChoiceItem.items.forEach((item) => {
              if (item.quantity) {
                choices.push(transformMenuMultiChoiceItemToCartItemIngredient(item))
              }
            })
            payload.multiChoices!.push(choices)
          }
        } else if (isCustomChoice) {
          const selectedChoiceItem = state.selectedCustomChoiceItems?.get(choice.title)
          if (selectedChoiceItem) {
            const choices: CartItemIngredient[] = []
            selectedChoiceItem.forEach((item) => {
              if (item) {
                choices.push(item)
              }
            })
            payload.multiChoices!.push(choices)
          }
        } else {
          const selectedChoiceItem = state.selectedSingleChoiceItems?.get(choice.title)
          if (selectedChoiceItem) {
            payload.multiChoices!.push([transformMenuItemToCartItemIngredient(selectedChoiceItem)])
          }
        }
      })
    }

    if (state.customisations.additions.length) {
      payload.additions = state.customisations.additions.filter((item) => item.quantity > 0)
    }

    if (state.customisations.subtractions) {
      payload.subtractions = state.customisations.subtractions
    }

    return payload
  }

  /**
   * @description Validates the selected choice items are within the constraints
   */
  function validateMultiSelectChoices(state: ProductState): boolean {
    let anyErrors = false
    if (state.selectedMultiChoiceItems) {
      for (const multiChoice of state.selectedMultiChoiceItems) {
        const totalQuantity = multiChoice[1].items.reduce((n, item) => n + (item.quantity ?? 0), 0)
        let isError = totalQuantity < multiChoice[1].minItemCount || totalQuantity > multiChoice[1].maxItemCount
        //Also check if the individual item quantity is less than the individualMaxItemCount
        if (!isError) {
          if (multiChoice[1].maxIndividualItemCount) {
            isError = multiChoice[1].items.some((item) => (item.quantity ?? 0) > multiChoice[1].maxIndividualItemCount)
          }
        }
        updateSelectedChoiceError(state, multiChoice[1].title, isError)
        if (isError) {
          anyErrors = true
        }
      }
    }
    if (state.selectedCustomChoiceItems) {
      for (const customChoice of state.selectedCustomChoiceItems) {
        let isError = false
        for (const item of customChoice[1]) {
          if (item === undefined) {
            isError = true

            anyErrors = true
          }
        }
        updateSelectedChoiceError(state, customChoice[0], isError)
      }
    }
    return !anyErrors
  }

  return {
    getProductId,
    getAllSingleChoices,
    hasChoices,
    getAllMultiChoices,
    getSelectedChoiceItem,
    getSelectedChoiceItemId,
    getSelectedCustomChoices,
    getSelectedMultiChoiceItem,
    hasMultiChoices,
    isMultiChoiceError,
    getCustomisationsAmount,
    getPriceOfUpsellsInCents,
    getPriceInCents,
    fetchProduct,
    updateSelectedChoiceItem,
    updateSelectedMultiChoice,
    updateSelectedChoiceError,
    updateSelectedCustomChoice,
    updateAddition,
    updateSubtraction,
    initProduct,
    initCustomisedProduct,
    initSelectedChoiceItems,
    prepareProductForCart,
    validateMultiSelectChoices
  }
}
