import { defineStore } from 'pinia'
import { STORE_ID } from '~/constants/store'
import type { ProductState as State, ProductCustomisations } from '~/types/product'
import type { CartItem, CartItemIngredient, MenuItem } from '~/types/api/data-contracts'
import type { MenuItemChoice, MenuItemExpanded } from '~/types/menu'

import { useProductCommon } from '~/store/productStoreCommon'
import useProductStore from './product'

const initialState = (): State => ({
  id: undefined,
  product: undefined,
  quantity: 1,
  selectedSingleChoiceItems: undefined,
  customisedItem: undefined,
  activeCustomChoice: undefined,
  activeCustomChoiceIndex: undefined,
  customisations: {} as ProductCustomisations
})

/**
 * @name useCustomChoiceStore
 * @description Store for viewing a custom choice for a product menu item at /menu/product/:id
 *
 */
const useCustomChoiceStore = defineStore(STORE_ID.CUSTOM_CHOICE, {
  state: initialState,

  getters: {
    getProductId(): number {
      return useProductCommon().getProductId(this)
    },
    /**
     * @description Gets all single choices available for the product
     */
    getAllSingleChoices(): MenuItemChoice[] {
      return useProductCommon().getAllSingleChoices(this)
    },
    /**
     * @description Returns true if the product has any single choices available
     */
    hasChoices(): boolean {
      return useProductCommon().hasChoices(this)
    },
    /**
     * @description Gets all multi choices available for the product
     */
    getAllMultiChoices(): MenuItemChoice[] {
      return useProductCommon().getAllMultiChoices(this)
    },
    /**
     * @description Gets the selected choice item for a given choice title
     * @param choiceTitle {MenuItemChoice["title"]} - The title of the choice
     * @returns {MenuItem | undefined} - The selected choice item or undefined if not found
     */
    getSelectedChoiceItem: (state) => {
      return (choiceTitle: MenuItemChoice['title']): MenuItem | undefined => {
        return useProductCommon().getSelectedChoiceItem(state, choiceTitle)
      }
    },
    /**
     * @description Gets the selected choice item ID for a given choice title
     * @param choiceTitle {MenuItemChoice["title"]} - The title of the choice
     * @returns {MenuItem["id"] | undefined} - The selected choice item ID or undefined if not found
     */
    getSelectedChoiceItemId: (state) => {
      return (choiceTitle: MenuItemChoice['title']): MenuItem['id'] | undefined => {
        return useProductCommon().getSelectedChoiceItemId(state, choiceTitle)
      }
    },
    getSelectedCustomChoices: (state) => {
      return (choiceTitle: MenuItemChoice['title']): Array<CartItem | undefined> | undefined => {
        return useProductCommon().getSelectedCustomChoices(state, choiceTitle)
      }
    },
    /**
     * @description Gets the selected multi-choice item for a given choice title
     * @param choiceTitle {MenuItemChoice["title"]} - The title of the choice
     * @returns {MenuItemChoice | undefined} - The selected choice item or undefined if not found
     */
    getSelectedMultiChoiceItem: (state) => {
      return (choiceTitle: MenuItemChoice['title']): MenuItemChoice | undefined => {
        return useProductCommon().getSelectedMultiChoiceItem(state, choiceTitle)
      }
    },
    /**
     * @description Returns true if the product has any multi-choices available
     */
    hasMultiChoices(): boolean {
      return useProductCommon().hasMultiChoices(this)
    },
    isMultiChoiceError: (state) => {
      return (choiceTitle: MenuItemChoice['title']): boolean | undefined => {
        return useProductCommon().isMultiChoiceError(state, choiceTitle)
      }
    },
    /**
     * @description The amount of additions available for the product
     */
    getCustomisationsAmount(): number {
      return useProductCommon().getCustomisationsAmount(this)
    },
    /**
     * @description Returns the price of the product in cents including customisations
     */
    getPriceInCents(): number {
      return useProductCommon().getPriceInCents(this)
    },
    /**
     * @description Returns the price of all customisations and selections, excluding the base product price
     */
    getPriceForBundleInCents(): number {
      const totalPrice = this.getPriceInCents
      const basePrice = this.product?.priceInCents || 0
      return totalPrice - basePrice
    }
  },

  actions: {
    /**
     * @description Fetches the product from the API and sets it in the store
     * @param id {number} The product ID
     * @returns {Promise<MenuItemExpanded>} The product
     */
    async fetchProduct(id: number): Promise<MenuItemExpanded> {
      return await useProductCommon().fetchProduct(this, id)
    },
    /**
     * @description Updates the selected choice item for a given choice title
     * @param choiceTitle The title of the choice
     * @param item The selected choice item
     */
    updateSelectedChoiceItem(choiceTitle: MenuItemChoice['title'], item: MenuItem): void {
      useProductCommon().updateSelectedChoiceItem(this, choiceTitle, item)
    },
    /**
     * @description Updates the selected multi choice item for a given choice title
     * @param choiceTitle The title of the choice
     * @param item The selected choice item
     */
    updateSelectedMultiChoice(choiceTitle: MenuItemChoice['title'], choices: MenuItemChoice): void {
      useProductCommon().updateSelectedMultiChoice(this, choiceTitle, choices)
    },
    updateSelectedCustomChoice(
      choiceTitle: MenuItemChoice['title'],
      index: number,
      choice: CartItem | undefined
    ): void {
      useProductCommon().updateSelectedCustomChoice(this, choiceTitle, index, choice)
    },
    updateAddition(newAddition: CartItemIngredient): void {
      useProductCommon().updateAddition(this, newAddition)
    },
    updateSubtraction(subtraction: CartItemIngredient): void {
      useProductCommon().updateSubtraction(this, subtraction)
    },
    /**
     * @description Initialises the product and upsell items as default
     * @param id {number} The product ID
     */
    async initProduct(id: number): Promise<void> {
      await useProductCommon().initProduct(this, id)
    },
    /**
     * @description Initialises the product and upsell items with customised data
     * @param cartItem {CartItem} The cart item to initialise the product with
     */
    async initCustomisedProduct(cartItem: CartItem): Promise<void> {
      await useProductCommon().initCustomisedProduct(this, cartItem)
    },
    /**
     * @description Adds the product and all upsell items to the cart
     */
    async addToBundle(imageUrl: string | null | undefined): Promise<void> {
      const productStore = useProductStore()

      const choiceTitle = productStore.activeCustomChoice?.title
      const index = productStore.activeCustomChoiceIndex
      if (!choiceTitle) {
        console.warn(`No choice found for choice title`)
        return
      }
      const choice = productStore.getSelectedCustomChoices(choiceTitle)
      if (choice === undefined) {
        console.warn(`No choice found for choice title`, choiceTitle)
        return
      }
      if (index === undefined) {
        console.warn(`No active choice index`, choiceTitle)
        return
      } else {
        const cartItem = this.prepareProductForCart()
        cartItem.imageUrl = imageUrl
        choice[index] = cartItem
      }
    },
    /**
     * @description Prepares the product for adding to the cart
     * @returns {CartItem} The product in the format required for the cart
     */
    prepareProductForCart(): CartItem {
      return useProductCommon().prepareProductForCart(this)
    },
    /**
     * @description Validates the selected choice items are within the constraints
     */
    validateMultiSelectChoices(): boolean {
      return useProductCommon().validateMultiSelectChoices(this)
    },
    /**
     * @description Resets some of the store properties. Used when entering page with customised data
     */
    clearStore(): void {
      this.id = undefined
      this.product = undefined
      this.quantity = 1
    }
  }
})

export default useCustomChoiceStore
export { initialState }
