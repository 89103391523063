import useAuthStore from '../store/auth'
import useBrightnessStore from '../store/brightness'
import useCustomChoiceStore from '../store/customChoice'
import useMenuStore from '../store/menu'
import useOrderStore from '../store/order'
import useOrderHistoryStore from '../store/orderHistory'
import usePaymentStore from '../store/payment'
import useProductStore from '../store/product'
import usePromotionsStore from '../store/promotions'
import useRelishStore from '../store/relish'
import useRestaurantStore from '../store/restaurant'
import useTrackStore from '../store/track'
import useUiStore from '../store/ui'
import useUserStore from '../store/user'

/**
 * @description Reset pinia stores when logging out
 */
export function useResetStores() {
  function resetStores() {
    useAuthStore().$reset()
    useBrightnessStore().$reset()
    useCustomChoiceStore().$reset()
    useMenuStore().$reset()
    useOrderStore().$reset()
    useOrderHistoryStore().$reset()
    usePaymentStore().$reset()
    useProductStore().$reset()
    usePromotionsStore().$reset()
    useRelishStore().$reset()
    useRestaurantStore().$reset()
    useTrackStore().$reset()
    useUiStore().$reset()
    useUserStore().$reset()
  }

  return resetStores
}
