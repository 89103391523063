import { defineStore } from 'pinia'
import { STORE_ID } from '~/constants/store'
import type { RelishRequest } from '~/composables/relish'
import useAuthStore from '~/store/auth'
import useMenuStore from '~/store/menu'
import usePromotionsStore from '~/store/promotions'
import useRelishStore from '~/store/relish'
import useRestaurantStore from '~/store/restaurant'
import type { PollingState as State } from '~/types/polling'

const POLLING_INTERVAL = 60000 // 1 minute
const RELISH_POLLING_INTERVAL = 600000 // 10 minutes

const initialState = (): State => ({
  intervalId: undefined
})

/**
 * @name usePollingStore
 * @description Store for polling data from the server.
 *
 */
const usePollingStore = defineStore(STORE_ID.POLLING, {
  state: initialState,

  getters: {},

  actions: {
    /**
     * @description Global polling check.
     * It includes:
     * - restaurant closing time to set restaurant availability; if restaurant is closed
     * an active cart will be expired
     * - order type and time to reset if restaurant is closed and order type is not Order at Table
     * - promotions to fetch if not fetched or older than 10 minutes
     * - relish product recommendations to fetch if not fetched or older than 1 hour
     * - relish coupons to fetch if not fetched or older than 5 minutes
     * - relish eightAndDonate to fetch if not fetched or older than 1 hour
     */
    async initPolling() {
      const authStore = useAuthStore()
      const menuStore = useMenuStore()
      const promotionsStore = usePromotionsStore()
      const restaurantStore = useRestaurantStore()

      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      const intervalCallback = async () => {
        const requests: Array<() => Promise<void>> = []

        // Restaurant availability (i.e. restaurant)
        if (restaurantStore.isNonDemoRestaurantSelected) {
          requests.push(restaurantStore.checkRestaurantAvailability)
        }

        // Menu
        if (!menuStore.lastUpdated.menu || Date.now() - menuStore.lastUpdated.menu > RELISH_POLLING_INTERVAL) {
          requests.push(menuStore.fetchMenu)
        }

        // Promotions
        if (
          !promotionsStore.lastUpdated.promotions ||
          Date.now() - promotionsStore.lastUpdated.promotions > RELISH_POLLING_INTERVAL
        ) {
          requests.push(promotionsStore.fetchPromotions)
        }

        if (authStore.isAuthenticated) {
          const fetchRelishData = useFetchRelish()
          const relishStore = useRelishStore()
          const relishRequests: RelishRequest[] = []

          // Relish - Product recommendations
          if (restaurantStore.isNonDemoRestaurantSelected) {
            if (
              !relishStore.lastUpdated.productRecommendations ||
              Date.now() - relishStore.lastUpdated.productRecommendations > RELISH_POLLING_INTERVAL
            ) {
              relishRequests.push('productRecommendations')
            }
          }

          // Relish - Coupons
          if (
            !relishStore.lastUpdated.coupons ||
            Date.now() - relishStore.lastUpdated.coupons > RELISH_POLLING_INTERVAL
          ) {
            relishRequests.push('coupons')
          }

          // Relish - Eight and Donate
          if (
            !relishStore.lastUpdated.eightAndDonate ||
            Date.now() - relishStore.lastUpdated.eightAndDonate > RELISH_POLLING_INTERVAL
          ) {
            relishRequests.push('eightAndDonate')
          }

          if (relishRequests.length) {
            requests.push(() => fetchRelishData(relishRequests))
          }
        }

        if (!requests.length) return

        await Promise.allSettled(requests.map((request) => request()))
      }

      await intervalCallback()

      this.intervalId = window.setInterval(intervalCallback, POLLING_INTERVAL)
    },
    /**
     * @description Clear the polling interval
     */
    clearPolling() {
      if (this.intervalId) {
        window.clearInterval(this.intervalId)
        this.intervalId = undefined
      }
    }
  }
})

export default usePollingStore
export { initialState }
