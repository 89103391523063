// THIS FILE IS MANUALLY CREATED AND NOT GENERATED
// DEFINES ENUMS USED IN THE API

/**
 * @description Enum for the type of menu item choice
 * @see {MenuItemChoiceType}
 */
export enum MenuItemChoiceEnum {
  Single,
  Multiple,
  Customise
}

/**
 * @description Enum for the type of Promotion Action
 * @see {PromotionActionType}
 */
export enum PromotionActionEnum {
  None = 0,
  ExternalWebsite = 1,
  Coupon = 2,
  MenuCategory = 3,
  MenuItem = 4,
  Route = 5,
  MenuCategoryCoupon = 6,
  MenuItemCoupon = 7
}

/**
 * @description Enum for the type of Take or Donate
 * @see {TakeOrDonate}
 */
export enum TakeOrDonate {
  Take = 0,
  Donate = 1,
  None = 3
}
