import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import type { Plugin } from 'vue'

export const webAnalyticsPlugin: Plugin = {
  install(_app, _options) {
    const firebaseConfig = {
      apiKey: import.meta.env.VITE_WEB_FIREBASE_ANALYTICS_API_KEY,
      authDomain: import.meta.env.VITE_WEB_FIREBASE_ANALYTICS_AUTH_DOMAIN,
      projectId: import.meta.env.VITE_WEB_FIREBASE_ANALYTICS_PROJECT_ID,
      storageBucket: import.meta.env.VITE_WEB_FIREBASE_ANALYTICS_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_WEB_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_WEB_FIREBASE_ANALYTICS_APP_ID,
      measurementId: import.meta.env.VITE_WEB_FIREBASE_ANALYTICS_MEASUREMENT_ID
    }

    if (!isPlatform('capacitor')) {
      FirebaseAnalytics.initializeFirebase(firebaseConfig)
    }
  }
}
