import { useAuthStore, useUserStore } from '~/store'
import type { RouteLocationRaw } from 'vue-router'
import type { OnboardingQueries } from '~/types/onboard'

/**
 * @description Redirects to the post sign-in route.
 * @param source {ViewSource | undefined} The source of the current route
 * @param restaurantId {string | undefined} The restaurant id used in the order-at-table flow
 */
export default async function middlewareOnboard({
  source,
  restaurantId
}: OnboardingQueries): Promise<RouteLocationRaw | undefined> {
  const authStore = useAuthStore()
  const userStore = useUserStore()

  if (!userStore.hasOnboarded) {
    if (authStore.isAuthenticated) {
      console.warn('User is signed in but not onboarded - redirecting to onboarding')
      return await getPostSignInRoute({ source, restaurantId })
    }

    // Route Guard: if not onboarded, redirect to onboarding.
    console.warn('User has not onboarded - redirecting to onboarding')
    return { path: '/onboard', query: { source, restaurantId } }
  }

  return
}
