import { format, parse } from 'date-fns'
import { defineStore } from 'pinia'
import type { Router } from 'vue-router'

import {
  type DeliveryDetails,
  OnlineOrderType,
  type OrderAtTableDetails,
  type OrderConfig,
  type OrderTime,
  OrderTimeOption,
  type ParkAndCollectDetails,
  type RestaurantState as State,
  type TemporaryOrderConfig
} from '~/types/restaurant'
import type { LocalMatter, Restaurant } from '~/types/api/data-contracts'
import type { CateringDetails, DriveThruDetails } from '~/types/restaurant'
import { STORE_ID } from '~/constants/store'
import { shuffleArray } from '~/utils/array'
import { getEarliestScheduleTime } from '~/utils/dateTime'
import { getOnlineOrderTypes, getOrderType } from '~/utils/restaurant'
import { checkStoreIsOpen } from '~/utils/status'
import useCustomChoiceStore from '~/store/customChoice'
import useMenuStore from '~/store/menu'
import useOrderStore from '~/store/order'
import useProductStore from '~/store/product'

export type RestaurantApproximateLocation = 'VIC' | 'NSW' | 'QLD' | 'SA' | 'WA' | 'TAS' | 'ACT' | 'NT' | 'OTHER'

const initialOrderConfig: OrderConfig = {
  orderType: OnlineOrderType.Pickup,
  orderTime: {
    option: OrderTimeOption.NOW,
    dateTime: undefined
  },
  tableNumber: undefined,
  fullAddress: undefined,
  addressLine1: undefined,
  suburb: undefined,
  state: undefined,
  postcode: undefined,
  deliveryNotes: undefined,
  registration: undefined,
  colour: undefined,
  make: undefined,
  model: undefined,
  cateringDate: undefined,
  cateringTime: undefined
}

const initialState = (): State => ({
  restaurant: undefined,
  hasVisitedCheckoutPageWithActiveOrder: false,
  orderConfig: structuredClone(initialOrderConfig),
  tempOrderConfig: {} as TemporaryOrderConfig,
  isOpen: undefined,
  restaurantViewing: undefined,
  isDemoRestaurant: false,
  isCateringMode: false,
  shouldShowProminentPromotion: false,
  shouldShowOrderConfigConfirmationModal: false,
  lastPresentedOrderConfigConfirmationModalDateStamp: undefined
})

/**
 * @name useRestaurantStore
 * @description Store for restaurant related data (selected restaurant, order type, order configuration ui).
 */
const useRestaurantStore = defineStore(STORE_ID.RESTAURANT, {
  state: initialState,

  getters: {
    getHasVisitedCheckoutPageWithActiveOrder(): boolean {
      return this.hasVisitedCheckoutPageWithActiveOrder
    },
    getSelectedRestaurant(): Restaurant | undefined {
      return this.restaurant
    },
    getSelectedOrderType(): OnlineOrderType {
      return this.orderConfig.orderType
    },
    getAvailableOrderTypes(): OnlineOrderType[] {
      return getOnlineOrderTypes(this.restaurant?.orderTypes || [])
    },
    getOrderTime(): OrderTime {
      return this.orderConfig.orderTime
    },
    getDeliveryDetails(): DeliveryDetails {
      return {
        fullAddress: this.orderConfig.fullAddress,
        addressLine1: this.orderConfig.addressLine1,
        suburb: this.orderConfig.suburb,
        state: this.orderConfig.state,
        postcode: this.orderConfig.postcode,
        deliveryNotes: this.orderConfig.deliveryNotes
      }
    },
    getOrderAtTableDetails(): OrderAtTableDetails['tableNumber'] {
      return this.orderConfig.tableNumber
    },
    getParkAndCollectDetails(): ParkAndCollectDetails {
      return {
        registration: this.orderConfig.registration,
        colour: this.orderConfig.colour,
        make: this.orderConfig.make,
        model: this.orderConfig.model
      }
    },
    getOrderConfig(): OrderConfig {
      return this.orderConfig
    },
    getTempOrderConfig(): TemporaryOrderConfig {
      return this.tempOrderConfig
    },
    isOrderConfigFlowActive(): boolean {
      return this.tempOrderConfig.restaurant !== undefined
    },
    isNonDemoRestaurantSelected(): boolean {
      return !this.isDemoRestaurant && !!this.restaurant?.id
    },
    getRestaurantDetails(): Restaurant {
      return this.restaurantViewing!
    },
    getLocalMatters(): LocalMatter[] {
      return this.restaurant?.localMatters ? shuffleArray(this.restaurant.localMatters) : []
    },
    getWillShowOrderConfigConfirmation(): boolean {
      // Check conditions of when to show (once per day, restaurant open, user onboarded, etc.)
      if (!this.shouldShowOrderConfigConfirmationModal) {
        return false
      }

      if (this.isDemoRestaurant) {
        return false
      }

      const restaurant = this.getSelectedRestaurant
      if (!restaurant) {
        return false
      }

      if (!checkStoreIsOpen(restaurant.closingTimeToday, restaurant.isOnline)) {
        return false
      }

      if (
        this.lastPresentedOrderConfigConfirmationModalDateStamp &&
        this.lastPresentedOrderConfigConfirmationModalDateStamp >= format(new Date(), 'yyyyMMdd')
      ) {
        return false
      }

      return true
    }
  },

  actions: {
    async initDefaultRestaurant(
      vueRouter: Router | undefined,
      storeLocation: RestaurantApproximateLocation,
      isDemo = true
    ): Promise<void> {
      // QLD: Grill’d Southbank   30
      // NSW: Grill’d Rouse Hill  94
      // ACT: Grill’d Belconnen   45
      // VIC: Grill’d Hampton     196
      // SA: Grill’d Rundle St    107
      // WA: Grill’d Kings Way    201
      // NT: Grill’d Casuarina (only store in NT) 142

      let restaurantId = 196
      switch (storeLocation) {
        case 'NSW':
          restaurantId = 94
          break
        case 'QLD':
          restaurantId = 30
          break
        case 'SA':
          restaurantId = 107
          break
        case 'WA':
          restaurantId = 201
          break
        case 'ACT':
          restaurantId = 45
          break
        case 'NT':
          restaurantId = 142
          break
        case 'TAS':
        case 'VIC':
        default:
      }

      try {
        const response = await fetchRestaurantById(restaurantId)

        if (!response) {
          throw new Error(`Could not find restaurant with ID: ${restaurantId} from default location.`)
        }

        this.setSelectedRestaurant(response, isDemo)
      } catch (error: any) {
        console.warn('Failed to initialise default restaurant', error)
        throw new Error(error)
      }
    },
    /**
     * Global polling of the restaurant closing time to set restaurant availability; if restaurant is closed
     * an active cart will be expired
     */
    async checkRestaurantAvailability() {
      const orderStore = useOrderStore()

      if (!this.restaurant || this.isDemoRestaurant) {
        return
      }

      // Refetch restaurant to get updated status
      try {
        this.restaurant = await fetchRestaurantById(this.restaurant.id)
      } catch (error) {
        console.warn('Error fetching restaurant')
        return
      }
      if (this.orderConfig.orderType !== OnlineOrderType.Catering) {
        this.isOpen = checkStoreIsOpen(this.restaurant.closingTimeToday, this.restaurant.isOnline)

        if (this.isOpen) {
          // If restaurant is Open set the selected order type
          this.setSelectedOrderType(this.getSelectedOrderType)
        } else {
          // If restaurant is Closed
          // - Expire cart if restaurant is closed
          // - Reset orderType and orderTime, if not order at table
          orderStore.handleExpiredCartIfRestaurantClosed()
          if (this.orderConfig.orderType !== OnlineOrderType['Order at Table']) {
            this.resetOrderTypeAndTime()
          }
        }
      }
    },
    /**
     * @description Set the selected restaurant (same as user saved restaurant)
     * @param restaurant {Restaurant} - Restaurant to set as selected.
     * @param isDemo {boolean} - If the restaurant is a demo restaurant.
     */
    setSelectedRestaurant(restaurant: Restaurant, isDemo = false) {
      // Reset the demo restaurant flag
      this.isDemoRestaurant = false

      this.restaurant = restaurant

      // If restaurant is offline set order type to pickup
      this.setSelectedOrderType(this.getSelectedOrderType)

      // Allow the order confirmation modal to be shown for the selected restaurant in the future, but not today
      if (isDemo) {
        this.isDemoRestaurant = true
        // Do not show the order confirmation modal for demo restaurant
        this.lastPresentedOrderConfigConfirmationModalDateStamp = undefined
        this.shouldShowOrderConfigConfirmationModal = false
      } else {
        this.setLastPresentedOrderConfigConfirmationModalDateStampToToday()
        this.shouldShowOrderConfigConfirmationModal = true
        this.isOpen = checkStoreIsOpen(restaurant.closingTimeToday, restaurant.isOnline)
      }
      gaEventRestaurantSetAsSelected(restaurant.id)
    },
    clearSelectedRestaurant() {
      this.restaurant = undefined
      this.isOpen = undefined
      this.isDemoRestaurant = false
      this.shouldShowOrderConfigConfirmationModal = false
    },
    setLastPresentedOrderConfigConfirmationModalDateStampToToday(): void {
      this.lastPresentedOrderConfigConfirmationModalDateStamp = format(new Date(), 'yyyyMMdd')
    },
    setRestaurantDetails(restaurant: Restaurant): void {
      this.restaurantViewing = restaurant
    },
    /**
     * @description Set the selected order type (same as saved order type)
     * @param orderType {OnlineOrderType | undefined} - Order type to set as selected.
     */
    setSelectedOrderType(orderType?: OnlineOrderType): void {
      if (!this.getSelectedRestaurant) {
        throw new Error('Cannot set an order type without a selected restaurant.')
      }

      const newOrderType = getOrderType(this.getAvailableOrderTypes, orderType)
      if (newOrderType !== this.orderConfig.orderType) {
        this.orderConfig.orderType = newOrderType
        gaEventOrderTypeSetAsSelected(this.getSelectedRestaurant.id!, newOrderType)
      }
    },
    /**
     * @description Set the selected order time
     * @param orderTime {OrderTime} - Order time to set as selected.
     */
    setSelectedOrderTime(orderTime: OrderTime): void {
      if (!this.getSelectedRestaurant) {
        throw new Error('Cannot set an order time without a restaurant.')
      }

      this.orderConfig.orderTime = orderTime
    },
    /**
     * @description Set (save) the order configuration.
     * Only used within the order configuration flow.
     */
    saveOrderConfig(): void {
      if (!this.isOrderConfigFlowActive) {
        throw new Error('Cannot save order config without an active order config flow.')
      }

      const isResOpen = checkStoreIsOpen(
        this.getTempOrderConfig.restaurant.closingTimeToday,
        this.getTempOrderConfig.restaurant.isOnline
      )

      // If restaurant or order type has changed,
      // - reset the menu
      // - clear the product store
      // - clear the custom choice store
      if (
        this.getTempOrderConfig.restaurant.id !== this.getSelectedRestaurant?.id ||
        this.getTempOrderConfig.orderType !== this.getSelectedOrderType
      ) {
        const customChoiceStore = useCustomChoiceStore()
        const menuStore = useMenuStore()
        const productStore = useProductStore()

        menuStore.resetMenu()
        productStore.clearStore()
        customChoiceStore.clearStore()

        if (this.getTempOrderConfig.restaurant.id !== this.getSelectedRestaurant?.id) {
          this.setSelectedRestaurant(this.getTempOrderConfig.restaurant)
        }
      }

      if (this.getTempOrderConfig.orderType === OnlineOrderType.Catering) {
        this.setSelectedOrderType(this.getTempOrderConfig.orderType)
      } else {
        this.setSelectedOrderType(isResOpen ? this.getTempOrderConfig.orderType : initialOrderConfig.orderType)
      }

      // If order at table, clear any previously saved schedule time
      if (
        this.getSelectedOrderType === OnlineOrderType['Order at Table'] ||
        this.getSelectedOrderType === OnlineOrderType.Catering
      ) {
        this.setSelectedOrderTime({
          option: OrderTimeOption.NOW,
          dateTime: undefined
        })
      } else {
        this.setSelectedOrderTime(isResOpen ? this.getTempOrderConfig.orderTime : initialOrderConfig.orderTime)
      }

      this.setCateringOptions({
        cateringDate: this.getTempOrderConfig.cateringDate,
        cateringTime: this.getTempOrderConfig.cateringTime
      })
      this.setDeliveryOptions({
        fullAddress: this.getTempOrderConfig.fullAddress,
        addressLine1: this.getTempOrderConfig.addressLine1,
        suburb: this.getTempOrderConfig.suburb,
        state: this.getTempOrderConfig.state,
        postcode: this.getTempOrderConfig.postcode,
        deliveryNotes: this.getTempOrderConfig.deliveryNotes
      })
      this.setOrderAtTableOptions({
        tableNumber: this.getTempOrderConfig.tableNumber
      })
      this.setParkAndCollectOptions({
        registration: this.getTempOrderConfig.registration,
        colour: this.getTempOrderConfig.colour,
        make: this.getTempOrderConfig.make,
        model: this.getTempOrderConfig.model
      })
    },
    /**
     * @description Set temporary config for the order configuration ui.
     * @param config {Partial<TemporaryOrderConfig>} - Partial config to update the temporary config.
     */
    setTempOrderConfig(config: Partial<TemporaryOrderConfig>): void {
      if (config.restaurant) {
        // if restaurant is updated, order type should be updated as well
        // reset order time to default
        this.tempOrderConfig = {
          ...this.getTempOrderConfig,
          restaurant: config.restaurant,
          orderType: getOrderType(
            getOnlineOrderTypes(config.restaurant.orderTypes!),
            this.getTempOrderConfig.orderType
          ),
          orderTime: { ...initialOrderConfig.orderTime }
        }
      } else {
        // update temporary config from payload
        this.tempOrderConfig = {
          ...this.getTempOrderConfig,
          ...config
        }
      }
    },
    /**
     * @description Initialize temporary config for the order configuration ui.
     * A selected restaurant is required to initialize the temporary config.
     * @throws Error - If no active restaurant.
     */
    initTempOrderConfig(): void {
      if (!this.restaurant) {
        throw new Error('Cannot initialize temporary config without a selected restaurant.')
      }

      // If the current selected order time is before the earliest schedule time, reset to now
      let selectedOrderTime = this.getOrderTime
      if (!selectedOrderTime.dateTime) {
        selectedOrderTime = {
          option: OrderTimeOption.NOW,
          dateTime: undefined
        }
      } else {
        const earliestOrderTime = getEarliestScheduleTime(
          this.restaurant.openingTimeToday,
          this.restaurant.makeTimeInMinutes
        )
        if (new Date(selectedOrderTime.dateTime) < new Date(earliestOrderTime)) {
          selectedOrderTime = {
            option: OrderTimeOption.NOW,
            dateTime: undefined
          }
        }
      }

      // If the current selected catering date is in the past, reset to now
      // to prevent the date picker being initialised to a past date
      let existingCateringDate = this.orderConfig.cateringDate
      if (existingCateringDate) {
        const parsedCateringDate = parse(existingCateringDate, 'yyyy-MM-dd', new Date())
        if (parsedCateringDate < new Date()) {
          existingCateringDate = format(new Date(), 'yyyy-MM-dd')
        }
      }

      this.tempOrderConfig = {
        restaurant: this.restaurant,
        orderType: this.getSelectedOrderType,
        orderTime: selectedOrderTime || {
          option: OrderTimeOption.NOW,
          dateTime: undefined
        },
        tableNumber: this.orderConfig.tableNumber || '',
        fullAddress: this.orderConfig.fullAddress || '',
        addressLine1: this.orderConfig.addressLine1 || '',
        suburb: this.orderConfig.suburb || '',
        state: this.orderConfig.state || '',
        postcode: this.orderConfig.postcode || '',
        deliveryNotes: this.orderConfig.deliveryNotes || '',
        registration: this.orderConfig.registration || '',
        colour: this.orderConfig.colour || '',
        make: this.orderConfig.make || '',
        model: this.orderConfig.model || '',
        cateringDate: existingCateringDate || '',
        cateringTime: this.orderConfig.cateringTime || ''
      }

      gaEventOrderConfigModalViewed()
    },
    /**
     * @description Clear temporary config for the order configuration ui.
     */
    resetTempOrderConfig(): void {
      this.tempOrderConfig = initialState().tempOrderConfig
    },
    /**
     * @description Sets catering options for the order configuration.
     * If empty string is passed, the value is set to undefined.
     * @param DeliveryDetails {Required<DeliveryDetails>}
     */
    setCateringOptions({ cateringDate, cateringTime }: Required<CateringDetails>) {
      this.orderConfig.cateringDate = cateringDate || undefined
      this.orderConfig.cateringTime = cateringTime || undefined
    },
    /**
     * @description Sets delivery options for the order configuration.
     * If empty string is passed, the value is set to undefined.
     * @param DeliveryDetails {Required<DeliveryDetails>}
     */
    setDeliveryOptions({
      fullAddress,
      addressLine1,
      suburb,
      state,
      postcode,
      deliveryNotes
    }: Required<DeliveryDetails>) {
      this.orderConfig.fullAddress = fullAddress || undefined
      this.orderConfig.addressLine1 = addressLine1 || undefined
      this.orderConfig.suburb = suburb || undefined
      this.orderConfig.state = state || undefined
      this.orderConfig.postcode = postcode || undefined
      this.orderConfig.deliveryNotes = deliveryNotes || undefined
    },
    /**
     * @description Sets order at table options
     * If empty string is passed, the value is set to undefined.
     * @param OrderAtTableDetails {Required<OrderAtTableDetails>}
     */
    setOrderAtTableOptions({ tableNumber }: Required<OrderAtTableDetails>): void {
      this.orderConfig.tableNumber = tableNumber || undefined
    },
    /**
     * @description Sets park and collect options for the order configuration.
     * If empty string is passed, the value is set to undefined.
     * @param ParkAndCollectDetails {Required<ParkAndCollectDetails>}
     */
    setParkAndCollectOptions({ registration, colour, make, model }: Required<ParkAndCollectDetails>) {
      this.orderConfig.registration = registration || undefined
      this.orderConfig.colour = colour || undefined
      this.orderConfig.make = make || undefined
      this.orderConfig.model = model || undefined
    },
    /**
     * @description Sets drive thru options for the order configuration.
     * If empty string is passed, the value is set to undefined.
     * @param DriveThruDetails {Required<DriveThruDetails>}
     */
    setDriveThruOptions({ registration }: Required<DriveThruDetails>) {
      this.orderConfig.registration = registration || undefined
    },
    /**
     * @description Reset order type and order time to default.
     */
    resetOrderTypeAndTime(): void {
      this.orderConfig.orderType = initialOrderConfig.orderType
      this.orderConfig.orderTime = initialOrderConfig.orderTime
    },
    /**
     * @description Sets whether the user has visited the checkout page.
     */
    setHasVisitedCheckoutPageWithActiveOrder(value: boolean) {
      this.hasVisitedCheckoutPageWithActiveOrder = value
    }
  },
  persist: {
    paths: [
      'orderConfig',
      'restaurant',
      'isDemoRestaurant',
      'isCateringMode',
      'shouldShowProminentPromotion',
      'shouldShowOrderConfigConfirmationModal',
      'lastPresentedOrderConfigConfirmationModalDateStamp'
    ]
  }
})

export default useRestaurantStore
export { initialState }
