import { sha256 } from 'js-sha256'
import { useAppMode } from '~/composables/app'
import type { Cart, UserResponse } from '~/types/api/data-contracts'

function isMetaEnabled() {
  const appMode = useAppMode()

  // Conditionally enable meta tracking based on app mode
  // Currently enabled for both web and mobile
  return appMode.isWebMode || appMode.isMobileMode
}

function isTikTokEnabled() {
  const appMode = useAppMode()

  // Conditionally enable tiktok tracking based on app mode
  // Currently enabled for both web and mobile
  return appMode.isWebMode || appMode.isMobileMode
}

export function trackingIdentifyUser(user: UserResponse | undefined) {
  const config = useRuntimeConfig()

  // set up hashed user parameters or clear the existing user tracking when signed out

  function hash(val: string): string {
    const hash = sha256.create()
    hash.update(val)
    return hash.toString()
  }

  const params: UserIdentificationParams = { email: user?.emailAddress, externalId: user?.id }
  const hashedParams = {
    email: params.email ? hash(params.email) : undefined,
    phone_number: params.phone ? hash(params.phone) : undefined,
    external_id: params.externalId ? hash(params.externalId) : undefined
  }
  if (isMetaEnabled()) {
    // https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching/
    const metaParams = {
      em: hashedParams.email,
      external_id: hashedParams.external_id
    }
    window.fbq('init', config.public.metaPixelId, metaParams)
  }
  if (isTikTokEnabled()) {
    window.ttq.identify(hashedParams)
  }
}

export function trackingEventOnUserSuccessfullySignedUp(params: CompleteRegistrationTrackingParams) {
  if (isMetaEnabled()) {
    window.fbq('track', 'CompleteRegistration', params)
  }
  if (isTikTokEnabled()) {
    window.ttq.track('CompleteRegistration', params)
  }
}

export function trackingEventOnUserSignIn(params: SignInTrackingParams) {
  // No standard meta pixel event for sign in. Must use custom event.
  if (isMetaEnabled()) {
    window.fbq('trackCustom', 'user_sign_in', params)
  }
  if (isTikTokEnabled()) {
    window.ttq.track('user_sign_in', params)
  }
}

export function trackingEventBeginCheckout(params: BeginCheckoutTrackingParams) {
  if (isMetaEnabled()) {
    window.fbq('track', 'InitiateCheckout', params)
  }
  if (isTikTokEnabled()) {
    window.ttq.track('InitiateCheckout', params)
  }
}

export function trackingEventPurchase(params: PurchaseEventTrackingParams) {
  if (isMetaEnabled()) {
    window.fbq('track', 'Purchase', params)
  }
  if (isTikTokEnabled()) {
    const totalQuantity = params.contents ? params.contents.reduce((total, content) => total + content.quantity, 0) : 0
    const contents = params.contents
      ? params.contents.map((content) => ({ content_id: content.id, quantity: content.quantity }))
      : []
    window.ttq.track('CompletePayment', {
      content_type: params.content_type,
      quantity: totalQuantity,
      currency: params.currency,
      value: params.value,
      description: params.content_name,
      contents: contents
    })
  }
}

export function trackingEventViewItem(params: ViewContentTrackingParams) {
  if (isMetaEnabled()) {
    window.fbq('track', 'ViewContent', params)
  }
  if (isTikTokEnabled()) {
    window.ttq.track('ViewContent', {
      content_type: params.content_type,
      quantity: params.num_items,
      currency: params.currency,
      value: params.value,
      description: params.content_name,
      content_id: params.content_ids[0]
    })
  }
}

export function trackingEventAddToCart(params: AddToCartEventTrackingParams) {
  if (isMetaEnabled()) {
    window.fbq('track', 'AddToCart', params)
  }
  if (isTikTokEnabled()) {
    window.ttq.track('AddToCart', {
      content_type: params.content_type,
      quantity: params.contents[0].quantity,
      currency: params.currency,
      value: params.value,
      description: params.content_name,
      content_id: params.content_ids[0]
    })
  }
}

export function getTrackingContentIds(cart: Cart | undefined): string[] {
  return cart!.items ? cart!.items.map((item) => String(item.id)) : []
}

export function getTrackingContents(cart: Cart | undefined): TrackingContentObject[] {
  return cart!.items
    ? cart!.items.map(
        (item) =>
          ({
            id: String(item.id),
            quantity: item.quantity
          }) as TrackingContentObject
      )
    : []
}

export interface UserIdentificationParams {
  email?: string | null // The email of the user
  phone?: string | null // The phone number
  externalId?: string | null // The external ID of the user
}

export interface CompleteRegistrationTrackingParams {
  content_name?: string // Name of the page / product
  currency?: string // The value for the value specified
  status?: boolean // Used with the CompleteRegistration event to show the status of the registration
  value?: number // The value of a user performing this event to the business
}

export interface SignInTrackingParams {
  content_name?: string // Name of the page / product
  status?: boolean // Used with the CompleteRegistration event to show the status of the registration
}

export interface TrackingContentObject {
  id: string // The ID of the product
  quantity: number // The quantity of the product
}

export interface BeginCheckoutTrackingParams {
  content_ids: string[] // Array of product IDs
  content_category?: string // Category of the page/product.
  contents?: TrackingContentObject[] // An array of JSON objects that contains the quantity and the International Article Number (EAN) when applicable, or other product or content identifier(s)
  num_items?: number // Number of items in the cart
  currency?: string // Required the value for the value specified
  value?: number // Required the value of a user performing this event to the business
}

export interface PurchaseEventTrackingParams {
  content_ids: string[] // Array of product IDs
  content_name?: string // Name of the page / product
  content_type?: string // Type of content product or product_group
  contents: TrackingContentObject[] // An array of JSON objects that contains the quantity and the International Article Number (EAN) when applicable, or other product or content identifier(s)
  num_items?: number // Number of items in the cart
  currency: string // Required the value for the value specified
  value: number // Required the value of a user performing this event to the business
}

export interface AddToCartEventTrackingParams {
  content_ids: string[] // Array of product IDs
  content_name?: string // Name of the page / product
  content_type?: string // Type of content product or product_group
  contents: TrackingContentObject[] // An array of JSON objects that contains the quantity and the International Article Number (EAN) when applicable, or other product or content identifier(s)
  currency: string // Required the value for the value specified
  value: number // Required the value of a user performing this event to the business
}

export interface ViewContentTrackingParams {
  content_ids: string[] // Array of product IDs
  content_category?: string // Category of the page/product.
  content_name?: string // Name of the page / product
  content_type?: string // Type of content product or product_group
  contents: TrackingContentObject[] // An array of JSON objects that contains the quantity and the International Article Number (EAN) when applicable, or other product or content identifier(s)
  num_items?: number // Number of items in the cart
  currency: string // Required the value for the value specified
  value: number // Required the value of a user performing this event to the business
}
