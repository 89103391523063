import { useAuthStore, useOrderStore, useRelishStore } from '~/store'

type MessageToParentWindowOp =
  | 'signed_in_state'
  | 'cart_item_count'
  | 'user_tags'
  | 'did_sign_up_from_website'
  | 'did_sign_in_from_website'
  | 'did_sign_out_from_website'
  | 'order_at_table_error_dismissed'

function emitMessage(op: MessageToParentWindowOp, value: any = undefined) {
  window.parent.postMessage(
    JSON.stringify({
      key: 'GRILLD_ORDERING_APP_EVENT',
      op: op,
      value: value
    }),
    '*' // TODO: specify destination with this parameter to make this more secure
  )
}

/**
 * @description Emits a message to inform the parent window about the user's authentication state
 */
export function emitSignedInState(): void {
  const authStore = useAuthStore()
  emitMessage('signed_in_state', authStore.isAuthenticated)
}

/**
 * @description Emits a message to inform the parent window that the user
 */
export function emitUserTags(): void {
  const relishStore = useRelishStore()
  emitMessage('user_tags', relishStore.tags || {})
}

/**
 * @description Emits a message to inform the parent window about the number of items in the cart
 */
export function emitCartItemCount(): void {
  const orderStore = useOrderStore()
  emitMessage('cart_item_count', orderStore.cart?.items?.length ?? 0)
}

/**
 * @description Emits a message to inform the parent window that the user
 * has signed up from the website successfully
 * @param closeOrderingPanel {boolean} - Whether to close the ordering panel after the user has signed up
 */
export function emitDidSignUpFromWebsite(closeOrderingPanel?: boolean): void {
  emitMessage('did_sign_up_from_website', closeOrderingPanel ?? false)
}

/**
 * @description Emits a message to inform the parent window that the user
 * has signed in from the website successfully
 * @param closeOrderingPanel {boolean} - Whether to close the ordering panel after the user has signed in
 */
export function emitDidSignInFromWebsite(closeOrderingPanel?: boolean): void {
  emitMessage('did_sign_in_from_website', closeOrderingPanel ?? false)
}

/**
 * @description Emits a message to inform the parent window that the user
 * has signed out from the website successfully
 * @param closeOrderingPanel {boolean} - Whether to close the ordering panel after the user has signed out
 */
export function emitDidSignOutFromWebsite(closeOrderingPanel?: boolean): void {
  emitMessage('did_sign_out_from_website', closeOrderingPanel ?? true)
}

/**
 * @description Emits a message to inform the parent window that the user
 * has dismissed the error message on the order at table page
 */
export function emitOrderAtTableErrorDismissed(): void {
  emitMessage('order_at_table_error_dismissed')
}

export function emitGaEvent(eventName: string, params: object): void {
  window.parent.postMessage(
    JSON.stringify({
      key: 'GRILLD_GA_EVENT',
      op: eventName,
      value: params
    }),
    '*' // TODO: specify destination with this parameter to make this more secure
  )
}
