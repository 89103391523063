import { useAuthStore, useUserStore } from '~/store'
import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { getPostSignInRoute } from '~/composables/signIn'

export async function middlewareOrderAtTableMobile(toPath: RouteLocationNormalized['path']): Promise<RouteLocationRaw> {
  const authStore = useAuthStore()
  const userStore = useUserStore()

  const slug = toPath
    .split(/\.dev|\.com\.au/)
    .pop()
    ?.split('/')
    .filter((item) => item !== '')

  // We only push to the route if there is a slug present
  if (!slug) {
    if (!userStore.hasOnboarded) {
      return { name: 'onboard' }
    } else {
      return { name: 'home' }
    }
  }

  // e.g slug = ['order-at-table', '180']
  if (slug[0] === 'order-at-table') {
    gaEventLaunchViaQRCode()
    const restaurantId = slug[1]

    if (!userStore.hasOnboarded) {
      if (authStore.isAuthenticated) {
        return getPostSignInRoute({ source: 'order-at-table', restaurantId })
      }

      return { name: 'onboard', query: { source: 'order-at-table', restaurantId } }
    }
    // NOTE: The '/order-at-table' route validates the restaurant id
    return { name: 'order-at-table', params: { restaurantId } }
  }

  if (!userStore.hasOnboarded) {
    return { name: 'onboard' }
  } else {
    return { name: 'home' }
  }
}
