export const mobilePlatformQueryParam = (restaurantId?: number) => {
  let param = '?platform='

  const { isWebMode } = useAppMode()

  if (isWebMode) {
    param += 'web'
  } else if (isPlatform('ios')) {
    param += 'ios'
  } else if (isPlatform('android')) {
    param += 'android'
  } else {
    param += 'all'
  }

  if (restaurantId !== undefined) {
    param += `&restaurantId=${restaurantId}`
  }

  return param
}
