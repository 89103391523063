import { Loader } from '@googlemaps/js-api-loader'
import type { Plugin, InjectionKey } from 'vue'

const googleMapsLoaded = ref<boolean>(false)

export const googleMapsLoadedKey = Symbol() as InjectionKey<Ref<boolean>>

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places']
})

export const googleMapsLoader: Plugin = {
  install(app, _options) {
    loader
      .importLibrary('places')
      .then(async () => {
        googleMapsLoaded.value = true
      })
      .catch((e) => {
        console.warn('Google Maps Places API failed to load', e)
      })
    app.provide(googleMapsLoadedKey, readonly(googleMapsLoaded))
  }
}
