import { defineStore } from 'pinia'
import { STORE_ID } from '~/constants/store'
import type { UtmCodes, TrackState as State } from '~/types/track'

const initialState = (): State => ({
  utmCodes: undefined
})

/**
 * @description Store for user preferences - saved in local storage.
 */
const useTrackStore = defineStore(STORE_ID.TRACK, {
  state: initialState,

  getters: {},

  actions: {
    setUtmCodes(utmCodes: UtmCodes): void {
      try {
        this.utmCodes = utmCodes
      } catch (error) {
        console.error(error)
        throw new Error('Could not set utm codes')
      }
    }
  },
  // Do not persist for now as the utm codes are only relevant for the current session
  // This may be reviewed in the future as part of the social tracking feature
  persist: false
})

export default useTrackStore
export { initialState }
