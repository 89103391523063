import { Geolocation, type PositionOptions } from '@capacitor/geolocation'
import { until } from '@vueuse/core'
import { ofetch } from 'ofetch'
import { googleMapsLoadedKey } from '~/plugins/googlemaps'
import type { RestaurantApproximateLocation } from '~/store/restaurant'
import type { Coords } from '~/types/location'

const config = useRuntimeConfig()

interface GeolocationResponse {
  location: {
    lat: number
    lng: number
  }
  accuracy: number
}

/**
 * @description Gets local lat/long coordinates from device's GPS location
 * @param {Function} errorCallback
 * @param {number} timeout
 * @param {number} maximumAge - Default is 10 minutes
 * @returns {Promise<Coords>}
 */
export async function getCurrentPos(
  errorCallback?: (error: GeolocationPositionError) => void,
  timeout: number = 10000,
  maximumAge: number = 600000
): Promise<Coords> {
  // NOTE: don't call this function without guarding against location services potentially being turned off

  const positionOptions: PositionOptions = useAppMode().isWebMode
    ? { enableHighAccuracy: false, timeout, maximumAge }
    : { timeout, maximumAge }
  // getCurrentPosition will inherently request app permission if not already granted

  try {
    const { coords } = await Geolocation.getCurrentPosition(positionOptions)
    return {
      latitude: coords.latitude,
      longitude: coords.longitude
    }
  } catch (err: GeolocationPositionError | any) {
    console.warn('Error getting current position', err)
    if (errorCallback) {
      errorCallback(err)
    }
    throw err
  }
}

/**
 * @description Checks if app has permission to use GPS
 */
export async function hasGpsPermission(): Promise<boolean> {
  const permission = await Geolocation.checkPermissions()
  return permission.location === 'granted' || permission.coarseLocation === 'granted'
}

export async function hasDeniedGpsPermission(): Promise<boolean> {
  const permission = await Geolocation.checkPermissions()
  return permission.location === 'denied' && permission.coarseLocation === 'denied'
}

/**
 * @description Requests permission to use GPS if not granted or blocked
 */
export async function requestGpsPermission(): Promise<void> {
  if (!(await hasGpsPermission()) && !(await hasDeniedGpsPermission())) {
    if (isPlatform('capacitor')) {
      await Geolocation.requestPermissions()
    } else if ('geolocation' in navigator) {
      // Request browser location to trigger permission request, however we are not interested in the result, so set
      // a short timeout and ignore any errors
      try {
        await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve(position)
            },
            (error) => {
              reject(error)
            },
            { enableHighAccuracy: false, timeout: 1000, maximumAge: 600000 }
          )
        })
      } catch (error) {
        console.warn(error)
        return
      }
    } else {
      console.warn('Geolocation not supported')
      return
    }
  }
}

export function useGoogleMapSearch() {
  const autoComplete = ref<google.maps.places.AutocompleteService>()
  const geocoder = ref<google.maps.Geocoder>()
  const googleMapsLoaded = inject(googleMapsLoadedKey)

  async function init() {
    if (autoComplete.value && geocoder.value) {
      //Google Maps Places API already initialized
      return
    }

    if (googleMapsLoaded === undefined) {
      console.warn('Google Maps Places API not loaded')
      return
    }
    // Wait for Google Maps Places API to finish loading
    try {
      await until(googleMapsLoaded).toBe(true, { timeout: 10000, throwOnTimeout: true })
    } catch (error) {
      console.log('Google Maps Places API not yet loaded')
    }

    if (!googleMapsLoaded?.value || !google.maps) {
      console.warn('Google Maps Places API not loaded')
      return
    }
    autoComplete.value = new google.maps.places.AutocompleteService()
    geocoder.value = new google.maps.Geocoder()
  }

  async function searchAddressByCoords(coords: Coords): Promise<google.maps.GeocoderResult[] | undefined> {
    await init()
    if (!geocoder.value) {
      console.warn('Geocoder not initialized')
      return
    }

    const res = await geocoder.value.geocode(
      { location: { lat: coords.latitude, lng: coords.longitude } },
      (results, status) => {
        if (status !== 'OK') {
          throw new Error(`Geocoder failed due to: ${status}`)
        }

        if (!results) {
          throw new Error('No Google Maps results found')
        }

        return results
      }
    )

    return res.results
  }

  async function getApproximateStoreLocation(): Promise<RestaurantApproximateLocation> {
    try {
      await init()
    } catch (error) {
      console.warn('Google Maps Places API not loaded')
      return 'OTHER'
    }

    const geolocate = await ofetch<GeolocationResponse>(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=${config.public.googleMapsApiKey}`,
      {
        method: 'POST',
        body: { considerIp: true }
      }
    )

    if (!geocoder.value) {
      console.warn('Geocoder not initialized')
      return 'OTHER'
    }

    try {
      const res = await geocoder.value.geocode(
        {
          location: geolocate.location,
          region: 'AU'
        },
        (results, status) => {
          if (status !== 'OK') {
            throw new Error(`Geocoder failed due to: ${status}`)
          }

          if (!results) {
            throw new Error('No Google Maps results found')
          }
        }
      )

      const geolocation = res.results.find((result) => result.types.includes('administrative_area_level_1'))
      return geolocation?.address_components[0].short_name as RestaurantApproximateLocation
    } catch (error) {
      return 'OTHER'
    }
  }

  async function getPlacesPredictions(
    searchString: string,
    shouldOnlySearchForAddresses: boolean
  ): Promise<google.maps.places.AutocompletePrediction[] | undefined> {
    await init()

    if (!autoComplete.value) {
      console.warn('Autocomplete not initialized')
      return
    }
    const request = {
      input: searchString,
      componentRestrictions: {
        country: 'au'
      },
      types: shouldOnlySearchForAddresses ? ['address'] : []
    }

    const predictions = (await autoComplete.value.getPlacePredictions(request)).predictions

    return predictions
  }

  async function geocodePlace(
    placeId: string,
    callback: ((a: google.maps.GeocoderResult[] | null, b: google.maps.GeocoderStatus) => void) | null | undefined
  ): Promise<google.maps.GeocoderResult[] | undefined> {
    await init()
    if (!geocoder.value) {
      console.warn('Geocoder not initialized')
      return
    }

    const res = await geocoder.value.geocode({ placeId }, callback)

    return res.results
  }

  return {
    searchAddressByCoords,
    getApproximateStoreLocation,
    getPlacesPredictions,
    geocodePlace
  }
}
