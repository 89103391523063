import { useAuthStore } from '~/store'

export default function middlewareAuth(): Record<string, string> | undefined {
  const authStore = useAuthStore()

  // Route Guard: if not authenticated, redirect to home
  if (!authStore.isAuthenticated) {
    console.warn('User is not authenticated - redirecting to home')
    return { path: '/home' }
  }
}
