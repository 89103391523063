import * as Sentry from '@sentry/capacitor'
import * as SentrySibling from '@sentry/vue'
import type { Vue } from '@sentry/vue/types/types'
import type { Plugin } from 'vue'
import { version } from '~~/package.json'

export const sentryPlugin: Plugin = {
  install(app, _options) {
    const config = useRuntimeConfig()
    const isProduction = config.public.deployEnv === 'production'
    const isLocalDev = config.public.deployEnv === 'local'
    const sentryDsn = config.public.sentryDsn
    const environment = config.public.deployEnv
    if (!isLocalDev) {
      initSentry(app, isProduction, sentryDsn, environment)
    }
  }
}

function initSentry(vueApp: Vue, isProduction: boolean, dsn: string, environment: string) {
  // Configure sentry for App
  if (isPlatform('capacitor')) {
    Sentry.init(
      {
        app: vueApp,
        dsn,
        environment,
        release: 'grilld@' + version,
        dist: '1',
        tracesSampleRate: 0
      },
      SentrySibling.init
    )
  } else {
    // Configure sentry for web
    // TODO verify this approach works for web
    SentrySibling.init({
      app: vueApp,
      dsn,
      environment,
      release: 'grilld@' + version,
      dist: '1',
      tracesSampleRate: 0
    })
  }
}
