import { useUserStore } from '~/store'

export default function middlewareMobileRoot(): Record<string, string> {
  const userStore = useUserStore()

  // Route Guard: if not onboarded, redirect to onboarding.
  if (!userStore.hasOnboarded) {
    console.warn('User has not onboarded - redirecting to onboarding')
    return { path: '/onboard' }
  }

  // Route Guard: Otherwise, redirect to home.
  console.warn('User has onboarded - redirecting to home')
  return { path: '/home' }
}
