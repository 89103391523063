import { defineStore } from 'pinia'
import usePromotionsStore from './promotions'
import useRelishStore from './relish'
import useUserStore from './user'
import { gaEventOnUserSignOut } from '~/composables/analytics'
import { useResetStores } from '~/composables/store'
import { STORE_ID } from '~/constants/store'
import type { LoginResponse, UserResponse } from '~/types/api/data-contracts'
import type { AuthState as State } from '~/types/auth'

const initialState = (): State => ({
  authSession: undefined,
  authUser: undefined
})

/**
 * @description Store for auth data - saved in local storage.
 */
const useAuthStore = defineStore(STORE_ID.AUTH, {
  state: initialState,

  getters: {
    getAuthSession(): LoginResponse | undefined {
      return this.authSession
    },
    getAuthUser(): UserResponse | undefined {
      return this.authUser
    },
    isAuthenticated(): boolean {
      return !!this.authSession
    },
    isGuestUser(): boolean {
      const userStore = useUserStore()

      return !this.authSession && userStore.hasOnboarded
    }
  },

  actions: {
    async signIn(authSession: LoginResponse, authUser: UserResponse): Promise<void> {
      const { isWebMode } = useAppMode()
      const promotionsStore = usePromotionsStore()
      const relishStore = useRelishStore()
      const userStore = useUserStore()

      this.authSession = authSession
      this.authUser = authUser
      userStore.details = undefined

      // Set up user tracking
      trackingIdentifyUser(this.authUser)

      // Handle async requests
      const requests = []

      requests.push(promotionsStore.fetchPromotions())
      requests.push(relishStore.fetchEightAndDonate())

      if (isWebMode) {
        emitSignedInState()
        requests.push(relishStore.fetchLoyaltyTags())
      } else {
        const { setupMobileNotificationsForRelishUser } = useSalesforceNotifications()

        requests.push(setupMobileNotificationsForRelishUser(this.authUser.salesforceContactId ?? ''))
      }

      await Promise.allSettled(requests)
    },
    setAuthUser(authUser: UserResponse): void {
      this.authUser = authUser
    },
    updateUserAndSession(authSession: LoginResponse, authUser: UserResponse): void {
      // Set up user tracking
      trackingIdentifyUser(this.authUser)
      this.authUser = authUser
      this.authSession = authSession
    },
    signOut(): void {
      const { isWebMode } = useAppMode()
      const resetStores = useResetStores()

      gaEventOnUserSignOut()

      this.authSession = undefined
      this.authUser = undefined
      trackingIdentifyUser(undefined)

      resetStores()

      if (isWebMode) {
        emitCartItemCount()
        emitSignedInState()
        emitUserTags()
      }
    }
  },
  persist: true
})

export default useAuthStore
export { initialState }
