import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import type { Cart, Coupon } from '~/types/api/data-contracts'
import type { ViewProductSource, ViewSource } from '~/types/route'
import { useAppMode } from './app'
import { useAuthStore } from '~/store'

/**
 * Firebase Analytics custom event tracking
 */

async function trackEvent(eventName: string, params: object): Promise<void> {
  const { isWebMode } = useAppMode()

  // include isRelishMember (boolean formatted as string)
  const authStore = useAuthStore()
  params = {
    isRelishMember: authStore.isAuthenticated.toString(),
    ...params
  }

  try {
    if (isWebMode) {
      emitGaEvent(eventName, params)
    } else {
      await FirebaseAnalytics.logEvent({
        name: eventName,
        params
      })
    }
  } catch (error) {
    console.error('Error tracking event:', eventName, error)
  }
}

export function toGaEventCouponParameter(coupon: Coupon | undefined): string | undefined {
  if (!coupon) {
    return undefined
  }
  const couponParam = `${coupon.programId}: ${coupon.title}`
  // truncate to trunc characters
  const trunc = 95
  return couponParam.length > trunc ? couponParam.substring(0, trunc) : couponParam
}

export async function gaEventPromoCarouselItemTapped(name: string, id: number) {
  await trackEvent('promo_carousel_item_tapped', {
    context: name,
    subcontext: id.toString()
  })
}

export async function gaEventPromoButtonTapped(id: number, isProminent: boolean) {
  await trackEvent('promo_button_tapped', {
    context: id.toString(),
    subcontext: isProminent.toString()
  })
}

export async function gaEventRestaurantViewed(id: number) {
  await trackEvent('restaurant_viewed', {
    context: id.toString()
  })
}

export async function gaEventRestaurantCalled(id: number) {
  await trackEvent('restaurant_called', {
    context: id.toString()
  })
}

export async function gaEventRestaurantSetAsSelected(id: number) {
  await trackEvent('restaurant_selected', {
    context: id.toString()
  })
}

export async function gaEventOrderTypeSetAsSelected(restaurantId: number | null, orderType: number) {
  await trackEvent('restaurant_order_type_selected', {
    context: restaurantId?.toString() ?? 'null',
    subcontext: orderType.toString()
  })
}

export async function gaEventBunSelectionItemTapped(name: string) {
  await trackEvent('bun_selection_item_tapped', {
    context: name
  })
}

export async function gaEventNutritionalInfoButtonTapped(id: number) {
  await trackEvent('product_nutritional_info_button_tapped', {
    context: id.toString()
  })
}

export async function gaEventCustomiseIngredientsButtonTapped(id: number) {
  await trackEvent('customise_ingredients_button_tapped', {
    context: id.toString()
  })
}

export async function gaEventMenuFiltersViewed() {
  await trackEvent('menu_filters_viewed', {})
}

export async function gaEventMenuFilterApplied(name: string) {
  await trackEvent('menu_filters_applied', {
    context: name
  })
}

export async function gaEventMapSearchAddress(name: string) {
  await trackEvent('searched_map_address', {
    context: name
  })
}

export async function gaEventOrderConfigModalViewed() {
  await trackEvent('order_config_modal_viewed', {})
}

export async function gaEventOrderChangeRestaurantButtonTapped() {
  await trackEvent('order_config_modal_change_restaurant', {})
}

export async function gaEventOrderNowButtonTapped() {
  await trackEvent('order_config_modal_order_now_tapped', {})
}

export async function gaEventOrderScheduleButtonTapped() {
  await trackEvent('order_config_modal_order_schedule_tapped', {})
}

export async function gaEventOrderTypeUnavailable() {
  await trackEvent('order_type_unavailable', {})
}

export async function gaEventCheckoutRestaurantInRange() {
  await trackEvent('checkout_restaurant_in_range', {})
}

export async function gaEventCheckoutRestaurantOutOfRange() {
  await trackEvent('checkout_restaurant_out_of_range', {})
}

export async function gaEventCheckoutRestaurantDistanceIndeterminate() {
  await trackEvent('checkout_restaurant_distance_indeterminate', {})
}

export async function gaEventCheckoutChangeRestaurantButtonTapped() {
  await trackEvent('checkout_change_restaurant_button_tapped', {})
}

export async function gaEventAddMoreItemsButtonTapped() {
  await trackEvent('checkout_add_more_items_button_tapped', {})
}

export async function gaEventCouponUsedInTransaction(couponId: string) {
  await trackEvent('checkout_coupon_used_in_transaction', {
    context: couponId
  })
}

export async function gaEventLocalMatterVote(groupAllocationId: string) {
  await trackEvent('local_matter_vote', {
    context: groupAllocationId
  })
}

export async function gaEventParkAndCollectImHereButtonTapped() {
  await trackEvent('pc_im_here_button_tapped', {})
}

export async function gaEventParkAndCollectWhereCanIParkButtonTapped() {
  await trackEvent('pc_where_can_i_park_button_tapped', {})
}

export async function gaEventParkAndCollectIGotMyOrderButtonTapped() {
  await trackEvent('pc_i_got_my_order_button_tapped', {})
}

export async function gaEventParkAndCollectOrderDetailsButtonTapped() {
  await trackEvent('pc_order_details_button_tapped', {})
}

export async function gaEventPickupOrderDetailsButtonTapped() {
  await trackEvent('pickup_order_details_button_tapped', {})
}

export async function gaEventHomeScreenOrderBannerPickupDismissButtonTapped() {
  await trackEvent('pickup_checkout_dismiss_button_tapped', {})
}

export async function gaEventHomeScreenOrderBannerTrackOrderButtonTapped() {
  await trackEvent('delivery_checkout_track_button_tapped', {})
}

export async function gaEventHomeScreenOrderBannerDeliveryDismissButtonTapped() {
  await trackEvent('delivery_checkout_dismiss_button_tapped', {})
}

export async function gaEventUserSignUp(source: ViewSource) {
  await trackEvent('user_sign_up', {
    context: source
  })
}

export async function gaEventJoinRelishFirstPageViewed() {
  await FirebaseAnalytics.setScreenName({
    screenName: 'relish_registration_first_page',
    nameOverride: 'RelishRegistrationFirstScreen'
  })

  await trackEvent('relish_registration_first_page_viewed', {})
}

export async function gaEventJoinRelishSecondPageViewed() {
  await FirebaseAnalytics.setScreenName({
    screenName: 'relish_registration_second_page',
    nameOverride: 'RelishRegistrationSecondScreen'
  })

  await trackEvent('relish_registration_second_page_viewed', {})
}

export async function gaEventOnUserSignIn(source: ViewSource) {
  await trackEvent('user_sign_in', {
    context: source
  })
}

export async function gaEventOnUserSignOut() {
  await trackEvent('user_sign_out', {})
}

export async function gaEventOnUserAccountDelete() {
  await trackEvent('user_delete_account', {})
}

export async function gaEventOnUserSuccessfullySignedUp() {
  await trackEvent('user_successfully_signed_up', {})
}

export async function gaEventRelishCouponsPageViewed() {
  await trackEvent('relish_member_coupons_page_viewed', {})
}

export async function gaEventOnTakeButtonTapped() {
  await trackEvent('ate_and_donate_take_button_tapped', {})
}

export async function gaEventOnDonateButtonTapped() {
  await trackEvent('ate_and_donate_donate_button_tapped', {})
}

export async function gaEventAddToOrderButtonTappedFromPromo(id: number) {
  await trackEvent('product_added_to_cart_from_promo', {
    context: id.toString()
  })
}

export async function gaEventQuickIngredientTapped() {
  await trackEvent('product_quick_ingredient_tapped', {})
}

export async function gaEventLaunchViaQRCode() {
  await trackEvent('app_launch_via_qr_code', {})
}

export async function gaAppRatingSubmit(rating: 'positive' | 'negative' | 'later') {
  await trackEvent('app_rating_submit', { context: rating })
}
/**
 * Firebase eCommerce event tracking
 */
export function getECommerceEventItems(cart: Cart | undefined, location_id: string): ECommerceItem[] {
  return cart!.items
    ? cart!.items.map((item) => {
        return {
          item_id: String(item.id),
          item_name: item.title || '',
          price: item.priceInCents! / 100,
          quantity: item.quantity,
          location_id
        } as ECommerceItem
      })
    : []
}

export interface ECommerceItem {
  item_id?: string // one of item_id or item_name is required
  item_name?: string
  affiliation?: string // A product affiliation to designate a supplying company or brick and mortar store location.
  coupon?: string // The coupon name/code associated with the item, independent of the total order coupon.
  discount?: number // The monetary discount associated with the item.
  index?: number // The index of the item in the list of items ordered.
  item_brand?: string // The brand associated with the item.
  item_category?: string // The category of the item. If used as part of a category hierarchy or taxonomy then this will be the first category.
  item_category2?: string // The second category hierarchy or additional taxonomy for the item.
  item_category3?: string // The third category hierarchy or additional taxonomy for the item.
  item_category4?: string // The fourth category hierarchy or additional taxonomy for the item.
  item_category5?: string // The fifth category hierarchy or additional taxonomy for the item.
  item_list_id?: string // The ID of the list in which the item was presented to the user. If set, event-level item_list_id is ignored. If not set, event-level item_list_id is used, if present.
  item_list_name?: string // The name of the list in which the item was presented to the user. If set, event-level item_list_name is ignored. If not set, event-level item_list_name is used, if present.
  item_variant?: string //The item variant or unique code or description for additional item details/options.
  location_id?: string // The physical location associated with the item (e.g. the physical store location). It's recommended to use the Google Place ID that corresponds to the associated item. A custom location ID can also be used. Note: `location id` is only available at the item-scope.
  price?: number // The monetary price of the item, in units of the specified currency parameter.
  quantity: number // The quantity of the item purchased. If not set, defaults to 1.
  // Grill'd custom parameters (up to 27 custom parameters are supported)
}

export interface PurchaseEventECommerceParams {
  currency: string // The local currency of the transaction. Currency must be a valid ISO 4217 currency code. If you set value then currency is required for revenue metrics to be computed accurately.
  transaction_id: string // The unique identifier of a transaction. Use redcatOrderId
  value: number // The monetary value of the transaction in units of the specified currency parameter. currency is required if you set value.
  coupon?: string // The coupon name/code associated with the event. Event-level and item-level coupon parameters are independent.
  shipping?: number // The shipping cost associated with the transaction.
  tax?: number // The tax cost associated with the transaction.
  items: Array<ECommerceItem> // The items that were purchased. Note: The items field is required for the event to be considered an Ecommerce event.
  // Grill'd custom parameters
  redcatOrderId: string
  orderType: string
  reorder: string // boolean formatted as string
  recommendation: string // boolean formatted as string
  upsell: string // boolean formatted as string
  purchase: string // value formatted as string
  restaurantName: string
}

export interface BeginCheckoutEventECommerceParams {
  currency: string // The local currency of the transaction. Currency must be a valid ISO 4217 currency code. If you set value then currency is required for revenue metrics to be computed accurately.
  value: number // The monetary value of the transaction in units of the specified currency parameter. currency is required if you set value.
  coupon?: string // The coupon name/code associated with the event. Event-level and item-level coupon parameters are independent.
  items: Array<ECommerceItem> // The items that were purchased. Note: The items field is required for the event to be considered an Ecommerce event.
  // Grill'd custom parameters
  orderType: string
  reorder: string // boolean formatted as string
  recommendation: string // boolean formatted as string
  upsell: string // boolean formatted as string
  purchase: string // value formatted as string
  restaurantName: string
}

export interface AddPaymentInfoECommerceParams {
  currency: string
  value: number
  payment_type: string
  orderType: string
  restaurantName: string
  items: ECommerceItem[]
}

export interface ViewItemListECommerceParams {
  item_list_id: string
  item_list_name: string
  orderType: string
  restaurantName: string
  items: ECommerceItem[]
}

export interface ItemECommerceParams {
  currency: string
  value: number
  orderType: string
  context?: ViewProductSource | 'cross-sell' | 'custom-product'
  restaurantName: string
  items: ECommerceItem[]
}

export interface AddShippingInfoECommerceParams {
  currency: string
  value: number
  shipping_tier: string
  orderType: string
  restaurantName: string
  items: ECommerceItem[]
}

export async function gaEventViewItemList(params: ViewItemListECommerceParams) {
  await trackEvent('view_item_list', params)
}

export async function gaEventSelectItem(params: ViewItemListECommerceParams) {
  await trackEvent('select_item', params)
}

export async function gaEventViewItem(params: ItemECommerceParams) {
  await trackEvent('view_item', params)
}

export async function gaEventViewItemInCart(params: ItemECommerceParams) {
  await trackEvent('view_item', params)
}

export async function gaEventAddToCart(params: ItemECommerceParams) {
  await trackEvent('add_to_cart', params)
}

export async function gaEventUpdateItemInCart(params: ItemECommerceParams) {
  await trackEvent('add_to_cart', params)
}

export async function gaEventViewCart(params: ItemECommerceParams) {
  await trackEvent('view_cart', params)
}

export async function gaEventRemoveFromCart(params: ItemECommerceParams) {
  await trackEvent('remove_from_cart', params)
}

export async function gaEventBeginCheckout(params: BeginCheckoutEventECommerceParams) {
  await trackEvent('begin_checkout', params)
}

export async function gaEventAddShippingInfo(params: AddShippingInfoECommerceParams) {
  await trackEvent('add_shipping_info', params)
}

export async function gaEventAddPaymentInfo(params: AddPaymentInfoECommerceParams) {
  await trackEvent('add_payment_info', params)
}

export async function gaEventPurchase(params: PurchaseEventECommerceParams) {
  await trackEvent('purchase', params)
}

export async function gaEventNewAppCarouselInteraction(title: string, index: number) {
  await trackEvent('new_app_carousel_interaction', {
    context: title,
    subcontext: index.toString()
  })
}

export async function gaEventFavouriteItemTapped(favouriteItemProductId: number) {
  await trackEvent('favourite_item_tapped', {
    context: favouriteItemProductId.toString()
  })
}

export async function gaEventFavouriteItemAddedToCart(favouriteItemProductId: number) {
  await trackEvent('favourite_item_added_to_cart', {
    context: favouriteItemProductId.toString()
  })
}

export interface DeliveryUnavailableEventAnalyticsParams {
  restaurantName: string
  address: string
}

export async function gaEventDeliveryToAddressUnavailable(params: DeliveryUnavailableEventAnalyticsParams) {
  await trackEvent('delivery_to_address_unavailable', params)
}

export async function gaEventOrderTypeChangedAfterVistingCheckoutPage() {
  await trackEvent('visited_checkout_order_type_changed', {})
}

export async function gaEventContinueAsGuestFromStartScreen() {
  await trackEvent('continue_as_guest_from_start_screen', {})
}

export async function gaEventContinueAsGuestFromRelishDisruptor() {
  await trackEvent('continue_as_guest_from_relish_disruptor', {})
}

export async function gaEventSignInModalNavigateBack() {
  await trackEvent('sign_in_modal_navigate_back', {})
}

export async function gaEventSignUpFirstPageModalNavigateBack() {
  await trackEvent('sign_up_first_page_modal_navigate_back', {})
}

export async function gaEventSignUpSecondPageModalNavigateBack() {
  await trackEvent('sign_up_second_page_modal_navigate_back', {})
}

export async function gaEventSignInStart(journeyType: string) {
  await trackEvent('sign_in_start', { journeyType })
}

export async function gaEventSignUpStart(journeyType: string) {
  await trackEvent('sign_up_start', { journeyType })
}
