import { v4 as uuidv4 } from 'uuid'
import { LocalNotifications } from '@capacitor/local-notifications'
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings'
import { useAppMode } from '~/composables/app'

// Declare the SalesForce Marketing Cloud plugin so Typescript is happy
declare let MCCordovaPlugin: any

/**
 * @description Setup the SalesForce Marketing Cloud plugin for mobile notifications.
 */
export function useSalesforceNotifications() {
  const { isWebMode } = useAppMode()

  async function getSystemToken(): Promise<string> {
    if (isWebMode || !isPlatform('capacitor')) {
      return ''
    }
    return await new Promise<string>((resolve, reject) => {
      MCCordovaPlugin.getSystemToken(
        (token: string) => {
          resolve(token)
        },
        () => {
          console.error('MCCordovaPlugin.getSystemToken failed')
          reject(new Error('MCCordovaPlugin.getSystemToken failed'))
        }
      )
    })
  }

  async function getDeviceId(): Promise<string> {
    if (isWebMode || !isPlatform('capacitor')) {
      return ''
    }
    return await new Promise<string>((resolve, reject) => {
      MCCordovaPlugin.getDeviceId(
        (deviceId: string) => {
          resolve(deviceId)
        },
        () => {
          console.error('MCCordovaPlugin.getDeviceId failed')
          reject(new Error('MCCordovaPlugin.getDeviceId failed'))
        }
      )
    })
  }

  async function logSdkState(): Promise<void> {
    if (isWebMode || !isPlatform('capacitor')) {
      return
    }

    return await new Promise<void>((resolve, reject) => {
      MCCordovaPlugin.logSdkState(
        () => {
          resolve()
        },
        () => {
          console.error('MCCordovaPlugin.logSdkState failed')
          reject(new Error('MCCordovaPlugin.logSdkState failed'))
        }
      )
    })
  }

  async function isPushEnabled(): Promise<boolean> {
    if (isWebMode || !isPlatform('capacitor')) {
      return false
    }
    return await new Promise<boolean>((resolve, reject) => {
      MCCordovaPlugin.isPushEnabled(
        (enabled: boolean) => {
          resolve(enabled)
        },
        () => {
          console.error('MCCordovaPlugin.isPushEnabled failed')
          reject(new Error('MCCordovaPlugin.isPushEnabled failed'))
        }
      )
    })
  }

  async function enablePush(): Promise<void> {
    if (isWebMode || !isPlatform('capacitor')) {
      return
    }
    return await new Promise<void>((resolve, reject) => {
      MCCordovaPlugin.enablePush(
        () => {
          resolve()
        },
        () => {
          console.error('MCCordovaPlugin.enablePush failed')
          reject(new Error('MCCordovaPlugin.enablePush failed'))
        }
      )
    })
  }

  async function enableLogging(): Promise<void> {
    if (isWebMode || !isPlatform('capacitor')) {
      return
    }
    return await new Promise<void>((resolve, reject) => {
      MCCordovaPlugin.enableLogging(
        () => {
          resolve()
        },
        () => {
          console.error('MCCordovaPlugin.enableLogging failed')
          reject(new Error('MCCordovaPlugin.enableLogging failed'))
        }
      )
    })
  }

  async function disableLogging(): Promise<void> {
    if (isWebMode || !isPlatform('capacitor')) {
      return
    }
    return await new Promise<void>((resolve, reject) => {
      MCCordovaPlugin.disableLogging(
        () => {
          resolve()
        },
        () => {
          console.error('MCCordovaPlugin.disableLogging failed')
          reject(new Error('MCCordovaPlugin.disableLogging failed'))
        }
      )
    })
  }

  async function setContactKey(contactKey: string): Promise<void> {
    if (isWebMode || !isPlatform('capacitor')) {
      return
    }
    return await new Promise<void>((resolve, reject) => {
      MCCordovaPlugin.setContactKey(
        contactKey,
        () => {
          resolve()
        },
        () => {
          console.error('mcsdk MCCordovaPlugin.setContactKey failed')
          reject(new Error('MCCordovaPlugin.setContactKey failed'))
        }
      )
    })
  }
  /**
   * @description Setup the SalesForce Marketing Cloud plugin for mobile notifications.
   * This is for relish users who have a salesforce contact id.
   * @param salesforceContactId {string}
   */
  async function setupMobileNotificationsForRelishUser(salesforceContactId: string): Promise<void> {
    if (isWebMode || !isPlatform('capacitor')) {
      return
    }

    return await setContactKey(salesforceContactId)
  }

  /**
   * @description Setup the SalesForce Marketing Cloud plugin for mobile notifications.
   * This is for guest users who don't have a salesforce contact id.
   */
  async function setupMobileNotificationsForGuestUser(): Promise<void> {
    if (isWebMode || !isPlatform('capacitor')) {
      return
    }

    return await setContactKey(uuidv4())
  }

  return {
    enablePush,
    isPushEnabled,
    setupMobileNotificationsForRelishUser,
    setupMobileNotificationsForGuestUser,
    logSdkState,
    getDeviceId,
    getSystemToken,
    enableLogging,
    disableLogging
  }
}

/**
 * @description Provides to setup mobile notifications provided by Capacitor
 */
export function useCapacitorNotifications() {
  async function requestNotificationPermissions(): Promise<PermissionState> {
    const { display } = await LocalNotifications.checkPermissions()

    if (display === 'granted') {
      return display
    } else {
      return (await LocalNotifications.requestPermissions()).display as PermissionState
    }
  }

  async function presentNotificationSettings(): Promise<void> {
    await NativeSettings.open({
      optionAndroid: AndroidSettings.AppNotification,
      optionIOS: IOSSettings.Notifications
    })
  }

  return {
    requestNotificationPermissions,
    presentNotificationSettings
  }
}
