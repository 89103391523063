import { defineStore } from 'pinia'
import { getPromotions } from '~/composables/api'
import { STORE_ID } from '~/constants/store' // not reactive, cannot destructure
import { useRestaurantStore } from '~/store'
import type { PromotionsState as State } from '~/types/promotions'

const initialState = (): State => ({
  promotions: [],
  lastPresentedProminentPromotionId: undefined,
  lastUpdated: {
    promotions: undefined
  }
})

/**
 * @name usePromotionsStore
 * @description Store for promotions displayed across the app
 *
 */
const usePromotionsStore = defineStore(STORE_ID.PROMOTIONS, {
  state: initialState,

  getters: {},

  actions: {
    fetchPromotions: async function () {
      const restaurantStore = useRestaurantStore()

      try {
        // If restaurant is a demo restaurant, do not send restaurantId
        const restaurantId = !restaurantStore.isDemoRestaurant ? restaurantStore.getSelectedRestaurant?.id : undefined

        const res = await getPromotions(restaurantId)
        if (res.promotions !== undefined && res.promotions !== null) {
          this.promotions = res.promotions
        }

        this.lastUpdated.promotions = Date.now()
        // TODO review correct behaviour if failed to get successful response from API or promotions is undefined | null
        // currently this is ignored
      } catch (error: any) {
        // error is ignored
        console.error('Error fetching promotions', error)
      }
    },
    clearPromotions: function () {
      this.promotions = []
    }
  },
  persist: {
    paths: ['lastPresentedProminentPromotionId']
  }
})

export default usePromotionsStore
export { initialState }
