import type { ChipSelectorSingleItem } from '~/components/common/GChipSelector/types'
import type { SingleOrderTypeItem } from '~/types/orderType'
import { OnlineOrderType, OrderTimeOption } from '~/types/restaurant'

export const cateringTag = 'catering'

/**
 * @description Order time options for the Order Configuration
 */
export const orderTimeItems: ChipSelectorSingleItem[] = [
  {
    id: OrderTimeOption.NOW,
    title: 'Order Now'
  },
  {
    id: OrderTimeOption.SCHEDULED,
    title: 'Schedule for later'
  }
]

/**
 * @description Key for multi item pickup.
 * This is used to identify the multi item pickup in the OrderTypeButtonGroup
 */
export const PICKUP_MULTI_ITEM_KEY = 999

/**
 * @description Order type options for the OrderTypeButtonGroup
 */
export const orderTypeOptions: Record<OnlineOrderType, SingleOrderTypeItem> = {
  [OnlineOrderType.Delivery]: {
    type: 'single',
    id: OnlineOrderType.Delivery,
    label: 'Delivery',
    icon: 'grilld-delivery',
    value: OnlineOrderType.Delivery
  },

  [OnlineOrderType['Order at Table']]: {
    type: 'single',
    id: OnlineOrderType['Order at Table'],
    label: 'Order at Table',
    icon: 'grilld-order-at-table',
    value: OnlineOrderType['Order at Table']
  },
  [OnlineOrderType.Catering]: {
    type: 'single',
    id: OnlineOrderType.Catering,
    label: 'Catering',
    icon: 'grilld-catering',
    value: OnlineOrderType.Catering
  },
  [OnlineOrderType['Drive Thru']]: {
    type: 'single',
    id: OnlineOrderType['Drive Thru'],
    label: 'Drive Thru',
    icon: 'grilld-drive-thru',
    value: OnlineOrderType['Drive Thru']
  },
  [OnlineOrderType['Park & Collect']]: {
    type: 'single',
    id: OnlineOrderType['Park & Collect'],
    label: 'Park & Collect',
    description: 'Park outside and we’ll bring your order to you!',
    icon: 'grilld-park-and-collect',
    value: OnlineOrderType['Park & Collect']
  },
  [OnlineOrderType.Pickup]: {
    type: 'single',
    id: OnlineOrderType.Pickup,
    label: 'Pickup',
    icon: 'grilld-pickup',
    value: OnlineOrderType.Pickup
  }
}
