import { defineStore } from 'pinia'
import { STORE_ID } from '~/constants/store'
import type { UserDetails, UserState as State } from '~/types/user'
import useAuthStore from './auth'

const initialState = (): State => ({
  details: undefined,
  hasOnboarded: false,
  ordersCompletedSinceEligibleToRate: 0,
  selectedAppRatingLaterCount: 0,
  dateLastSubmittedAppRating: undefined
})

/**
 * @description Store for user preferences - saved in local storage.
 */
const useUserStore = defineStore(STORE_ID.USER, {
  state: initialState,

  getters: {
    getUserDetails(): UserDetails {
      const authStore = useAuthStore()

      if (!authStore.isAuthenticated) {
        return {
          givenName: this.details?.givenName || '',
          familyName: this.details?.familyName || '',
          emailAddress: this.details?.emailAddress || '',
          mobileNumber: this.details?.mobileNumber || '',
          company: this.details?.company || '',
          position: this.details?.position || ''
        }
      }

      return {
        givenName: authStore.authUser?.givenName || '',
        familyName: authStore.authUser?.familyName || '',
        emailAddress: authStore.authUser?.emailAddress || '',
        mobileNumber: authStore.authUser?.mobileNumber || ''
      }
    }
  },

  actions: {},

  persist: true
})

export default useUserStore
export { initialState }
