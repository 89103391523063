import { Geolocation, type PermissionStatus as LocationPermissions } from '@capacitor/geolocation'
import { PushNotificationsPermissions } from '~/capacitor-plugins/push-notifications-permissions'
import { usePermanentStore } from '~/store'

export enum LocationPermissionStatus {
  SERVICES_DISABLED,
  NOT_REQUESTED,
  DENIED,
  COARSE_GRANTED_ONLY,
  PRECISE_GRANTED_ONLY,
  ALL_GRANTED
}

export enum NotificationsPermissionStatus {
  NOT_REQUESTED,
  DENIED,
  GRANTED
}

export async function checkLocationPermissionStatus(): Promise<LocationPermissionStatus> {
  let permissions: LocationPermissions
  try {
    permissions = await Geolocation.checkPermissions()
  } catch {
    return LocationPermissionStatus.SERVICES_DISABLED
  }

  if (
    permissions.coarseLocation === 'prompt' ||
    permissions.coarseLocation === 'prompt-with-rationale' ||
    permissions.location === 'prompt' ||
    permissions.location === 'prompt-with-rationale'
  ) {
    return LocationPermissionStatus.NOT_REQUESTED
  }

  if (permissions.coarseLocation === 'granted' && permissions.location === 'granted') {
    return LocationPermissionStatus.ALL_GRANTED
  }

  if (permissions.coarseLocation === 'granted') {
    return LocationPermissionStatus.COARSE_GRANTED_ONLY
  }

  if (permissions.location === 'granted') {
    return LocationPermissionStatus.PRECISE_GRANTED_ONLY
  }

  return LocationPermissionStatus.DENIED
}

export async function checkNotificationsPermissionStatus(): Promise<NotificationsPermissionStatus> {
  const permission = await PushNotificationsPermissions.checkPermissions()

  if (permission.receive === 'prompt') {
    return NotificationsPermissionStatus.NOT_REQUESTED
  }

  if (permission.receive === 'granted') {
    return NotificationsPermissionStatus.GRANTED
  }

  return NotificationsPermissionStatus.DENIED
}

export async function shouldPromptLocationPermission(): Promise<boolean> {
  const { isMobileMode } = useAppMode()
  const permanentStore = usePermanentStore()

  const permission = await checkLocationPermissionStatus()
  const permissionNotDefined =
    permission === LocationPermissionStatus.NOT_REQUESTED || permission === LocationPermissionStatus.SERVICES_DISABLED

  return isMobileMode && !permanentStore.dontRequestLocationPermissionAgain && permissionNotDefined
}

export async function shouldPromptNotificationsPermission(): Promise<boolean> {
  const { isMobileMode } = useAppMode()
  return (
    isMobileMode &&
    isPlatform('capacitor') &&
    (await checkNotificationsPermissionStatus()) === NotificationsPermissionStatus.NOT_REQUESTED
  )
}
