import sanitizeHtml from 'sanitize-html'
import type { RouteParamsRaw } from 'vue-router'

/**
 * @description Split a camel case string into a space separated string
 * @param input The camel case string to split
 * @returns The space separated string
 */
export function splitCamelCase(input: string): string {
  return input.replace(/([A-Z])/g, ' $1')
}

/**
 * @description Capitalise the first letter of a string
 * @param input The string to capitalise
 * @returns The capitalised string
 */
export function capitalise(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1)
}

/**
 * @description Parse a route from the parent site.
 * Required because slashes are encoded as %2F in the URL.
 * @param route {string} The route to parse
 * @returns The parsed route
 * @example parseEmbeddedRoute('/home') // => '/home'
 */
export function parseEmbeddedRoute(route: string): string {
  return route.replaceAll(/%2F/g, '/')
}

/**
 * @description Parse a string of key value pairs into an object
 * @param input {string} The string to parse
 * @returns The parsed object
 * @example parseStringToParams('?key1=value1/key2=value2') // => { key1: 'value1', key2: 'value2' }
 */
export function parseStringToQueryParams(input: string): RouteParamsRaw {
  {
    const keyValuePairs = input.slice(1).split('/')

    return keyValuePairs.reduce((result: Record<string, string>, pair: string) => {
      const [key, value] = pair.split('=')
      result[key] = value
      return result
    }, {})
  }
}

/**
 *
 * @param n number to convert to ordinal
 * @returns Returns the long ordinal string for the number up to 9 then falls back to short ordinal string
 */
export function integerToLongOrdinal(n: number): string {
  const nInt = Math.floor(n)
  switch (nInt) {
    case 1:
      return 'first'
    case 2:
      return 'second'
    case 3:
      return 'third'
    case 4:
      return 'fourth'
    case 5:
      return 'fifth'
    case 6:
      return 'sixth'
    case 7:
      return 'seventh'
    case 8:
      return 'eighth'
    case 9:
      return 'ninth'
    default:
      return integerToOrdinal(n)
  }
}
/**
 *
 * @param n number to convert to ordinal
 * @returns returns the short ordinal string for the number
 */
export function integerToOrdinal(n: number): string {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

/**
 * @description Sanitize an html input string for safe display in ionic components such as ion-alert
 * @param input The string to sanitize
 * @returns The sanitized string
 */
export function sanitizeString(input: string): string {
  // replace all \n with <br> for backwards compatibility
  input = input.replace(/\n/g, '<br>')
  // sanitize the string
  return sanitizeHtml(input)
}

/**
 * @description Replace \n with <br />
 * @param text {string | { [x: string]: string | undefined }} The string or Short Text ("Symbol") from Contentful
 * @returns {string} The string with break tags
 */
export function withLinebreaks(text: string | { [x: string]: string | undefined }): string {
  return (text as string).replaceAll('\\n', '<br />')
}
