import { App, type URLOpenListenerEvent } from '@capacitor/app'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import type { RouteRecordRaw } from 'vue-router'
import middlewareAuth from '~/router/middleware/auth'
import middlewareMobileRoot from '~/router/middleware/mobileRoot'
import middlewareOnboard from '~/router/middleware/onboard'
import { middlewareOrderAtTableMobile } from '~/router/middleware/orderAtTable'
import type { ViewSource } from '~/types/route'

/**
 * @description Mobile app routes.
 */
const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/',
    redirect() {
      return middlewareMobileRoot()
    }
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('~/views/home/ViewHome.vue'),
    meta: {
      requiresRestaurant: true
    },
    props: (route) => ({ productId: route.query?.productId, categoryId: route.query?.categoryId })
  },
  {
    name: 'config',
    path: '/config',
    component: () => import('~/views/order/ViewOrderConfig.vue'),
    meta: {
      onboardingFlow: true
    }
  },
  {
    name: 'order-at-table',
    path: '/order-at-table/:restaurantId',
    component: () => import('~/views/order/ViewOrderAtTable.vue'),
    meta: {
      requiresRestaurant: true
    }
  },
  {
    name: 'restaurants',
    path: '/restaurants',
    component: () => import('~/views/restaurants/ViewRestaurants.vue')
  },
  {
    name: 'restaurant-detail',
    path: '/restaurants/:id',
    component: () => import('~/views/restaurants/ViewRestaurantItem.vue')
  },
  {
    name: 'menu',
    path: '/menu',
    component: () => import('~/views/menu/ViewMenu.vue')
  },
  {
    name: 'menu-product',
    path: '/menu/product/:id',
    component: () => import('~/views/menu/product/ViewProductItem.vue')
  },
  {
    name: 'menu-product-customisation',
    path: '/menu/product/:id/customise',
    component: () => import('~/views/menu/product/ViewProductItemCustomise.vue')
  },
  {
    name: 'menu-product-nutrition',
    path: '/menu/product/:id/nutritionalinfo',
    component: () => import('~/views/menu/product/ViewProductItemNutrition.vue')
  },
  {
    name: 'menu-product-list',
    path: '/menu/product/:id/productchoices',
    component: () => import('~/views/menu/product/ViewProductList.vue')
  },
  {
    name: 'menu-product-custom',
    path: '/menu/customproduct/:id',
    component: () => import('~/views/menu/product/ViewCustomProductItem.vue')
  },
  {
    name: 'menu-product-custom-customisation',
    path: '/menu/customproduct/:id/customise',
    component: () => import('~/views/menu/product/ViewCustomProductItemCustomise.vue')
  },
  {
    name: 'menu-product-custom-nutrition',
    path: '/menu/customproduct/:id/nutritionalinfo',
    component: () => import('~/views/menu/product/ViewCustomProductItemNutrition.vue')
  },
  {
    name: 'checkout',
    path: '/checkout',
    component: () => import('~/views/checkout/ViewCheckout.vue'),
    props: (route) => ({ replaceBackPath: route.query.replaceBackPath })
  },
  {
    name: 'relish',
    path: '/relish',
    component: () => import('~/views/relish/ViewRelish.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'relish-coupon-item',
    path: '/relish/coupons/:id',
    component: () => import('~/views/relish/coupons/ViewCouponItem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'profile',
    path: '/profile',
    component: () => import('~/views/profile/ViewSettings.vue')
  },
  {
    name: 'mad-bunday',
    path: '/profile/mad-bunday',
    component: () => import('~/views/profile/mad-bunday/ViewMadBunday.vue'),
    props: (route) => ({ source: route.query?.source, promo: route.query?.promo }),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'edit-profile',
    path: '/profile/edit',
    component: () => import('~/views/profile/edit/ViewEditProfile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'edit-password',
    path: '/profile/edit/password',
    component: () => import('~/views/profile/edit/ViewEditPassword.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'edit-payment-methods',
    path: '/profile/edit/payment-methods',
    component: () => import('~/views/profile/edit/ViewEditPaymentMethods.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'onboard',
    path: '/onboard',
    component: () => import('~/views/onboard/ViewOnboard.vue'),
    meta: {
      onboardingFlow: true
    }
  },
  {
    name: 'permissions-location',
    path: '/permissions/location',
    component: () => import('~/views/permissions/ViewPermissionsLocation.vue'),
    meta: {
      onboardingFlow: true
    }
  },
  {
    name: 'permissions-notifications',
    path: '/permissions/notifications',
    meta: {
      onboardingFlow: true
    },
    component: () => import('~/views/permissions/ViewPermissionsNotifications.vue')
  },
  {
    name: 'history',
    path: '/history',
    component: () => import('~/views/order/ViewOrderHistory.vue')
  },
  {
    name: 'history-item',
    path: '/history/:id',
    component: () => import('~/views/order/ViewOrderHistoryItem.vue')
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    redirect: '/home'
  }
]

const mobileRouter = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes
})

mobileRouter.beforeEach(async (to) => {
  // Route Guard: if not authenticated, redirect to /home.
  if (to.meta.requiresAuth) {
    const redirect = middlewareAuth()

    if (redirect) {
      return redirect
    }
  }

  // Route Guard: if not onboarded and not in onboarding flow, redirect to /onboard.
  // Route Guard: if no restaurant set, redirect to /config.
  if (!to.meta.onboardingFlow || to.meta.requiresRestaurant) {
    const redirect = middlewareOnboard({
      source: to.query.source as ViewSource | undefined,
      restaurantId: to.params.restaurantId as string | undefined
    })

    if (redirect) {
      return redirect
    }
  }
})

mobileRouter.afterEach((to) => {
  window.fbq('track', 'ViewContent', { content_name: to.path })
  window.ttq.page()
})

initUrlOpenListener()

function initUrlOpenListener() {
  // Configure App for native

  if (isPlatform('capacitor')) {
    App.addListener('appUrlOpen', async function (event: URLOpenListenerEvent): Promise<void> {
      const redirect = await middlewareOrderAtTableMobile(event.url)

      await mobileRouter.replace(redirect)
    })
  }
}

export default mobileRouter
