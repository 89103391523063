import { defineStore } from 'pinia'
import { ScreenBrightness } from '@capacitor-community/screen-brightness'
import type { BrightnessState as State } from '~/types/brightness'
import { STORE_ID } from '~/constants/store'

function calculateResetValue(savedValue: number | null): number | null {
  if (isPlatform('android')) {
    // to reset brightness on Android we always use -1
    return -1
  }

  // platform will be iOS now
  if (savedValue === null) {
    // we don't have a value to reset it back to, so there's nothing to do
    return null
  }

  return savedValue
}

const initialState = (): State => ({
  savedBrightness: null
})

/**
 * @name useBrightnessStore
 * @description Store for manual brightness control state on mobile
 *
 */
const useBrightnessStore = defineStore(STORE_ID.BRIGHTNESS, {
  state: initialState,

  getters: {},

  actions: {
    async setBrightnessMax(): Promise<void> {
      const { isMobileMode } = useAppMode()

      // if we're not on mobile, there's nothing to do
      if (!isMobileMode || !isPlatform('capacitor')) {
        return
      }

      if (this.savedBrightness === null) {
        this.savedBrightness = (await ScreenBrightness.getBrightness()).brightness
      }

      await ScreenBrightness.setBrightness({ brightness: 1 })
    },
    async unsetBrightnessMax(): Promise<void> {
      const { isMobileMode } = useAppMode()

      // if we're not on mobile, there's nothing to do
      if (!isMobileMode || !isPlatform('capacitor')) {
        return
      }

      const resetValue = calculateResetValue(this.savedBrightness)

      if (resetValue === null) {
        return
      }

      await ScreenBrightness.setBrightness({ brightness: resetValue })
      this.savedBrightness = null
    },
    async toggleBrightnessMax(): Promise<void> {
      if (this.savedBrightness === null) {
        await this.setBrightnessMax()
      } else {
        await this.unsetBrightnessMax()
      }
    }
  }
})

export default useBrightnessStore
export { initialState }
