import { defineStore } from 'pinia'
import { STORE_ID } from '~/constants/store'
import useOrderStore from '~/store/order'
import { useProductCommon } from '~/store/productStoreCommon'
import useRestaurantStore from '~/store/restaurant'
import type { CartError, CartItem, CartItemIngredient, MenuItem } from '~/types/api/data-contracts'
import type { MenuItemChoice, MenuItemExpanded } from '~/types/menu'
import type { CartItemSource, ProductState as State } from '~/types/product'
import { OnlineOrderType } from '~/types/restaurant'
import type { ViewProductSource } from '~/types/route'

const initialState = (): State => ({
  id: undefined,
  product: undefined,
  quantity: 1,
  selectedSingleChoiceItems: undefined,
  customisedItem: undefined,
  activeCustomChoice: undefined,
  activeCustomChoiceIndex: undefined,
  customisations: {
    additions: [],
    subtractions: [],
    upsells: []
  }
})

/**
 * @name useProductStore
 * @description Store for viewing a menu item at /menu/product/:id
 *
 */
const useProductStore = defineStore(STORE_ID.PRODUCT, {
  state: initialState,

  getters: {
    getProductId(): number {
      return useProductCommon().getProductId(this)
    },
    /**
     * @description Gets all single choices available for the product
     */
    getAllSingleChoices(): MenuItemChoice[] {
      return useProductCommon().getAllSingleChoices(this)
    },
    /**
     * @description Returns true if the product has any single choices available
     */
    hasChoices(): boolean {
      return useProductCommon().hasChoices(this)
    },
    /**
     * @description Gets all multi choices available for the product
     */
    getAllMultiChoices(): MenuItemChoice[] {
      return useProductCommon().getAllMultiChoices(this)
    },
    /**
     * @description Gets the selected choice item for a given choice title
     * @param choiceTitle {MenuItemChoice["title"]} - The title of the choice
     * @returns {MenuItem | undefined} - The selected choice item or undefined if not found
     */
    getSelectedChoiceItem: (state) => {
      return (choiceTitle: MenuItemChoice['title']): MenuItem | undefined => {
        return useProductCommon().getSelectedChoiceItem(state, choiceTitle)
      }
    },
    /**
     * @description Gets the selected choice item ID for a given choice title
     * @param choiceTitle {MenuItemChoice["title"]} - The title of the choice
     * @returns {MenuItem["id"] | undefined} - The selected choice item ID or undefined if not found
     */
    getSelectedChoiceItemId: (state) => {
      return (choiceTitle: MenuItemChoice['title']): MenuItem['id'] | undefined => {
        return useProductCommon().getSelectedChoiceItemId(state, choiceTitle)
      }
    },
    getSelectedCustomChoices: (state) => {
      return (choiceTitle: MenuItemChoice['title']): Array<CartItem | undefined> | undefined => {
        return useProductCommon().getSelectedCustomChoices(state, choiceTitle)
      }
    },
    /**
     * @description Gets the selected multi-choice item for a given choice title
     * @param choiceTitle {MenuItemChoice["title"]} - The title of the choice
     * @returns {MenuItemChoice | undefined} - The selected choice item or undefined if not found
     */
    getSelectedMultiChoiceItem: (state) => {
      return (choiceTitle: MenuItemChoice['title']): MenuItemChoice | undefined => {
        return useProductCommon().getSelectedMultiChoiceItem(state, choiceTitle)
      }
    },
    /**
     * @description Returns true if the product has any multi-choices available
     */
    hasMultiChoices(): boolean {
      return useProductCommon().hasMultiChoices(this)
    },
    isMultiChoiceError: (state) => {
      return (choiceTitle: MenuItemChoice['title']): boolean | undefined => {
        return useProductCommon().isMultiChoiceError(state, choiceTitle)
      }
    },
    /**
     * @description The amount of additions available for the product
     */
    getCustomisationsAmount(): number {
      return useProductCommon().getCustomisationsAmount(this)
    },
    getPriceOfUpsellsInCents(): number {
      if (!this.customisations.upsells || this.customisations.upsells.length === 0) {
        return 0
      }

      return this.customisations.upsells.reduce((total, upsell) => total + upsell.priceInCents, 0)
    },
    /**
     * @description Returns the price of the product in cents including customisations
     */
    getPriceInCents(): number {
      return useProductCommon().getPriceInCents(this)
    },
    getTotalPriceInCents(): number {
      const price = this.getPriceInCents
      const upsellPrice = this.getPriceOfUpsellsInCents
      return price + upsellPrice
    }
  },

  actions: {
    /**
     * @description Fetches the product from the API and sets it in the store
     * @param id {number} The product ID
     * @returns {Promise<MenuItemExpanded>} The product
     */
    async fetchProduct(id: number): Promise<MenuItemExpanded> {
      return await useProductCommon().fetchProduct(this, id)
    },
    /**
     * @description Updates the selected choice item for a given choice title
     * @param choiceTitle The title of the choice
     * @param item The selected choice item
     */
    updateSelectedChoiceItem(choiceTitle: MenuItemChoice['title'], item: MenuItem): void {
      useProductCommon().updateSelectedChoiceItem(this, choiceTitle, item)
    },
    /**
     * @description Updates the selected multi choice item for a given choice title
     * @param choiceTitle The title of the choice
     * @param choices
     */
    updateSelectedMultiChoice(choiceTitle: MenuItemChoice['title'], choices: MenuItemChoice): void {
      useProductCommon().updateSelectedMultiChoice(this, choiceTitle, choices)
    },
    updateSelectedCustomChoice(
      choiceTitle: MenuItemChoice['title'],
      index: number,
      choice: CartItem | undefined
    ): void {
      useProductCommon().updateSelectedCustomChoice(this, choiceTitle, index, choice)
    },
    updateAddition(newAddition: CartItemIngredient): void {
      useProductCommon().updateAddition(this, newAddition)
    },
    updateSubtraction(subtraction: CartItemIngredient): void {
      useProductCommon().updateSubtraction(this, subtraction)
    },
    /**
     * @description Initialise the selected choice items to the first item in each choice
     * e.g. { 'Choice 1': {...menuItem}, 'Choice 2': {...menuItem} }
     * @param choices The choices to reset
     * @param multiChoices - Already customised selected choice items
     */
    initSelectedChoiceItems(choices: MenuItemChoice[], multiChoices?: CartItemIngredient[][]): void {
      useProductCommon().initSelectedChoiceItems(this, choices, multiChoices)
    },
    /**
     * @description Initialises the product and upsell items as default
     * @param id {number} The product ID
     */
    async initProduct(id: number): Promise<void> {
      await useProductCommon().initProduct(this, id)
    },
    /**
     * @description Initialises the product and upsell items with customised data
     * @param cartItem {CartItem} The cart item to initialise the product with
     */
    async initCustomisedProduct(cartItem: CartItem): Promise<void> {
      await useProductCommon().initCustomisedProduct(this, cartItem)
    },
    /**
     * @description Adds the product and all upsell items to the cart
     */
    async addToCart(source: ViewProductSource): Promise<CartError[] | undefined | null> {
      const orderStore = useOrderStore()
      const product = this.prepareProductForCart()

      // Add product to cart
      const errors = await orderStore.addProductToOrder(product, source)
      if (errors && errors.length > 0) {
        return errors
      }

      await this.addUpsellsToCart()
    },
    /**
     * @description Updates the product in the cart. Used on product page from the checkout
     * @param cartItemIndex
     * @param source
     */
    async updateProductInCart(cartItemIndex: number, source: CartItemSource): Promise<CartError[] | undefined | null> {
      const orderStore = useOrderStore()
      const updatedItem = this.prepareProductForCart()

      try {
        if (!orderStore.cart || !orderStore.order?.items) {
          throw Error('No cart or no items in cart')
        }

        await this.addUpsellsToCart()

        // Find index in orderStore.order.items, replace item with updatedItem
        // and update the order items
        const newCartItems = orderStore.order.items.map((item, index) => {
          return cartItemIndex === index ? updatedItem : item
        })

        const errors = await orderStore.updateItemsInCart(newCartItems)

        if (errors && errors.length > 0) {
          return errors
        }

        const restaurantStore = useRestaurantStore()

        gaEventUpdateItemInCart({
          currency: 'AUD',
          value: updatedItem.priceInCents ? updatedItem.priceInCents / 100 : 0,
          orderType: OnlineOrderType[orderStore.cart.orderType],
          context: source,
          restaurantName: restaurantStore.restaurant?.name || '',
          items: [
            {
              item_id: updatedItem.id.toString() || '',
              item_name: updatedItem?.title || '',
              price: updatedItem.priceInCents ? updatedItem.priceInCents / 100 : 0,
              quantity: updatedItem.quantity
            }
          ]
        })

        emitCartItemCount()
      } catch (error: any) {
        console.error('Error updating updatedItem in cart', error)
        throw error
      }
    },
    /**
     * @description Adds all upsell items to the cart, if any
     */
    async addUpsellsToCart(): Promise<CartError[] | undefined | null> {
      const orderStore = useOrderStore()

      if (this.customisations.upsells.length > 0) {
        // Add all upsell items to cart
        for (const item of this.customisations.upsells) {
          const upsellCartItem: CartItem = {
            id: item.id,
            title: item.title,
            quantity: 1,
            priceInCents: item.priceInCents
          }

          try {
            const crossSellErrors = await orderStore.addProductToOrder(upsellCartItem, 'cross-sell')

            if (crossSellErrors && crossSellErrors.length > 0) {
              console.warn(`Error adding cross-sell item ${item.title} to cart`, crossSellErrors)
              return crossSellErrors
            }
          } catch (error: any) {
            console.warn(error)
            throw error
          }
        }
      }
    },
    /**
     * @description Prepares the product for adding to the cart
     * @returns {CartItem} The product in the format required for the cart
     */
    prepareProductForCart(): CartItem {
      return useProductCommon().prepareProductForCart(this)
    },
    /**
     * @description Validates the selected choice items are within the constraints
     */
    validateMultiSelectChoices(): boolean {
      return useProductCommon().validateMultiSelectChoices(this)
    },
    /**
     * @description Resets some of the store properties. Used when entering page with customised data
     */
    clearStore(): void {
      this.id = undefined
      this.product = undefined
      this.quantity = 1
    }
  }
})

export default useProductStore
export { initialState }
