import { App, type AppInfo } from '@capacitor/app'
import type { DeviceId, DeviceInfo } from '@capacitor/device'
import { Device } from '@capacitor/device'
import { jwtDecode } from 'jwt-decode'
import type { $Fetch, FetchContext } from 'ofetch'
import { ofetch } from 'ofetch'

import { useAuthStore } from '~/store'

import type {
  AuthenticationResponse,
  Cart,
  CartCoupon,
  CartCustomerDetails,
  CartDeliveryDetails,
  CartItem,
  CartResponse,
  ChangePasswordRequest,
  CheckoutRequest,
  CheckoutResponse,
  Coupon,
  EightDonateResponse,
  LoginRequest,
  LookupRequest,
  MenuItemExpanded,
  MenuResponse,
  Order,
  OrderAction,
  PaymentMethodsResponse,
  PromotionsResponse,
  RefreshRequest,
  RegisterRequest,
  Restaurant,
  SavedPaymentMethod,
  TakeOrDonateRequest,
  UpdateUserRequest,
  UserResponse,
  LoginResponse,
  MadBundayResponse
} from '~/types/api/data-contracts'
import type { Coords } from '~/types/location'
import type { PastOrder } from '~/types/orderHistory'
import { OnlineOrderType } from '~/types/restaurant'
import type { CreateCartRequest } from '~/types/order'

const apiFetch = createFetch()
let deviceInfo: DeviceInfo | null = null

async function getDeviceInfo(): Promise<DeviceInfo> {
  if (deviceInfo === null) {
    deviceInfo = await Device.getInfo()
  }
  return deviceInfo
}

let deviceId: DeviceId | null = null

async function getDeviceId(): Promise<DeviceId> {
  if (deviceId === null) {
    deviceId = await Device.getId()
  }
  return deviceId
}

let appInfo: AppInfo | null = null

async function getAppInfo(): Promise<AppInfo> {
  if (appInfo === null) {
    appInfo = await App.getInfo()
  }
  return appInfo
}

function createFetch(): $Fetch {
  return ofetch.create({
    // Retry on additional status code 401 to allow token refresh
    retryStatusCodes: [401, 408, 409, 425, 429, 500, 502, 503, 504],
    retry: 2,
    retryDelay: 500,
    async onRequest(context) {
      const authStore = useAuthStore()
      // If is authenticated, refresh token (if necessary) and add the token to the request
      if (authStore.authSession) {
        // Preemptively refresh the token if it is about to expire
        if (checkRefreshToken(context)) {
          await shouldRefreshToken()
        }

        context.options.headers = {
          ...context.options.headers,
          Authorization: `Bearer ${authStore.authSession.idToken}`
        }
      }
      const deviceId = await getDeviceId()
      if (isPlatform('capacitor')) {
        const deviceInfo = await getDeviceInfo()
        const appInfo = await getAppInfo()
        context.options.headers = {
          ...context.options.headers,
          'X-Grilld-App-Platform': deviceInfo.platform,
          'X-Grilld-App-Platform-Version': deviceInfo.osVersion,
          'X-Grilld-App-Version': appInfo.version,
          'X-Grilld-Device-Id': deviceId.identifier
        }
      } else {
        context.options.headers = {
          ...context.options.headers,
          ...(import.meta.env.VITE_APP_VERSION ? { 'X-Grilld-App-Version': import.meta.env.VITE_APP_VERSION } : {}),
          'X-Grilld-App-Platform': 'web',
          'X-Grilld-Device-Id': deviceId.identifier
        }
      }
    },
    async onResponseError(context) {
      // Refresh token if a 401 response is returned. GET requests will be retried automatically
      const authStore = useAuthStore()

      // If we get a 400 error from the auth/refresh endpoint, sign the user out

      if (context.response?.status === 400 && context.request.toString().endsWith('/auth/refresh')) {
        console.warn('Received 400 from /auth/refresh, signing out')
        authStore.signOut()
        return
      }

      if (authStore.isAuthenticated && context.response?.status === 401) {
        const authResponse = await refreshToken(authStore.getAuthSession!.refreshToken!, authStore.updateUserAndSession)
        // Replace the old token with the new one
        context.options.headers = {
          ...context.options.headers,
          Authorization: `Bearer ${authResponse.session?.idToken}`
        }

        // Do not retry the request here, rely on the ofetch retry mechanism
        // Note, ofetch will not retry on POST, PUT, PATCH and DELETE
      }
    }
  })

  /**
   * @description Checks if the token needs to be refreshed
   * @param context {FetchContext} - The fetch context from ofetch
   * @returns {boolean} - True if the token needs to be refreshed
   */
  function checkRefreshToken(context: FetchContext): boolean {
    if (context.options.method === undefined) {
      return false
    }
    // return false if the request is in the suppress list
    const anonymous = ['/auth/refresh', '/auth/login', '/auth/register', '/auth/lookup', '/feedback']
    const request = context.request
    if (typeof request === 'string') {
      return !anonymous.includes(request)
    } else if (typeof request === 'object') {
      return !anonymous.includes(request.url)
    }
    return true
  }
}

/**
 * @description Preemptively refresh the token if it is about to expire
 * @returns {Promise<void>}
 */
export async function shouldRefreshToken(): Promise<void> {
  const authStore = useAuthStore()
  const token = authStore.authSession?.idToken

  if (token) {
    const decoded = jwtDecode(token)
    // refresh token if it is about to expire
    const timeToRefresh = Math.floor(Date.now() / 1000) + 60 * 5 // 5 minutes before expiry

    if (decoded?.exp && decoded.exp < timeToRefresh) {
      await refreshToken(authStore.getAuthSession!.refreshToken!, authStore.updateUserAndSession)
    }
  }
}

async function refreshToken(
  refreshToken: string,
  updateUserAndSession: (authSession: LoginResponse, authUser: UserResponse) => void
) {
  const authResponse = await refreshAuthTokens({ refreshToken })

  if (!authResponse.session || !authResponse.user) {
    throw new Error('Could not refresh auth token')
  }

  updateUserAndSession(authResponse.session, authResponse.user)
  return authResponse
}

interface DefaultOptions {
  baseURL: string
  headers: Record<string, string>
}

/**
 * @description Returns base api + version with optional endpoint
 * @param endpoint {string}
 * @returns {string}
 */
export function getApi(endpoint?: string): string {
  const config = useRuntimeConfig()

  if (endpoint && !endpoint.startsWith('/')) {
    endpoint = `/${endpoint}`
  }
  return `${config.public.baseApiUrl}/${config.public.apiVersion}${endpoint}`
}

export function getApiTest(): Promise<string> {
  const config = useRuntimeConfig()

  const params = {
    baseURL: `${config.public.baseApiUrl}/`,
    headers: {}
  }
  return apiFetch(`/`, params)
}

function defaultOptions(): DefaultOptions {
  const config = useRuntimeConfig()

  return {
    baseURL: `${config.public.baseApiUrl}/${config.public.apiVersion}`,
    headers: {}
  }
}

export function fetchMenuItemById({
  restaurantId,
  orderType,
  productId
}: {
  restaurantId: number
  orderType: number
  productId: number
}): Promise<MenuItemExpanded> {
  const options = {
    method: 'GET'
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/restaurants/${restaurantId}/menu/${productId}?orderType=${orderType}`, params)
}

/**
 * @description Client-side fetch cart by cartId
 * @param cartId {string}
 */
export function getCart(cartId: string): Promise<CartResponse> {
  const params = defaultOptions()
  return apiFetch(`/carts/${cartId}`, params)
}

/**
 * @description Gets customers order via orderId
 * @param orderId {string}
 */
export function getOrder(orderId: string): Promise<Order> {
  const params = defaultOptions()
  return apiFetch(`/Orders/${orderId}`, params)
}

/**
 * @description Creates a cart
 * @param restaurantId {number}
 * @param orderType {number}
 * @param orderTime {string | undefined}
 * @param deliveryDetails {CartDeliveryDetails}
 * @param vehicleDetails {CartVehicleDetails}
 * @param tableDetails {CartTableDetails}
 * @param cateringDetails {CartCateringDetails}
 */
export function createCart({
  restaurantId,
  orderType,
  orderTime,
  deliveryDetails,
  vehicleDetails,
  tableDetails,
  cateringDetails
}: CreateCartRequest): Promise<CartResponse> {
  let body: CreateCartRequest = {
    restaurantId,
    orderType,
    orderTime
  }

  switch (orderType) {
    case OnlineOrderType.Catering:
      if (cateringDetails && cateringDetails.date && cateringDetails.time) {
        body = {
          ...body,
          cateringDetails
        }
      }
      break
    case OnlineOrderType.Delivery:
      if (
        deliveryDetails &&
        deliveryDetails.addressLine1 &&
        deliveryDetails.suburb &&
        deliveryDetails.state &&
        deliveryDetails.postcode
      ) {
        body = {
          ...body,
          deliveryDetails
        }
      }
      break
    case OnlineOrderType['Order at Table']:
      if (tableDetails && tableDetails.tableNumber) {
        body = {
          ...body,
          tableDetails
        }
      }
      break
    case OnlineOrderType['Park & Collect']:
      if (
        vehicleDetails &&
        vehicleDetails.registrationNumber &&
        vehicleDetails.colour &&
        vehicleDetails.make &&
        vehicleDetails.model
      ) {
        body = {
          ...body,
          vehicleDetails
        }
      }
      break
    case OnlineOrderType['Drive Thru']:
      if (vehicleDetails && vehicleDetails.registrationNumber) {
        body = {
          ...body,
          vehicleDetails
        }
      }
      break
  }

  const options = {
    method: 'POST',
    body
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch('/carts', params)
}

/**
 * @description Client-side request to delete a cart
 * @param cartId {number}
 * @returns {Promise<Cart>}
 */
export function deleteCart(cartId: string): Promise<Cart> {
  const options = {
    method: 'DELETE'
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/carts/${cartId}`, params)
}

/**
 * @description Client-side request to update cart items; before using, first consider if orderStore.updateOrder() is
 * more appropriate which will also sync the store with the updated cart
 * @param cartId {string}
 * @param cart {Cart[]}
 * @returns {Promise<CartResponse>}
 */
export function updateCartItems(cartId: string, cart: CartItem[]): Promise<CartResponse> {
  const options = {
    method: 'PUT',
    body: cart
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/carts/${cartId}/items`, params)
}

/**
 * @description Client-side request to update coupons applied to a cart.
 * @param cartId {string} - Cart to update
 * @param cartCoupon {CartCoupon} - Coupon to apply to cart
 * @return {Promise<CartResponse>}
 */
export function updateCartCoupons(cartId: string, cartCoupon?: CartCoupon): Promise<CartResponse> {
  const options = {
    method: 'PUT',
    body: cartCoupon
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)

  return apiFetch(`/carts/${cartId}/coupons`, params)
}

/**
 * @description Client-side request to remove coupons applied to a cart.
 * @param cartId {string} - Cart to remove coupons from
 * @return {Promise<CartResponse>}
 */
export function removeCartCoupons(cartId: string): Promise<CartResponse> {
  const options = {
    method: 'DELETE'
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/carts/${cartId}/coupons`, params)
}

/**
 * @description Client-side request to update a cart
 * @param cartId {string}
 * @param cart {Cart[]}
 * @returns {Promise<CartResponse>}
 */
export function updateCart(cartId: string, cart: Cart): Promise<CartResponse> {
  const options = {
    method: 'PUT',
    body: cart
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/carts/${cartId}`, params)
}

/**
 * @description Fetch list of supported payment methods
 * @param cartId {string}
 * @returns {Promise<PaymentMethodsResponse>}
 */
export function preparePaymentMethods(cartId: string): Promise<PaymentMethodsResponse> {
  const options = {
    method: 'POST'
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/carts/${cartId}/prepare`, params)
}

/**
 * @description Checkout with payment method
 * @param cartId {string}
 * @param checkoutRequestBody {CheckoutRequest}
 * @returns {Promise<Order>}
 */
export function checkout(cartId: string, checkoutRequestBody: CheckoutRequest): Promise<CheckoutResponse> {
  const options = {
    method: 'POST',
    body: checkoutRequestBody
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/carts/${cartId}/checkout`, params)
}

/**
 * @description Updates cart with customer details
 * @param cartId {string}
 * @param cartCustomerDetails {CartCustomerDetails}
 * @returns {Promise<CartCustomerDetails>}
 */
export function updateCartCustomer(
  cartId: string,
  cartCustomerDetails: CartCustomerDetails
): Promise<CartCustomerDetails> {
  const options = {
    method: 'PUT',
    body: cartCustomerDetails
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/carts/${cartId}/customer`, params)
}

/**
 * @description Sends an action to the restaurant
 * @param orderId {string}
 * @param action {OrderAction}
 * @returns {Promise<CartCustomerDetails>}
 */
export function postOrderAction(orderId: string, action: OrderAction): Promise<CartCustomerDetails> {
  const options = {
    method: 'POST',
    body: action
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/orders/${orderId}/action`, params)
}

/**
 * @description Vote for a local matter at a restaurant
 * @param orderId {string} - Order to vote on
 * @param groupAllocationId {string} - Group allocation to vote for
 * @returns {Promise<void>} - 200 if successful, 404 if order or group allocation not found
 */
export function voteOnLocalMatter(orderId: string, groupAllocationId: string): Promise<void> {
  const options = {
    method: 'POST',
    body: { groupAllocationId }
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/orders/${orderId}/vote`, params)
}

/**
 * @description Fetch single restaurant by id
 * @param restaurantId
 * @returns {Promise<Restaurant>}
 */
export function fetchRestaurantById(restaurantId: number): Promise<Restaurant> {
  const options = {
    method: 'GET'
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/restaurants/${restaurantId}`, params)
}

/**
 * @description Fetch list of coupons
 * @returns {Promise<Coupon[]>}
 */
export function fetchCoupons(): Promise<Coupon[]> {
  const options = {
    method: 'GET'
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)

  return apiFetch(`/coupons`, params)
}

/**
 * @description Fetch a list of coupons available for a cart
 * @param cartId {string}
 * @returns {Promise<Coupon[]>}
 */
export function fetchAvailableCoupons(cartId: string): Promise<Coupon[]> {
  const options = {
    method: 'GET'
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/carts/${cartId}/coupons`, params)
}

/**
 * @description Lookups email to see if it already exists
 * @param lookupRequest
 * @returns {Promise<boolean | Error>} - True if email exists, false if it does not, Error if something went wrong
 */
export async function lookupEmail(lookupRequest: LookupRequest): Promise<boolean | Error> {
  const options = {
    method: 'POST',
    body: lookupRequest
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)

  try {
    await apiFetch(`/auth/lookup`, params)
    // 200 means email exists in the system
    return true
  } catch (error: any) {
    if (error.response.status === 404) {
      // 404 means email does not exist in the system
      return false
    }

    return error
  }
}

/**
 * @description Registers account
 * @param registerRequest
 * @returns {Promise<AuthenticationResponse>}
 */
export function register(registerRequest: RegisterRequest): Promise<AuthenticationResponse> {
  const options = {
    method: 'POST',
    body: registerRequest
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/auth/register`, params)
}

/**
 * @description Refresh user auth tokens
 * @param refreshRequest
 * @returns {Promise<AuthenticationResponse>}
 */
export function refreshAuthTokens(refreshRequest: RefreshRequest): Promise<AuthenticationResponse> {
  const options = {
    method: 'POST',
    body: refreshRequest
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/auth/refresh`, params)
}

/**
 * @description Logs in user
 * @param logInRequest
 * @returns {Promise<AuthenticationResponse>}
 */
export function signIn(logInRequest: LoginRequest): Promise<AuthenticationResponse> {
  const options = {
    method: 'POST',
    body: logInRequest
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/auth/login`, params)
}

/**
 * @description Updates user details
 * @param userDetails {UpdateUserRequest}
 * @returns {Promise<UserResponse>}
 */
export function updateUserDetails(userDetails: UpdateUserRequest): Promise<UserResponse> {
  const options = {
    method: 'PUT',
    body: userDetails
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/auth/update`, params)
}

/**
 * @description Changes user password
 * @param changePasswordRequest {ChangePasswordRequest}
 * @returns {Promise<AuthenticationResponse>}
 */
export function updateUserPassword(changePasswordRequest: ChangePasswordRequest): Promise<AuthenticationResponse> {
  const options = {
    method: 'POST',
    body: changePasswordRequest
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/auth/changePassword`, params)
}

/**
 * @description Deletes user account
 * @returns {Promise<void>}
 */
export function deleteUser(): Promise<void> {
  const options = {
    method: 'DELETE'
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/auth/delete`, params)
}

/**
 * @description Validates user address to see if user is close enough for delivery from restaurant
 * @param createCartRequest
 * @return {Promise<any>}
 */
export function validateDelivery(createCartRequest: CreateCartRequest): Promise<CartDeliveryDetails> {
  const options = {
    method: 'POST',
    body: createCartRequest
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/carts/validate-delivery`, params)
}

/**
 * @description Gets a Relish user's loyalty stamp (Eight & Donate)
 * @param userId {string} - User id
 * @return {Promise<EightDonateResponse>}
 */
export function getEightAndDonate(userId: string): Promise<EightDonateResponse> {
  const options = {
    method: 'GET'
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/loyalty/${userId}`, params)
}

/**
 * @description Submits users take or donate preference
 * @param userId
 * @param takeOrDonateAction
 * @return {Promise<EightDonateResponse>}
 */
export function submitTakeOrDonate(userId: string, takeOrDonateAction: TakeOrDonateRequest): Promise<any> {
  const options = {
    method: 'POST',
    body: takeOrDonateAction
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/loyalty/${userId}/takeOrDonate`, params)
}

export function fetchUserPaymentMethods(userId: string): Promise<SavedPaymentMethod[]> {
  const options = {
    method: 'GET'
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/Loyalty/${userId}/paymentMethods`, params)
}

/**
 * @description Fetches nearby restaurants given a lat/lng and optional limit
 * @param coords {Coords}
 * @param limit {number}
 * @param tag {string | null}
 * @returns {Promise<Restaurant[]>}
 */
export function fetchNearbyRestaurants(coords: Coords, limit = 10, tag: string | null = null): Promise<Restaurant[]> {
  const options = {
    method: 'GET'
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  const tagQuery = tag ? `&tag=${tag}` : ''
  return apiFetch(`restaurants/nearby?lat=${coords.latitude}&lng=${coords.longitude}&limit=${limit}${tagQuery}`, params)
}

export function deleteSavedPaymentMethod(userId: string, storedPaymentMethodId: string): Promise<void> {
  const options = {
    method: 'DELETE'
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/Loyalty/${userId}/paymentMethods/${storedPaymentMethodId}`, params)
}

/**
 * @description Fetches product recommendations for a Relish user
 * @param userId {string}
 * @param restaurantId {number}
 * @param orderType {OnlineOrderType}
 * @return {Promise<MenuItem[]>}
 */
export function getProductRecommendations({
  userId,
  restaurantId,
  orderType
}: {
  userId: string
  restaurantId: number
  orderType: OnlineOrderType
}): Promise<CartItem[]> {
  const options = {
    method: 'GET'
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/loyalty/${userId}/menuRecommendations?restaurantId=${restaurantId}&orderType=${orderType}`, params)
}

export function resetPassword(email: string): Promise<any> {
  const options = {
    method: 'POST',
    body: {
      emailAddress: email
    }
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`auth/reset`, params)
}

export function getMenuForRestaurant(restaurantId: number, orderType: OnlineOrderType): Promise<MenuResponse> {
  const options = {
    method: 'GET'
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/restaurants/${restaurantId}/menu?orderType=${orderType}`, params)
}

export function getOrderHistory(userId: string): Promise<Array<PastOrder>> {
  const options = {
    method: 'GET'
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/loyalty/${userId}/orders`, params)
}

export function getPromotions(restaurantId: number | undefined): Promise<PromotionsResponse> {
  const options = {
    method: 'GET'
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/promotions/${mobilePlatformQueryParam(restaurantId)}`, params)
}

export function fetchCateringParameters(restaurantId: number): Promise<{
  dateFrom: string
  dateTo: string
  excludedDates: string[]
  timesOfDay: string[]
  orderingMessage: string
}> {
  const options = {
    method: 'GET'
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/carts/catering-parameters?restaurantId=${restaurantId}`, params)
}

export function getMadBundayEntries(userId: string): Promise<MadBundayResponse> {
  const options = {
    method: 'GET'
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/loyalty/${userId}/madBundayEntries`, params)
}

export function fetchUserLoyaltyTags(userId: string): Promise<UserResponse['tags']> {
  const options = {
    method: 'GET'
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/loyalty/${userId}/tags`, params)
}

export function updateLoyaltyTags(userId: string, tags: UserResponse['tags']): Promise<void> {
  const options = {
    method: 'PUT',
    body: tags
  }

  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/loyalty/${userId}/tags`, params)
}

export function sendFeedback(message: string): Promise<any> {
  const options = {
    method: 'POST',
    body: {
      message
    }
  }
  const params: DefaultOptions = Object.assign({}, defaultOptions(), options)
  return apiFetch(`/feedback`, params)
}
